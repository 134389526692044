// DevisDisplayComponent.js
import React from 'react';

const ListDevises = ({ subcategorieItemsD }) => {
  return (
    <div>
      {/*<h2>List of Devises</h2>
      <div>
        {subcategorieItemsD && subcategorieItemsD.map((item, index) => (
          <div key={index}>
            <p>ID: {item.id}</p>
            <p>Sub Category: {item.sub_category.name}</p>
          </div>
        ))}
        </div>devide the imobillier from the visselle in regard to price total and ttc and stock each one of them in its place 
        take on cosideration the discount and display them on all devises and also factures
        the admin can enter the discount by himself
        */} 
    </div>
  );
};

export default ListDevises;

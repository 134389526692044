// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-app{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-container {
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    text-align: center;
  }
  .forgot-password .login{
    text-decoration: none;
  }
  .forgot-password .login:hover{
    text-decoration: underline;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .register-label{
    align-self: self-start;
  }

  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #7b6248;
    width: 100%;
    box-sizing: border-box;

    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #634f3a;
  }
  
  .already-have-account {
    margin-top: 16px;
  }
  
  .already-have-account a {
    color: #007bff;
    text-decoration: none;
  }
  
  .already-have-account a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/AuthStyles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;IACI,YAAY;IACZ,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,uCAAuC;IACvC,sBAAsB;IACtB,kBAAkB;EACpB;EACA;IACE,qBAAqB;EACvB;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;EACrB;EACA;IACE,sBAAsB;EACxB;;;EAGA;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,sBAAsB;;IAEtB,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".auth-app{\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.auth-container {\n    width: 400px;\n    margin: 0 auto;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    background-color: #fff;\n    text-align: center;\n  }\n  .forgot-password .login{\n    text-decoration: none;\n  }\n  .forgot-password .login:hover{\n    text-decoration: underline;\n  }\n  form {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .register-label{\n    align-self: self-start;\n  }\n\n  \n  input {\n    width: 100%;\n    padding: 10px;\n    margin-bottom: 16px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    box-sizing: border-box;\n  }\n  \n  button {\n    background-color: #7b6248;\n    width: 100%;\n    box-sizing: border-box;\n\n    color: #fff;\n    padding: 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: #634f3a;\n  }\n  \n  .already-have-account {\n    margin-top: 16px;\n  }\n  \n  .already-have-account a {\n    color: #007bff;\n    text-decoration: none;\n  }\n  \n  .already-have-account a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import './AuthStyles.css';
import './newAuth.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link, Navigate } from 'react-router-dom';

const LoginCopy = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    onLogin({ username, password });
  };

  return (
    <div className='login-body'>
        <img className='wave' src='./images/wave.png' alt='error' />
        <div className='container'>
            <div className='img'>
                <img src='/images/bg.png' alt='bg.svg' />
            </div>
        <div className='login-content'>
            <form>
                    <h2 className='login-title'>Bienvenue</h2>
                    <div className='input-div one'>
                        <div className='i'>
                            <FontAwesomeIcon className='i' icon={faUser}/>
                        </div>
                        <div className='div'>
                            <input type='text' className='input' placeholder='Username'/>
                        </div>
                    </div>

                    <div className='input-div pass'>
                        <div className='i'>
                            <FontAwesomeIcon icon={faLock}/>
                        </div>
                        <div className='div'>
                            <input type='password' className='input' placeholder='Password' />
                        </div>
                    </div>
                <Link to='' className='login-link'>Vous avez déjà un compte ? Connectez-vous ici.</Link>
                <button className='btn-login'>Login</button>
                </form>
                </div>
                </div>
            </div>
  );
};

export default LoginCopy;

function formatNumberWithTwoDecimals(num) {
    const roundedNum = num.toFixed(2);
  
    const parts = roundedNum.split('.');
  
    const integerPart = parts[0];
    let decimalPart = parts[1];
  
    if (decimalPart.length > 2) {
      const nextDigit = parseInt(decimalPart[2], 10);
  
      if (nextDigit >= 5) {
        decimalPart = (parseInt(decimalPart.substring(0, 2), 10) + 1).toString();
      }
  
      else {
        decimalPart = decimalPart.substring(0, 2);
      }
    }
  
    return `${integerPart}.${decimalPart}`;
  }

  export default formatNumberWithTwoDecimals;
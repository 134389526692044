import './modifyDevis.css';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';
import $ from 'jquery';
import Popper from 'popper.js';
import Swal from 'sweetalert2';

const ModifyDevis = () => {
  const [subcategoryItems, setSubcategoryItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allSubcategoryItems, setAllSubcategoryItems] = useState([]);
  const [selectedFabric, setSelectedFabric] = useState(null);
  const [fabricTypes, setFabricTypes] = useState([]);
  const [selectedFabricType, setSelectedFabricType] = useState(null);
  const [fabricColors, setFabricColors] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [selectedFabricTypeId, setSelectedFabricTypeId] = useState();
  const [collapsedStates, setCollapsedStates] = useState({});
  const [allFabricTypes, setAllFabricTypes] = useState([]);
  const [situation, setSituation] = useState('')
  const [quantity, setQuantity] = useState(1);
  const [selectedSubOption, setSelectedSubOption] = useState();
  const [colors, setColors] = useState([])
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const serialNumber = searchParams.get('serialNumber');
  const customerId = searchParams.get('customerId');
  const subcategoryId = searchParams.get('subcategoryId');
  const id = searchParams.get('Id');
  console.log("customer_id", customerId,
  "fabric_id", selectedFabric,
  "orientation_id", 1,
  "sub_category_id", subcategoryId,
  "serial_number", serialNumber,
  "state", situation,
  "subcategoryItemsId", id)

console.log("fabric type:", selectedFabricTypeId, "amount",1,)


const increaseQuantity = () =>{
  setQuantity(quantity+1)
}
const decreaseQuantity = () =>{
  if (quantity > 1) {
    setQuantity(quantity-1)
  }
}

//<<<<<<<<<<<<<<<<<<<<<<<<<<< BEGIN UPDATE SUBCATEGORY ITEMS DETAILS >>>>>>>>>>>>>>>>>>>>>>>>

const updateSubCategoryItemDetails = async (amount, detailsId) => {
  const payload = {
    fabric_type_id: selectedFabricTypeId,
    amount: amount,
    quantity: quantity
  };

  console.log('Update Payload:', payload);

  try {
    const response = await fetch(`http://localhost:5000/api/sub-category-items/details/${detailsId}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    console.log('Response Status:', response.status);

    const responseData = await response.json();
    console.log('Response Data:', responseData);

    if (response.ok) {
      console.log('Update successful:', responseData);
      console.log('wa9ila nadi');
    } else {
      console.error('Error:', responseData);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: responseData.error || 'An error occurred while updating the sub-category item details'
      });
    }
  } catch (error) {
    console.error('Network Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'Network Error',
      text: 'Failed to communicate with the server. Please try again later.'
    });
  }
};

// <<<<<<<<<<<<<<<<<<<<<<<<<< END UPDATE SUBCATEGORY ITEMS DETAIL >>>>>>>>>>>>>>>>>>>>>>>>>








//<<<<<<<<<<<<<<<<<<<<<<<<<< BEGIN UPDATE SUBCATEGORY ITEM >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const updateSubCategoryItem = async (id) => {
  const payload = {
    customer_id: customerId,
    fabric_id: selectedFabric.id,
    orientation_id: 8,
    sub_category_id: subcategoryId,
    serial_number: serialNumber,
    state: "DONE"
  };

  console.log('Update Payload:', payload);

  try {
    const response = await fetch(`http://localhost:5000/api/sub-category-items/${id}/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });

    console.log('Response Status:', response.status);

    const responseData = await response.json();
    console.log('Response Data:', responseData);

    if (response.ok) {
      console.log('Update successful:', responseData);
      console.log('nadi');
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "l'élément est mis à jour avec succès"
      });
    } else {
      console.error('Error:', responseData);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: responseData.error || 'An error occurred while updating the sub-category item'
      });
    }
  } catch (error) {
    console.error('Network Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'Network Error',
      text: 'Failed to communicate with the server. Please try again later.'
    });
  }
};

//<<<<<<<<<<<<<<<<<<<<<<<<<< END UPADE SUBCATEGORY ITEM >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const fetchSubcategoryItems = useCallback(async () => {
    if (serialNumber) {
      try {
        const response = await fetch(`https://icanto-api.gisway.org/api/v1/sub-category-items/${serialNumber}`);
        if (!response.ok) throw new Error('Failed to fetch subcategory items');
        const data = await response.json();
        setSubcategoryItems(data.items);
      } catch (error) {
        console.error('Error fetching subcategory items:', error);
      }
    }
  }, [serialNumber]);

  const fetchAllSubcategoryItems = useCallback(async () => {
    try {
      const response = await fetch('https://icanto-api.gisway.org/api/v1/sub-category-items/');
      if (!response.ok) throw new Error('Failed to fetch all subcategory items');
      const data = await response.json();
      setAllSubcategoryItems(data.items);
    } catch (error) {
      console.error('Error fetching all subcategory items:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchAllFabricTypes = useCallback(async () => {
    try {
      const response = await fetch('https://icanto-api.gisway.org/api/v1/fabric-types/');
      if (!response.ok) throw new Error('Failed to fetch all fabric types');
      const data = await response.json();
      setAllFabricTypes(data.items);
    } catch (error) {
      console.error('Error fetching all fabric types:', error);
    }
  }, []);

  useEffect(() => {
    fetchSubcategoryItems();
    fetchAllSubcategoryItems();
    fetchAllFabricTypes(); // Fetch all fabric types
  }, [fetchSubcategoryItems, fetchAllSubcategoryItems, fetchAllFabricTypes]);

  /*const fetchFabricTypes = useCallback(async () => {
    if (selectedFabric) {
      try {
        const response = await fetch(`https://icanto-api.gisway.org/api/v1/fabrics/${selectedFabric.id}/fabric-types`);
        if (!response.ok) throw new Error('Failed to fetch fabric types');
        const data = await response.json();
        setFabricTypes(data.items);
      } catch (error) {
        console.error('Error fetching fabric types:', error);
      }
    }
  }, [selectedFabric]);*/

  const fetchFabricColors = useCallback(async () => {
    if (selectedFabric && selectedFabricType!==null) {
      const lowerCaseFabricType = selectedFabricType.toLowerCase();

      try { 
        const response = await fetch(`https://icanto-api.gisway.org/api/v1/fabric-types/${selectedFabric.id}/${lowerCaseFabricType}`);
        if (!response.ok) throw new Error('Failed to fetch fabric colors');
        const data = await response.json();console.log(data.items)
        const colors = data.items.map((item) => [item.color, item.id]);
        setFabricColors(colors);
        console.log(data)
      } catch (error) {
        console.error('Error fetching fabric colors:', error);
      }
    }
  }, [selectedFabric, selectedFabricType]);

  /*useEffect(() => {
    fetchFabricTypes();
    fetchFabricColors();
  }, [fetchFabricTypes, fetchFabricColors]);*/

  useEffect(() => {
    if (inputValue.trim() === '') {
      setSuggestions([]);
    } else {console.log(colors)
      const filteredSuggestions = colors;
      setSuggestions(filteredSuggestions);
    }
  }, [inputValue, colors]);

  const handleFabricSelection = useCallback((fabric, selectedSubOption) => {
    setSelectedFabric(fabric);
    setSelectedSubOption(selectedSubOption)



  }, []);
  useEffect(() => {
    if (selectedSubOption !== null && selectedFabric !== null) {
      fetch(`https://icanto-api.gisway.org/api/v1/fabrics/${selectedFabric.id}/${selectedSubOption}/fabric-types`)
        .then(response => response.json())
        .then(data => setFabricTypes(data.items))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [selectedSubOption, selectedFabric]);


  useEffect(() => {
    if (selectedSubOption !== null && selectedFabric !== null && selectedFabricType !== null) {
      console.log('Fetching colors with:', {
        selectedFabric: selectedFabric.id,
        selectedSubOption,
        selectedFabricType  
      });

      fetch(`https://icanto-api.gisway.org/api/v1/fabric-types/fabrics/${selectedSubOption}/${selectedFabric.id}/${selectedFabricType}/colors`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          console.log('Fetched colors:', data.items);
          setColors(data.items);
        })
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [selectedSubOption, selectedFabric, selectedFabricType]);
  /**/

  const handleFabricTypeSelection = (category) => {
    setSelectedFabricType(category);
  };

  const handleInputChange = useCallback((event) => {
    setInputValue(event.target.value);
    console.log(colors)
    setShowSuggestion(true);
  }, []);

  const handleSuggestionClick = useCallback((color, id) => {
    setInputValue(color);
    setSelectedFabricTypeId(id)
    setShowSuggestion(false);
    
    // Find the matching fabric type ID
    /*const matchedFabricType = allFabricTypes.find(
      (type) => type.color === suggestion && fabricTypes.some(ft => ft.category === selectedFabricType && ft.id === type.id)
    );

    if (matchedFabricType) {
      setSelectedFabricTypeId(matchedFabricType.id);
    }*/
  }, [allFabricTypes, fabricTypes, selectedFabricType]);

  const toggleCollapse = useCallback((id) => {
    setCollapsedStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  }, []);




  if (loading) {
    return <div className="loader"></div>;
  }
  const handleCheckboxChange = (event) => {
    setSituation(event.target.checked ? 'DONE' : 'PENDING');
  };
/*  console.log("-----------fiv -------------",subcategoryItems);
  subcategoryItems.map((item, index) => {
    console.log(item);
   const filteredFabrics = allSubcategoryItems
    .filter((it) => {
      if (it.sub_category !==null) {
        console.log(" , item.sub_category ",it.sub_category.id);
      }


    })
    .map((it) => ({ id: it.fabric.id, name: it.fabric.name }));
  })
  */
  
  return (
    
    <div className='modifications-container'>
      {subcategoryItems.length > 0 ? (
        subcategoryItems.map((item, index) => {
          console.log(subcategoryItems);
          const filteredFabrics = allSubcategoryItems  
          .filter((it) => {
            if (it.sub_category !== null && item.sub_category !== null) {
              return it.sub_category.id === item.sub_category.id;
            }
            return false;
          })
          .map((it) => ({ id: it.fabric.id, name: it.fabric.name }));
        
          const uniqueFabrics = Array.from(new Set(filteredFabrics.map(JSON.stringify)), JSON.parse);

          const isCollapsed = collapsedStates[item.id];

          return (
            <div
              className={`modify-inputs ${isCollapsed ? 'collapsed' : 'expanded'}`}
              key={item.id}
              onClick={() => toggleCollapse(item.id)}
            >
              {item.sub_category&&(<h5 className='colapsed-subcategory'>{item.sub_category.name}</h5>)}
        
              <div className={`collapse-content ${isCollapsed ? 'show' : 'hide'}`} onClick={(e) => e.stopPropagation()}>
                <div className='modify-mat'>
                  <div>
                    <label>Matériau</label>
                    <div className="fabric-options">
                      {console.log('----------object',uniqueFabrics)}
                      {uniqueFabrics.map((fabric) => (
                        <div
                          key={fabric.id}
                          onClick={() => handleFabricSelection(fabric, item.sub_category.id)}
                          className="fabric-option"
                        >
                          {fabric.name}
                        </div>
                      ))}
                    </div>
                  </div>
            {console.log(subcategoryItems)}
            {console.log('item-----------', item)}
                  {/*item.id === parseInt(subcategoryItems[subcategoryItems.length - 1].id) && (
                    <div className='situation'>
                      <label>La Situation De Commande Est Done?</label>
                      <input
                        type="checkbox"
                        checked={situation === 'done'}
                        onChange={handleCheckboxChange}
                        className='situation-checkbox'
                      />
                    </div>
                  )*/}
        
                  <button className='confirm-mat' onClick={()=>updateSubCategoryItem(item.id, item.sub_category.id)}>confirm material?</button>
                  <hr />
                </div>
        
                <div className='modify-ver'>
                  <label>Version Matériau</label>
                  <div className='version-mat-mod'>
                    
                    {fabricTypes && fabricTypes.map((fab) => (
                      <div key={fab.category} className='fabric-type-item' onClick={() => handleFabricTypeSelection(fab.category)}>
                        
                        {fab.category}{console.log('fab:',fab,' ')}
                      </div>
                    ))}
                  </div>
                </div>
        
                <div className='modify-col'>
                  <input
                    type="text"
                    placeholder="Sélectionner la couleur"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  {inputValue && showSuggestion && (
                    <div className="suggestions">
                      {console.log(suggestions)}
                      {suggestions&&suggestions.map((suggestion, index) => (
                        <div key={index} onClick={() => handleSuggestionClick(suggestion.color, suggestion.id)}>
                          {suggestion.color}
                          {console.log(suggestion)}
                        </div>
                      ))}
                    </div>
                  )}

                </div>
        
                <div className='quantity-update'>
                  <p onClick={decreaseQuantity}>-</p>
                  {quantity}
                  <p onClick={increaseQuantity}>+</p>
                </div>
        
                <div className='confirmer-anuller-mod'>
                  {item.details&&(
                    <button onClick={() => updateSubCategoryItemDetails(item.details[0].amount, item.details[0].id)}>Confirmer</button>
                  )}
                  
                </div>
              </div>
            </div>
          );
        })        
      ) : (
        <p>No subcategory items found for this device.</p>
      )}
      {selectedFabric && (
        <p className='fabric'>Selected Fabric: {selectedFabric.name} (ID: {selectedFabric.id})</p>
      )}
            {selectedSubOption && (
        <p className='fabric'>Selected selectedSubOption: {selectedSubOption}</p>
      )}
      {selectedFabricType && (
        <p className='fabric'>Selected Fabric Type : {selectedFabricType}</p>
      )}
    </div>
  );
  
};

export default ModifyDevis;

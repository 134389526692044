import { useState } from "react";
import MyHeader from "./header";
import './welcomBack.css';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPenNib, faDownload, faTrash, faLevelDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useLocation } from "react-router";
import formatNumberWithTwoDecimals from "./numberFormat";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import Swal from "sweetalert2";
import { faF, faD } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from 'uuid';

const WelcomeBack = () =>{
    const [customers, setCustomers] = useState([]);
    const [data, setData] = useState([])
    const [pdf, setPdf] = useState([]);
    const [nom, setNom] = useState('');
    const [adresse, setAdresse] = useState('');
    const [telephone, setTelephone] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get('customerId');
    const id = searchParams.get('Id');
    const [subcategoryItems, setSubcategoryItems] = useState([]);
    const [models, setModels] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleCheck = async (serial) => {
      console.log('handleCheck called with serial:', serial); // Debugging log
    
      const confirmation = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, update it!"
      });
    
      if (confirmation.isConfirmed) {
        const matchingItems = pdf.filter(item => item.serial_number === serial);
        console.log('Matching items:', matchingItems); // Debugging log
    
        const idsToUpdate = matchingItems.map(item => item.id);
        console.log('IDs to update:', idsToUpdate); // Debugging log
    
        try {
          for (const id of idsToUpdate) {
            console.log('Updating item with ID:', id); // Debugging log
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            const raw = JSON.stringify({
              state: "DONE",
              serial_number: serial
            });
    
            const requestOptions = {
              method: "PATCH",
              headers: myHeaders,
              body: raw,
              redirect: "follow"
            };
    
            const response = await fetch(`http://localhost:5000/api/sub-category-items/${id}/update-state`, requestOptions);
    
            if (response.ok) {
              const result = await response.json();
              console.log(result);
    
              // Optionally update the local state here if needed
              setPdf(prevPdf => prevPdf.map(item =>
                item.id === id ? { ...item, state: 'DONE' } : item
              ));
    
              Swal.fire({
                title: "Good job!",
                text: `Item with ID ${id} updated successfully`,
                icon: "success",
              });
            } else {
              const errorData = await response.json();
              throw new Error(`Failed to update item with ID ${id}: ${errorData.message}`);
            }
          }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: "Error",
            text: `Failed to update items: ${error.message}`,
            icon: "error",
          });
        }
      }
    };
    
    // Usage in JSX
    {pdf.map(item => (
      <input 
        key={item.id} 
        type="checkbox" 
        className="done" 
        checked={item.state==="DONE"?true:false}
        onClick={() => handleCheck(item.serial_number)} 
      />
    ))}
    
    
    // Usage in JSX
    // Make sure the serial number is passed correctly
    {pdf.map(item => (
      <input 
        key={item.id} 
        type="checkbox" 
        className="done" 
        onClick={() => handleCheck(item.serial_number)} 
      />
    ))}
    
    const deleteItemsBySerialNumber = async (serialNumber) => {
      
      const matchingItems = pdf.filter(item => item.serial_number === serialNumber);
      const deleted_ids = matchingItems.map(item => item.id);
  
      for (const id of deleted_ids) {
        try {
          const response = await fetch(`http://localhost:5000/api/sub-category-items/${id}/delete`, {
            method: 'DELETE'
          });
  
          if (response.ok) {
            Swal.fire({
              title: "Good job!",
              text: `Item with ID ${id} deleted successfully`,
              icon: "success"
            });
          } else {
            console.error(`Failed to delete item with ID ${id}. Status: ${response.status}`);
            try {
              const errorData = await response.json();
              console.error('API Error:', errorData);
              Swal.fire({
                title: "Good job!",
                text: `item deleted successfully`,
                icon: "success"
              });
            } catch (jsonError) {
              console.error('Error parsing error response:', jsonError);
              Swal.fire({
                title: "Bad job!",
                text: `Failed to delete item with ID ${id}: An error occurred during delete operation`,
                icon: "error"
              });
            }
          }
        } catch (error) {
          console.error('Server Error:', error);
          Swal.fire({
            title: "Error",
            text: `Failed to delete item with ID ${id}: Internal server error`,
            icon: "error"
          });
        }
      }
    };
//    const uniqueSerialNumbers = [...new Set(pdf.map(order => order.serial_number))];

//console.log(uniqueSerialNumbers);
// Use a Set to keep track of unique serial numbers
//const uniqueSerialNumbers = [...new Set(pdf.map(order => order.serial_number))];

let uniqueSerialNumbers = [...new Set(pdf.map(item => item.serial_number))];

let data_uniqueSerialNumbers = uniqueSerialNumbers.map(serial_number => {
    let item = pdf.find(d => d.serial_number === serial_number);
    console.log(pdf)
    return {
        serial_number: serial_number,
        created_at: item.created_at_fr,
        id:item.id,
        subcategoryId:item.sub_category?item.sub_category.id:null,
        state:item.state
    };
});

console.log("------------------",data_uniqueSerialNumbers);
    //<<<<<<<<<<<<<<<<<<< BEGIN GENERATE FACTURE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    const generateFacture = (filteredItems) => {
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
      const fabricType = filteredItems[0].details[0].fabric_type?.category;
      const color = filteredItems[0].details[0].fabric_type?.color;
      const result = calculateTotalPriceWithVAT(filteredItems);
    
      const chunkSize = 6;
      const chunks = [];
      
      for (let i = 0; i < filteredItems.length; i += chunkSize) {
        chunks.push(filteredItems.slice(i, i + chunkSize));
      }
    
      const docDefinition = {
        pageMargins: [40, 160, 40, 160],
        header: [
          {
            height: 500,
            columns: [
              { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
              { text: 'facture', fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
            ]
          },
          { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 3, color: '#7b6248' }] }
        ],
        footer: function(currentPage, pageCount) {
          return [
            { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
            { text: 'ICE : 003378241000085', style: 'footer11' },
            { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
            { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
            { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
            { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
            { text: 'Tel : 00212661093929', style: 'footer1' },
            //{ text: 'Ce devis est valable pendant 6 mois.', style: 'warning' },
            { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
          ];
        },
        content: [
          { columns: [
            { text: 'Client: ' + nom, style: 'client' },
            { text: ' devis N°:', style: 'devis' },
            { text: '1', style: 'x' }
          ]},
          { columns: [
            { text: 'Adresse Client: ' + adresse, style: 'adressClient' },
            { text: "Date:", style: 'dateE' },
            { text: filteredItems[0].created_at_fr, style: 'date' }
          ]},
          { columns: [
            { text: 'Telephone: ' + telephone, style: 'adressClient' },
            { text: "" },
            { text: '' }
          ]},
          { columns: [
            { text: '', style: 'vidText' }
          ]},
          ...chunks.map(chunk => ({
            layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*'],
              body: [
                [
                  //{ text: 'Image', style: 'tableHeader' },
                  { text: 'Gamme', style: 'tableHeader' },
                  { text: 'Détails', style: 'tableHeader' },
                  { text: 'Quantite', style: 'tableHeader' },
                  { text: 'Prix (Dhs)', style: 'tableHeader' }
                ],
                ...chunk.map(item => [
                  //{ text: 'image64', fillColor: '#f3f3f3', width: 40, height: 40 },
                  { text: `${item.model}`, fillColor: '#f3f3f3' },
                  {
                    text: `-${item.sub_category.name}. \n -${item.fabric.name}. \n -${fabricType}. \n -${color}. \n -H: ${item.sub_category.category.height} L: ${item.sub_category.category.length} P: ${item.sub_category.category.depth}`,
                    fillColor: '#f3f3f3',
                    fontSize: 8,
                    alignment: 'start',
                    bold: true
                  },
                  { text: `${item.details[0].quantity}`, fillColor: '#f3f3f3' },
                  { text: `${item.details[0].amount}`, fillColor: '#f3f3f3' }
                ])
              ],
              border: []
            },
            style: 'tableStyle', marginTop:70
          })),
          {
            layout: 'noBorders',
            table: {
              widths: [100, 100],
              body: [
                [
                  { text: 'TOTAL HT', style: 'tableHeader1' },
                  { text: formatNumberWithTwoDecimals(result.totalPrice), style: 'value' }
                ],
                [
                  { text: 'TVA', style: 'tableHeader1' },
                  { text: '20%', style: 'value' }
                ],
                [
                  { text: 'TOTAL TTC', style: 'tableHeaderTTC' },
                  { text: formatNumberWithTwoDecimals(result.totalPriceTTC), style: 'valueTTC' }
                ]
              ]
            },
            style: 'table2',
            margin: [307, 15, null, null]
          },
          { text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 }
        ],
        styles: {
          foot: {
            alignment: 'center'
          },
          facture: {
            fontSize: 30,
            color: '#7b6248',
            bold: true,
            margin: [40, 40, 0, 0]
          },
          tableStyle: {
            marginTop:70,
            alignment: 'center',
            marginTop: 50,
            color: '#7b6248'
          },
          adressText: {
            color: '#7b6248'
          },
          factureA: {
            marginTop: 15,
            bold: true,
            color: '#7b6248'
          },
          client: {
            color: '#7b6248',
            alignment: 'left'
          },
          devis: {
            alignment: 'center',
            color: '#7b6248',
            margin: [110, 0, 0, 0],
            bold: true,
            fontSize: 12
          },
          x: {
            alignment: 'right',
            marginRight: 9,
            color: '#7b6248',
            bold: true
          },
          adressClient: {
            marginTop: 5,
            color: '#7b6248'
          },
          dateE: {
            alignment: 'start',
            color: '#7b6248',
            margin: [116, 2, 0, 0],
            bold: true
          },
          date: {
            alignment: 'start',
            color: '#7b6248',
            bold: true,
            margin: [107, 2, 0, 4]
          },
          ref: {
            alignment: 'start',
            color: '#7b6248',
            margin: [118, 8, 0, 0],
            bold: true
          },
          refN: {
            alignment: 'right',
            color: '#7b6248',
            bold: true,
            margin: [135, 8, 9, 4]
          },
          tableHeader: {
            bold: true,
            fillColor: '#f3f3f3',
            color: '#7b6248',
            alignment: 'center'
          },
          tableHeaderTTC: {
            fillColor: '#7b6248',
            color: 'white'
          },
          valueTTC: {
            fillColor: '#7b6248',
            color: 'white'
          },
          table2: {},
          tableHeader1: {
            fillColor: '#f3f3f3'
          },
          value: {
            fillColor: '#f3f3f3'
          },
          footer1: {
            fontSize: 10,
            bold: true,
            alignment: 'center'
          },
          warning: {
            fontSize: 14,
            bold: true,
            alignment: 'center',
            color: 'red'
          },
          footer11: {
            margin: [0, 5, 0, 0],
            fontSize: 10,
            bold: true,
            alignment: 'center'
          },
          total: {
            alignment: 'right',
            margin: [0, 15, 0, 0]
          },
          totalTitle: {
            margin: [0, 15, 0, 0],
            bold: true,
            alignment: 'right'
          },
          HT: {
            margin: [0, 30, 0, 0]
          },
          totalPriceBackground: {
            fillColor: '#D9D6DC'
          }
        }
    }
    
  const logoPath = './images/logo1.png';
  const sofaImagePath = './images/sofa2.png';

  convertImageToBase64(logoPath, base64Logo => {
    convertImageToBase64(sofaImagePath, base64SofaImage => {
      docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    });
  });
    }

    //<<<<<<<<<<<<<<<<<<<<END GENERATE FACTURE >>>>>>>>>>>>>>>>>>>>>>>>>>>>
    console.log(subcategoryItems)
    function filterBySerialNumber(data, serialNumber) {
      return data.filter(order => order.serial_number === serialNumber);
  }
  
  let date_document = ""

  const removeDuplicates = (arr) => {
    const uniqueSet = new Set();
    return arr.filter(item => {
        const itemString = JSON.stringify(item); // Convert item to string
        if (!uniqueSet.has(itemString)) {
            uniqueSet.add(itemString);
            return true; // Include in the filtered array
        }
        return false; // Exclude from the filtered array
    });
};


  
  const function_adapter_object = (data) => {
    let data_of_papier_generer = []
   let totalPrix = 0
   let created_at
    data.forEach(element => {
        created_at= element.created_at_fr
        if (element.sub_category != null) {


            console.log('Meubles');
            let lenght=`${element.sub_category.category.length}`
            let height=`${element.sub_category.category.height}`
            let depth=`${element.sub_category.category.depth}`
            
            let each_element_meubles = {
                "model": `- ${element.sub_category.product_model.name}`,
                "sub_category_name": `- ${element.sub_category.name}`,
                "category_name": `- ${element.sub_category.category.name}`,
                "dimension":`- H: ${height}, L: ${lenght}, P: ${depth}`,
                "fabric_name": `- ${element.fabric.name}`,
                "orientation": `- ${element.orientation.name}`,
                "details_category": `- ${element.details[0].fabric_type.category}`,
                "details_color": `- ${element.details[0].fabric_type.color}`,
                "prix": element.details[0].amount,
                "quantite": element.details[0].quantity,
               
            }
            let prix_unitaire =  element.details[0].amount*element.details[0].quantity
            //totalPrix += prix_unitaire
            data_of_papier_generer.push(each_element_meubles)


        } else {
            console.log("This is visselle");
            element.accessories.forEach(element_visselle => {
                console.log("each_element_in_accessoire", element_visselle);
                let each_element_visselle_facture = {
                    "model": `${element_visselle.service_title}`,
                    "sub_category_name": `- ${element_visselle.product_designation}`,
                    "category_name": ` `,
                    "dimension":` `,
                    "fabric_name": "",
                    "orientation": "",
                    "details_category": `- ${element_visselle.product_reference}`,
                    "details_color": "",
                    "prix": element_visselle.price,
                    "quantite": element_visselle.quantity,
                    
                }
                let prix_unitaire =  element_visselle.price*element_visselle.quantity
                //totalPrix += prix_unitaire
                data_of_papier_generer.push(each_element_visselle_facture)
                
            });
        }

    });
    let total_ttc=totalPrix + totalPrix*0.02
    const uniqueItems = removeDuplicates(data_of_papier_generer);
    console.log("uniaue ite;s ------",uniqueItems);
    uniqueItems.forEach(element => {
      totalPrix += element.prix*element.quantite
    });

    return {data:uniqueItems,totalht:totalPrix,totalttc:total_ttc,created_at:created_at}


}

    const generateX = (serial, state) => {
      console.log('selected serial :',serial)
      console.log('customer data:',pdf)
      const result_devis_element = filterBySerialNumber(pdf, serial);
  
      console.log('devis elements are:',result_devis_element);
      state = result_devis_element[0].state
      if (state === 'PENDING') {
        console.log("-------devis",result_devis_element);
        
        let send_data = function_adapter_object(result_devis_element)
        send_data.state = "DEVIS"
        generatePdf(send_data);

      } else if (state === 'DONE') {
        let send_data = function_adapter_object(result_devis_element)
        send_data.state = "FACTURE"
        generatePdf(send_data);
        //generateFacture(send_data);
      }
  };
  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GENERAT BON DE LIVRAISON >>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  /*const generateBoneDeLivraison = () =>{
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  
  setPdfReference(pdfReference + 1)
    const currentPage = 1;
  
    const docDefinition = { 
      pageMargins: [40, 160, 40, 160],
        header:[
        {
          height:500,
          columns:[
            {image: 'logo', width:200, fit:[200, 100], alignment:'left', margin:[40, 0, 0, 0] },
            {text:'Bon De Livraison', fontSize:26, color:'#7b6248', bold:true,margin:[0, 70, 40, 0], alignment:'right'},
        ]},
        { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248'}]},
      ],
      footer:[
        { canvas: [{ type: 'line', x1: 40, y1: 60, x2: 552, y2: 60, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style:'footer11'},
        { text: 'IF : 60118501 - RIB : 000000000', style:'footer1'},
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style:'footer1'},
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style:'footer1'},
        { text: 'Email : cornerliving2023@gmail.com', style:'footer1'},
        { text: 'Tel : 00212661093929', style:'footer1'},
        {text: currentPage, style:'footer1' }
    ],
      content: [
        {columns:[
          { text: 'Client: '+nom, style:'client'},
          { text: 'BL N°:', style:'devis'},
          { text: "pdfReference", style:'x'},
          //{ text: type+' N°:', style:'devis'},
        ]},
        {columns:[
          { text: 'Adresse Client: '+adresse, style:'adressClient',},
          { text: "Date:", style:'dateE'},
          { text: '4/30/2022', style:'date'},
        ]},
        {columns:[
          { text: 'Telephone: '+telephone, style:'adressClient',},
          { text: ""},
          { text: ''}, //<<<<<<<<<<<<<<<<<<<< Ce devis est valable pendant 6 mois. >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        ]},
        {columns:[
          {text:'', style:'vidText'},
          //{text:type, style:'devis'}
        ]},
        {
          layout: 'lightHorizontalLines',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
               { text: 'Gamme', style: 'tableHeader'},
               { text: 'Détails', style: 'tableHeader'},
               { text: 'Quantité Commandée', style: 'tableHeader' },
              ],
              ...objects.map(item => [
              {text: item['modelName'], fillColor: 'f3f3f3'},
              {
                text: '-' + item['sou-categorie-text'] + '. \n -' + item['version-text'] + '. \n -' + item['version-material'] + '. \n -' + item['couleur'] + '. \n -H: ' +item['height']+' L: '+item['width']+' P: '+item['depth']
                , fillColor:'f3f3f3', fontSize:8, alignment:'start', bold:true
              },
              {text: item['quantity'], fillColor:'f3f3f3'},
            ]),
            ],
            border: [],
          }, style: 'tableStyle'
        },
        {
          layout: 'noBorders',
          table:{
          widths: [100, 100],
          body: [
            [
            { text: '', style: 'tableHeader1'},
            { text:'', style: 'value',},
            ],
            [
              { text: '', style: 'tableHeader1' },
              { text: '', style: 'value' },
            ],
            [
              { text: '', style: 'tableHeaderTTC' },
              { text: '', style: 'valueTTC' },
            ]
            ],
      }, style:'', margin: [307, 15, null, null], 
  
      },
        { text: 'Signature', alignment: 'right', marginTop:20, bold:true, marginRight:50},
      ],
      
      styles: {
        foot:{
          alignment:'center'
        },
        facture:{
          fontSize:30,
          color:'#7b6248',
          bold:true,
          margin:[40, 40, 0, 0]
        },
        tableStyle:{
          alignment: 'center',
          marginTop:50,
          color: '#7b6248',
        },
        adressText:{
          color: '#7b6248',
        },
        factureA:{
          marginTop: 15,
          bold: true,
          color: '#7b6248',
        },
        client:{
          color: '#7b6248',
          alignment: 'left'
        },
        devis:{
          alignment: 'center',
          color: '#7b6248', 
          margin:[110, 0, 0, 0],
          bold:true,
          fontSize:12
        },
        x:{
          alignment: 'right',
          marginRight:9,
          color: '#7b6248',
          bold:true
        },
  
        adressClient:{
          marginTop:5,
          color: '#7b6248',
        },
  
        dateE:{
          alignment: 'start',
          color: '#7b6248', 
          margin:[116, 2, 0, 0],
          bold:true,
        },
        date:{
          alignment: 'start',
          color: '#7b6248',
          bold:true,
          margin:[107, 2, 0, 4],
        },
        ref:{
          alignment: 'start',
          color: '#7b6248', 
          margin:[118, 8, 0, 0],
          bold:true,
        },
        refN:{
          alignment: 'right',
          color: '#7b6248',
          bold:true,
          margin:[135, 8, 9, 4],
        },
        tableHeader: {
          bold: true,
          fillColor: '#f3f3f3',
          color: '#7b6248',
          alignment: 'center'
        },
        tableHeaderTTC:{
          fillColor: '#7b6248',
          color: 'white'
        },
        valueTTC:{
          fillColor: '#7b6248',
          color: 'white'
        },
  
        table2:{
        },
        tableHeader1:{
          fillColor:'#f3f3f3',
        },
        value:{
          fillColor:'#f3f3f3',
        },
        footer1:{
          fontSize:10,
          bold:true,
          alignment:'center',
        },
        warning:{
          fontSize:14,
          bold:true,
          alignment:'center',
          color:'red'
        },
        footer11:{
          margin:[0, 5, 0, 0],
          fontSize:10,
          bold:true,
          alignment:'center',
        },
        footer:{
          fontSize:10,
          bold:true,
          alignment:'center'
        },
        total: {
          alignment: 'right',
          margin:[0, 15, 0, 0],
  
        },
        totalTitle: {
          margin:[0, 15, 0, 0],
          bold: true,
          alignment: 'right', 
        },
        HT:{
          margin:[0, 30, 0, 0],
  
        },
        totalPriceBackground: {
          fillColor: '#D9D6DC',
        },
        
      },
    };
  
    const logoPath = './images/logo1.png'; 
    const sofaImagePath = './images/sofa2.png';
  
    convertImageToBase64(logoPath, base64Logo => {
        convertImageToBase64(sofaImagePath, base64SofaImage => {
            docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
            const pdfGenerator = pdfMake.createPdf(docDefinition);
            pdfGenerator.getBlob(blob => {
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            });
        });
    });
  ;
    
  };*/


  //<<<<<<<<<<<<<<<<<<<<<<<<<< END GENERATION >>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          const showwEdit = (serialNumber, subcat, id) =>{
            console.log('sirial:',serialNumber,' customerid:',customerId, ' subcategoryId:',subcat,' id:',id)
            console.log(`/modify?serialNumber=${serialNumber}&customerId=${customerId}&subcategoryId=${subcat}&Id=${id}`);
            window.location.href = `/modify?serialNumber=${serialNumber}&customerId=${customerId}&subcategoryId=${subcat}&Id=${id}`;
          }
          useEffect(() => {
            const fetchItems = async () => {
              try {
                //fetch customers https://icanto-api.gisway.org/api/v1/customers/
                const responseCustomers = await fetch('https://icanto-api.gisway.org/api/v1/customers/');
                if (!responseCustomers.ok) {
                  throw new Error('Failed to fetch customers');
                }
                const dataC = await responseCustomers.json();
                console.log('Customers data:', dataC);
        
                // Assuming data.items is the array of customers
                const customerItems = dataC.items || [];
                setCustomers(customerItems);
        
                // Filter the customers based on customerId
                const filteredCustomer = customerItems.find(item => item.id === parseInt(customerId));
                console.log('Filtered customer:', filteredCustomer);
        
                if (filteredCustomer) {
                  setNom(`${filteredCustomer.first_name} ${filteredCustomer.last_name}`);
                  setAdresse(filteredCustomer.address);
                  setTelephone(filteredCustomer.phone);
                  setFirstName(filteredCustomer.first_name)
                  setLastName(filteredCustomer.last_name);
                }
        

                // Fetch subcategory items
               
                const response = await fetch(`https://icanto-api.gisway.org/api/v1/sub-category-items/customers/${customerId}`);
                if (!response.ok) {
                  throw new Error('Failed to fetch data');
                }
              
                const data = await response.json();
                console.log(data.items);
                setPdf(data.items)
        
                // Filter subcategory items based on customerId
        
                // Group subcategory items by serial_number
                const groupedItems = groupBySerialNumber(data.items);
        
                // Fetch models from the API
                const modelsResponse = await fetch('https://icanto-api.gisway.org/api/v1/categories/');
                if (!modelsResponse.ok) {
                  throw new Error('Failed to fetch models');
                }
                console.log(pdf)
                const modelsData = await modelsResponse.json();
                console.log(modelsData);
                // Match models to subcategory items in pdf array based on category ID
                /*const updatedPdf = groupedItems.map(group => {
                  return group.map(item => {
                    const matchingCategory = modelsData&&modelsData.items&&modelsData.items.find(category => category.id === item.sub_category.category.id);
                    if (matchingCategory && matchingCategory.product_model) {
                      return {
                        ...item,
                        model: matchingCategory.product_model.name,
                        modelId: matchingCategory.product_model.id
                      };
                    } else {
                      return {
                        ...item,
                        model: 'Unknown Model',
                        modelId: null
                      };
                    }
                  });
                });
                // Set updated pdf with matched models
                setPdf(updatedPdf); console.log(updatedPdf)*/
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            };
            fetchItems();
          }, [customerId]);
          console.log(pdf)
          // Helper function to group items by serial_number
          const groupBySerialNumber = (data) => {
            const grouped = {};
            data.forEach(obj => {
              const serialNumber = obj.serial_number;
              if (!serialNumber) {
                return;
              }
              if (!grouped[serialNumber]) {
                grouped[serialNumber] = [];
              }
              grouped[serialNumber].push(obj);
            });
            return Object.values(grouped);
          };
        
          // Helper function to group items by serial_number



      console.log('before')

      //<<<<<<<<<<<<<<<<<<<<< FUNCTION DELETE >>>>>>>>>>>>>>>>>>>
const handleDeleteDetail = async (devisId) => {
  try {
    if (!devisId) {
      console.error('Invalid devisId:', devisId);
      return;
    }

    const response = await fetch(`http://localhost:5000/api/sub-category-items/details/${devisId}/delete`, {
      method: 'DELETE',
    });

    if (response.ok) {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Sub-category item deleted successfully!',
      });
      // Optionally update UI or perform other actions upon successful deletion
    } else {
      const errorData = await response.json();
      console.error('Error:', errorData);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: errorData.error || 'An error occurred while deleting the sub-category item',
      });
    }
  } catch (error) {
    console.error('Network Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'Network Error',
      text: 'Failed to communicate with the server. Please try again later.',
    });
  }
};

      

      const handleDeleteDetaill = async (recordId) => {
        try {
          const response = await fetch(`https://icanto-api.gisway.org/api/v1/sub-category-items/details/${recordId}/delete`, {
            method: 'DELETE',
          });
          if (!response.ok) {
            throw new Error('Failed to delete detail');
          }
          // If successful, update the state to reflect the removal of the detail
          setPdf((prevItems) => prevItems.filter((item) => item.details.id !== recordId));
        } catch (error) {
          console.error('Error deleting detail:', error);
        }
      };



    const [showPopup, setShowPopup] = useState(false);
    const handleNewComandClick = () =>{

      Swal.fire({
        icon: "question",
        title: "Choose an action",
        showCancelButton: true,
        confirmButtonText: "immobilier",
        cancelButtonText: "Visselle",
      }).then(async(result) => {
        if (result.isConfirmed) {
          const newId = uuidv4();
          const urlParams = new URLSearchParams(window.location.search); 
          const customerId = urlParams.get('customerId');
          window.location.href = `/new-form?customerId=${customerId}&serialNumber=${newId}&first_name=${firstName}&last_name=${lastName}&email=${adresse}&phone=${telephone}`;
        }
        else if (result.dismiss === Swal.DismissReason.cancel) {
          const newId = uuidv4();
          const urlParams = new URLSearchParams(window.location.search); 
          const customerId = urlParams.get('customerId');
          window.location.href = `/visselleD?customerId=${customerId}&serialNumber=${newId}&first_name=${firstName}&last_name=${lastName}&email=${adresse}&phone=${telephone}`;
        }
      })

          }
    const handleViewDevisClick = () => {
        setShowPopup(true);
      }
      const handleClosePopup = () => {
        setShowPopup(false);
      };

    const Popup = ({ handleClose }) => {

        return (
<div className="popup-backgroundd">{console.log('nom:',nom,' adress:', adresse, ' telephone:', telephone)}
{console.log(pdf)}
{console.log(subcategoryItems)}
      <div className="popupp">
        <h2>Devis Générés</h2>
        <p className="describingp">Voici les devis générés pour ce client.</p>
        <button onClick={handleClose} className="close-popupp">Fermer</button>
        <div className="devises-container">{console.log(pdf)}
        {
          data_uniqueSerialNumbers && data_uniqueSerialNumbers.map(item => (
            console.log("----- item ----.",item),
            console.log('---------pdf-------------',pdf),
  <div key={item} className="devis">
    {          //console.log(item[0].sub_category.id)
    <div className="pdf-icon-genrated">{console.log(pdf)}
    <FontAwesomeIcon icon={item.state==='PENDING'?faD:faF} style={{ color: 'white' }}/>
  </div>
    }

    <p className="generation-date-p">{console.log(item)}
      Date de génération du devis: <span className="generated-devis-date-span">{item.created_at}</span>
    </p>{console.log('item.serial_number:',item.serial_number,' item:', item, 'customeId:',customerId)}
    <div className="devis-actions">
      <div className="devis-actions-container" onClick={() => showwEdit(item.serial_number, item.subcategoryId, customerId)}>
        <FontAwesomeIcon icon={faPenNib} className="icon-action-dev"/>{console.log(item)}
      </div>
      {console.log(subcategoryItems)}
      {console.log(item)}
      {item && ( // Add a check for item to prevent accessing properties on undefined
        <div className="devis-actions-container" onClick={() => generateX(item.serial_number, item)}>
          <FontAwesomeIcon icon={faDownload} className="icon-action-dev" />
        </div>
      )}
      <div className="devis-actions-container">{console.log(pdf)}
      {item !== undefined &&( 
              <FontAwesomeIcon icon={faTrash} className="icon-action-dev" onClick={() => deleteItemsBySerialNumber(item.serial_number)} 
              />
      )}




      </div>{console.log(item.id)}
      {console.log('------------------------serial',item.serial_number)}
      <input 
    type="checkbox" 
    className="done" 
    onClick={() => {
      console.log('Checkbox clicked for item with serial:', item.id); // Debugging log
      handleCheck(item.serial_number);
    }} 
  />

      {/*item && ( // Add a check for item to prevent accessing properties on undefined
        <div className="devis-actions-container" onClick={() => generateX(item.serial_number, item)}>
          <FontAwesomeIcon icon={faDownload} className="icon-action-dev" />
        </div>
      )*/}
    </div>
  </div>
))        
        
      /*  pdf && pdf.map(item => (
  <div key={item.id} className="devis">
    {          //console.log(item[0].sub_category.id)
    }
    <div className="pdf-icon-genrated">{console.log(pdf)}
      <FontAwesomeIcon icon={faFilePdf} style={{ color: 'white' }}/>
    </div>
    <p className="generation-date-p">{console.log(item.created_at_fr)}
      Date de génération du devis: <span className="generated-devis-date-span">{item.created_at_fr}</span>
    </p>
    <div className="devis-actions">
      <div className="devis-actions-container">
        <FontAwesomeIcon icon={faPenNib} className="icon-action-dev" onClick={() => showwEdit(item.serial_number, item.sub_category.id, customerId, item.id)}/>{console.log(item.id)}
      </div>
      {console.log(subcategoryItems)}
      {console.log(item)}
      {item && ( // Add a check for item to prevent accessing properties on undefined
        <div className="devis-actions-container"> {item.details[0] !== undefined &&(console.log(item.details[0].id))}{console.log('state on item.state:',item.state)}
          <FontAwesomeIcon icon={faDownload} className="icon-action-dev" onClick={() => generateX(item, item.state)} />{console.log(item.state)}
        </div>
      )}
      <div className="devis-actions-container">{console.log('item:',item)}
      {item.details[0] !== undefined &&(
              <FontAwesomeIcon icon={faTrash} className="icon-action-dev" onClick={() => handleDeleteDetail(item[0].details[0].id)} 
              />
      )}

      </div>
    </div>
  </div>
))*/
}


          {pdf.length === 0 && <p>Aucun devis trouvé pour ce client.</p>}
        </div>
      </div>
    </div>
        );
      };
      function calculateTotalPriceWithVAT(objects) {
        let totalPrice = 0;
        console.log('price objects:',objects)
        objects.forEach(obj => {
          console.log(obj)
          //if (obj.details && Array.isArray(obj.details)) {
            //obj.details.forEach(detail => {
              //totalPrice += detail.amount;
            //});
          //}
        });
      
        const totalPriceTTC = totalPrice * 1.2; // Applying VAT of 20%
      
        return {
          totalPrice: totalPrice,
          totalPriceTTC: totalPriceTTC
        };
      }
//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< BEGIN PDF GENERATOR >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const generatePdf = (myDataDeviFacture) => {
  console.log('filteredItems to generqte pdf',myDataDeviFacture);
  let state_page=myDataDeviFacture.state
  let text_alert=''
  if (state_page === 'DEVIS') {
    text_alert='Ce devis est valable pendant 6 mois.'
  }else{
    text_alert=''
  }

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  //const fabricType = filteredItems.details[0].fabric_type?.category;
  //const color = filteredItems.details[0].fabric_type?.color;
  const chunkSize = 6;
  const chunks = [];
  
  for (let i = 0; i < myDataDeviFacture.data.length; i += chunkSize) {
    chunks.push(myDataDeviFacture.data.slice(i, i + chunkSize));
  }
  console.log(chunks)

  const docDefinition = {
    pageMargins: [40, 160, 40, 160],
    header: [
      {
        height: 500,
        columns: [
          { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
          { text: state_page, fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
        ]
      },
      { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248' }] }
    ],
    footer: function(currentPage, pageCount) {
      return [
        { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style: 'footer11' },
        { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
        { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
        { text: 'Tel : 00212661093929', style: 'footer1' },
        { text: text_alert, style: 'warning' },
        { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
      ];
    },
    content: [
      { columns: [
        { text: 'Client: ' + nom, style: 'client' },
        { text: `${state_page} N°:`, style: 'devis' },
        { text: '1', style: 'x' }
      ]},
      { columns: [
        { text: 'Adresse Client: ' + adresse, style: 'adressClient' },
        { text: "Date:", style: 'dateE' },
        { text: myDataDeviFacture.created_at, style: 'date' }
      ]},
      { columns: [
        { text: 'Telephone: ' + telephone, style: 'adressClient' },
        { text: "" },
        { text: '' }
      ]},
      { columns: [
        { text: '', style: 'vidText' }
      ]},
      ...chunks.map(chunk => ({
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*', '*', '*'],
          body: [
            [
              //{ text: 'Image', style: 'tableHeader' },
              { text: 'Gamme', style: 'tableHeader' },
              { text: 'Détails', style: 'tableHeader' },
              { text: 'Prix (Dhs)', style: 'tableHeader' },
              { text: 'Quantite', style: 'tableHeader' },
              { text: 'Total (Dhs)', style: 'tableHeader' }
            ],
            ...chunk.map(item => [
              //{ text: 'image64', fillColor: '#f3f3f3', width: 40, height: 40 },
              { text: `${item.model}`, fillColor: '#f3f3f3' },
              {
                text: `${item.sub_category_name} \n${item.fabric_name} \n${item.details_category} \n${item.details_color} \n${item.dimension}`,
                fillColor: '#f3f3f3',
                fontSize: 8,
                alignment: 'start',
                bold: true
              },
              { text: `${item.prix}`, fillColor: '#f3f3f3' },
              { text: `${item.quantite}`, fillColor: '#f3f3f3' },
              {text: `${item.prix*item.quantite}`, fillColor: '#f3f3f3'}
            ])
          ],
          border: []
        },
        style: 'tableStyle', marginTop:70
      })),
      {
        layout: 'noBorders',
        table: {
          widths: [100, 100],
          body: [
            [
              { text: 'TOTAL HT', style: 'tableHeader1' },
              { text: formatNumberWithTwoDecimals(myDataDeviFacture.totalht), style: 'value' }
            ],
            [
              { text: 'TVA', style: 'tableHeader1' },
              { text: '20%', style: 'value' }
            ],
            [
              { text: 'TOTAL TTC', style: 'tableHeaderTTC' },
              { text:formatNumberWithTwoDecimals(myDataDeviFacture.totalht*1.2), style: 'valueTTC' }
            ]
          ]
        },
        style: 'table2',
        margin: [307, 15, null, null]
      },
      { text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 }
    ],
    styles: {
      foot: {
        alignment: 'center'
      },
      facture: {
        fontSize: 30,
        color: '#7b6248',
        bold: true,
        margin: [40, 40, 0, 0]
      },
      tableStyle: {
        marginTop:70,
        alignment: 'center',
        marginTop: 50,
        color: '#7b6248'
      },
      adressText: {
        color: '#7b6248'
      },
      factureA: {
        marginTop: 15,
        bold: true,
        color: '#7b6248'
      },
      client: {
        color: '#7b6248',
        alignment: 'left'
      },
      devis: {
        alignment: 'center',
        color: '#7b6248',
        margin: [110, 0, 0, 0],
        bold: true,
        fontSize: 12
      },
      x: {
        alignment: 'right',
        marginRight: 9,
        color: '#7b6248',
        bold: true
      },
      adressClient: {
        marginTop: 5,
        color: '#7b6248'
      },
      dateE: {
        alignment: 'start',
        color: '#7b6248',
        margin: [116, 2, 0, 0],
        bold: true
      },
      date: {
        alignment: 'start',
        color: '#7b6248',
        bold: true,
        margin: [107, 2, 0, 4]
      },
      ref: {
        alignment: 'start',
        color: '#7b6248',
        margin: [118, 8, 0, 0],
        bold: true
      },
      refN: {
        alignment: 'right',
        color: '#7b6248',
        bold: true,
        margin: [135, 8, 9, 4]
      },
      tableHeader: {
        bold: true,
        fillColor: '#f3f3f3',
        color: '#7b6248',
        alignment: 'center'
      },
      tableHeaderTTC: {
        fillColor: '#7b6248',
        color: 'white'
      },
      valueTTC: {
        fillColor: '#7b6248',
        color: 'white'
      },
      table2: {},
      tableHeader1: {
        fillColor: '#f3f3f3'
      },
      value: {
        fillColor: '#f3f3f3'
      },
      footer1: {
        fontSize: 10,
        bold: true,
        alignment: 'center'
      },
      warning: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        color: 'red'
      },
      footer11: {
        margin: [0, 5, 0, 0],
        fontSize: 10,
        bold: true,
        alignment: 'center'
      },
      total: {
        alignment: 'right',
        margin: [0, 15, 0, 0]
      },
      totalTitle: {
        margin: [0, 15, 0, 0],
        bold: true,
        alignment: 'right'
      },
      HT: {
        margin: [0, 30, 0, 0]
      },
      totalPriceBackground: {
        fillColor: '#D9D6DC'
      }
    }
  };

  const logoPath = './images/logo1.png';
  const sofaImagePath = './images/sofa2.png';

  convertImageToBase64(logoPath, base64Logo => {
    convertImageToBase64(sofaImagePath, base64SofaImage => {
      docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    });
  });
};

  
  const convertImageToBase64 = (url, callback) => {
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function() {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
    };
    img.src = url;
  };
  
  const convertImageUrlToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const base64Image = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
      return base64Image;
    } catch (error) {
      console.error("Error converting image to base64:", error);
      return null;
    }
  };








//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END PDF GENERATOR >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>





    return(
        <div className="welcome-back-container">
            <MyHeader />
            <div className="back-hero">
              <h5>Le Client {firstName} {lastName}</h5>
                <h1>Gestion des Devis Clients</h1>
                <p>Consultez et créez des devis pour vos clients afin de faciliter la gestion et le suivi des commandes de votre magasin.</p>
                <div className="welcome-ctas">
                    <button onClick={handleViewDevisClick}>Voir les devis générés</button>
                    <button onClick={handleNewComandClick}>Nouvelle commande</button>
                </div>

            </div>
            {showPopup && <Popup handleClose={handleClosePopup} />}
        </div>
    )
}
export default WelcomeBack;
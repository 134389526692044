import './App.css';
import './components/pop-up.css';
import DynamicSelects from './components/form';
import { useState } from 'react';
import MyHeader from './components/header';
import Register from './components/Register';
import {Routes, Route} from 'react-router-dom'
import LoginCopy from './components/LoginCopy';
function App() {
  const images = ['sofaPic1.jpg', 'sofa2.png', 'sofa3.jpg', 'sofa4.jpg'];








  const [selectedImage, setSelectedImage] = useState(images[0]);
  const handleImageClick = (image) => {
    setSelectedImage(image);
  <Routes>
    <Route path='/Register' element={<Register />} />
    <Route path='/loginCopy' element={<LoginCopy />} />

  </Routes>
  };
  return (
    <div className="App">
      {/*<VeryHeader /> */}
      <MyHeader />
      <div className='main'>
      <div className='left-side'>
      <div className='image-list large-screen'>
        {images.map((image, index) => (
          <img
            key={index}
            src={`/images/${image}`}
            alt="beautiful sofa"
            onClick={() => handleImageClick(image)}
            className={selectedImage === image ? 'selected' : ''}
          />
        ))}
      </div>
      <div className='main-image'>
        <img
          className="main-image-display"
          src={`/images/${selectedImage}`}
          alt="beautiful sofa"
        />
      </div>
      <div className='image-list small-screen'>
        {images.map((image, index) => (
          <img
            key={index}
            src={`/images/${image}`}
            alt="beautiful sofa"
            onClick={() => handleImageClick(image)}
            className={selectedImage === image ? 'selected' : ''}
          />
        ))}
      </div>
    </div>

          <DynamicSelects/>
      </div>
      {/*<hr className='half-line'/>
      <Description /> */}
    </div>
  );
}

export default App;

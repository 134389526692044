import './visselle.css';
import { useState, useEffect } from 'react';

const Visselle = () => {
  const [visselles, setVisselles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [produitId, setProduitId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [serviceText, setServiceText] = useState('');
  const [serviceId, setServiceId] = useState(null);
  const [prixVente, setPrixVente] = useState();

  useEffect(() => {
    fetch('https://icanto-api.gisway.org/api/v1/services/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setVisselles(data.items);
        if (data.items.length > 0) {
          setSelectedTitle(data.items[0].title);
          setProduitId(data.items[0].id);
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (produitId) {
      fetch(`https://icanto-api.gisway.org/api/v1/products/services/${produitId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          setProductDetails(data.items);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [produitId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleChange = (event) => {
    const selectedIndex = event.target.options.selectedIndex;
    setSelectedTitle(event.target.value);
    setProduitId(visselles[selectedIndex].id);
  };

  const handleChangeDetails = (event) => {
    const selectedIndex = event.target.options.selectedIndex - 1; // Adjusting for the default option
    if (selectedIndex >= 0 && selectedIndex < productDetails.length) {
      const selectedProductDetail = productDetails[selectedIndex];
      setServiceId(event.target.value);
      setServiceText(selectedProductDetail?.designation || '');
      setPrixVente(selectedProductDetail?.prix_vente || 0);
    } else {
      setServiceId(null);
      setServiceText('');
      setPrixVente(0);
    }
  };

  return (
    <div className="left-right">
      <div className="left">
        <div className="main-image">
          <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
        </div>
        <div className="images-variants">
          <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
          <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
          <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
          <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
        </div>
        {visselles.map((visselle) => (
          <p key={visselle.id}>{visselle.title}</p>
        ))}
      </div>
      <div className="right">
        <h1>{selectedTitle !== undefined && (selectedTitle)}</h1>
        <h4>{prixVente !== undefined ? (prixVente) : '0'} DHs</h4>
        <label>Choisizer Le Produit</label><br />
        <select onChange={handleChange} value={selectedTitle}>
          {visselles.map((visselle) => (
            <option key={visselle.id} value={visselle.title}>
              {visselle.title}
            </option>
          ))}
        </select><br />
        <label>Choisizer Le Service</label><br />
        <select onChange={handleChangeDetails} value={serviceId}>
          <option value="">Choisisser Le Service</option>
          {productDetails.map((productDetail) => (
            <option key={productDetail.id} value={productDetail.id}>
              {productDetail.designation}
            </option>
          ))}
        </select>
        <p>Selected Service Text: {serviceText}</p>
        <p>Selected Service ID: {serviceId}</p>
        <div>
        <button>Confirmer</button>
        </div>
      </div>

    </div>
  );
};

export default Visselle;

import React from 'react';
import { useState } from 'react';
import './responsiveHeader.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faUser } from '@fortawesome/free-solid-svg-icons';


const MyHeader = () => {
    const[isActive, setIsActive] = useState(false)
    const handleMenuClick = () =>{
        {isActive === false ? setIsActive(true) : setIsActive(false)}
    }
    const [activeMenu, setActiveMenu] = useState("active-product")
  return (
    <div className='nav-bar'>
        <div className='nav-logo'><img src='./images/logo.png' alt='err' /></div>
        <ul className={`menu ${isActive === true ? 'active' : ''}`}>
            <li onClick={()=>setActiveMenu("active-product")} className={activeMenu === 'active-product' ? 'active menu-item' : ''}>Nos Produits</li>
            <li onClick={()=>setActiveMenu("active-shop")} className={activeMenu === 'active-shop' ? 'active menu-item' : ''}>La Boutique</li>
            <li onClick={()=>setActiveMenu("active-about")} className={activeMenu === 'active-about' ? 'active menu-item' : ''}>À Propos</li>
            <li onClick={()=>setActiveMenu("active-contact")} className={activeMenu === 'active-contact' ? 'active menu-item' : ''}>Contactez Nous</li>
        </ul>
        <div className='right-header'>
            <div className='shopping-cart'>
                <FontAwesomeIcon className='header-icon' icon={faCartShopping} style={{color: "#ffffff",}} />
            <p>Panier {'(0)'}</p>
            </div>
            <div className='login'>
            <FontAwesomeIcon className='header-icon' icon={faUser} style={{color: "#ffffff",}} />
            <Link to='/login' className='link'><p>Connexion</p></Link>
            </div>
            <div className='burger-icon' onClick={handleMenuClick}>
            &#9776;
            </div>
        </div>
    </div>
  );
};

export default MyHeader;

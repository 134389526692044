import React from "react";
import './userInfos.css'
import MyHeader from './header';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faUser, faIdCard, faLocationDot, faPhone, faEnvelope, faX } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { faUser as faUserRegular } from "@fortawesome/free-regular-svg-icons";
import { v4 as uuidv4 } from 'uuid';

import axios from "axios";

const UserInfos = () =>{
    const [nom, setNom] = useState("ahmad");
    const [cin, setCin] = useState("");
    const [adresse, setAdresse] = useState("");
    const [telephone, setTelephone] = useState("");
    const [email, setEmail] = useState("");
    const [clientExist, setClientExist] = useState(false);
    const [formActive, setFormActive] = useState(true);
    const isFormComplete = nom && cin && adresse && telephone && email;
    const [customer, setCustomer] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const highlightMatch = (text) => {
        const index = text.toLowerCase().indexOf(customer.toLowerCase());
        if (index !== -1) {
          const before = text.slice(0, index);
          const match = text.slice(index, index + customer.length);
          const after = text.slice(index + customer.length);
          return (
            <span>
              {before}
              <strong>{match}</strong>
              {after}
            </span>
          );
        }
        return text;
      };
    useEffect(() => {
        if (customer.trim() !== '') {
          // Fetch customers from API
          axios.get('https://icanto-api.gisway.org/api/v1/customers/')
            .then(response => {
              // Filter customers based on search query (name or email)
              const filteredCustomers = response.data.items.filter(item => {
                const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
                return fullName.includes(customer.toLowerCase()) || item.email.toLowerCase().includes(customer.toLowerCase());
              });
              setSuggestions(filteredCustomers);
              setShowSuggestions(true);
            })
            .catch(error => {
              console.error('Error fetching customers:', error);
            });
        } else {
          setSuggestions([]);
          setShowSuggestions(false);
        }
      }, [customer]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setCustomer(newValue); // Update the state
        // Use the updater function form to access the most recent state value
        setCustomer((prevCustomer) => {
          if (prevCustomer && prevCustomer.trim() !== '') {
            setShowSuggestions(true);
          } else {
            setShowSuggestions(false);
          }
          return newValue; // Return the new value
        });
      };

    useEffect(() => {
        const storedNom = localStorage.getItem("nom");
        const storedCin = localStorage.getItem("cin");
        const storedAdresse = localStorage.getItem("adresse");
        const storedTelephone = localStorage.getItem("telephone");
        const storedEmail = localStorage.getItem("email");

        if (storedNom) setNom(storedNom);
        if (storedCin) setCin(storedCin);
        if (storedAdresse) setAdresse(storedAdresse);
        if (storedTelephone) setTelephone(storedTelephone);
        if (storedEmail) setEmail(storedEmail);
    }, []);

    useEffect(() => {
        localStorage.setItem("nom", nom);
        localStorage.setItem("cin", cin);
        localStorage.setItem("adresse", adresse);
        localStorage.setItem("telephone", telephone);
        localStorage.setItem("email", email);
    }, [nom, cin, adresse, telephone, email]);
    const handleSubmit = async () => {
        console.log('Submitting form...');
        try {
            const response = await fetch('http://localhost:5000/api/customers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id:5,
                    first_name: nom,
                    last_name: cin,
                    email: email,
                    phone: telephone,
                    address: adresse,
                    city:"rabat",
                    country:"maroc"
                })
            });
    
            console.log('Response:', response);
    
            if (response.ok) {
                const data = await response.json();
                const newId = uuidv4();
                const queryParams = new URLSearchParams({
                    customerId: data.id, // Assuming the response contains the generated ID
                    first_name: nom,
                    last_name: cin,
                    email: email,
                    phone: telephone,
                    address: email,
                    serialNumber:newId
                    // Add other data as needed
                });
                // reset fields here
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'User information saved successfully!'
                });
                localStorage.setItem("serial","myserialtest")
                setTimeout(window.location.href = `/new-form?${queryParams.toString()}`, 9000);
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorData.error || 'An error occurred while saving user information'
                });
            }
        } catch (error) {
            console.error('Network Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Failed to communicate with the server. Please try again later.'
            });
        }
    };
    
    
    const handleClientExistClick = () =>{
        setClientExist(true)
        setFormActive(false)
    }
    const handleSuggestionClick = (suggestion) => {
        Swal.fire({
            icon: 'success',
            text: `Le client ${suggestion.first_name} - ${suggestion.last_name} a été sélectionné avec succès !`,
        }).then(() => {
            window.location.href = `/welcome-back?customerId=${suggestion.id}`;
        });
    }
    return(
        <div className="userTest gradient-background">
            <MyHeader />
            <div className="hero-user-infos">
                <div className="left">
                    <h1>Bienvenue sur Notre Plateforme en Ligne !</h1>
                    <p>Nous sommes heureux de vous accueillir sur notre plateforme en ligne ! Merci d'entrer vos informations ci-dessous !</p>
                    <div className="social-links">
                        <FontAwesomeIcon className="social-icon" icon={faFacebook} style={{color: "white"}}/>
                        <FontAwesomeIcon className="social-icon" icon={faInstagram} style={{color: "white"}}/>
                        <FontAwesomeIcon className="social-icon" icon={faX} style={{color: "white"}}/>
                        <FontAwesomeIcon className="social-icon" icon={faLinkedin} style={{color: "white"}}/>
                    </div>
                </div>
                <div className="right">
                    <div className="form-user-infos-container">
                        <p>Créez Votre Expérience de Shopping Unique</p>
                        <h1>Découvrez nos Offres Personnalisées</h1>
                        <div className={`flex-inputs ${clientExist ? 'client-exists' : ''}`}>
                            <div className="left-inputs">
                                <div className="input-name">
                                    <FontAwesomeIcon icon={faUser} style={{color: "white"}}/>
                                    <input type="text" placeholder="Nom*" value={nom} 
                                        onChange={(e) => setNom(e.target.value)} /> 
                                </div>
                                <div className="input-cin">
                                    <FontAwesomeIcon icon={faUserRegular} style={{color: "white"}}/>
                                    <input type="text" placeholder="Prénom*" value={cin} 
                                        onChange={(e) => setCin(e.target.value)} /> 
                                </div>
                            </div>
                            <div className="line-container">
                                <div className="vertical-line"></div>
                            </div>
                            <div className="right-inputs">
                                <div className="input-adress">
                                    <FontAwesomeIcon icon={faLocationDot} style={{color: "white"}}/>
                                    <input type="text" placeholder="Adresse*" value={adresse} 
                                        onChange={(e) => setAdresse(e.target.value)} /> 
                                </div>
                                <div className="input-num">
                                    <FontAwesomeIcon icon={faPhone} style={{color: "white"}}/>
                                    <input type="text" placeholder="Téléphone*" value={telephone} 
                                        onChange={(e) => setTelephone(e.target.value)} /> 
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className={`input-email ${clientExist ? 'client-exists' : ''}`}>
                            <FontAwesomeIcon icon={faEnvelope} style={{color: "white"}}/>
                            <input type="text" placeholder="Email*" value={email} 
                                onChange={(e) => setEmail(e.target.value)} /> 
                        </div>
                        <div className="customers-suggestions-container">
                            <input 
                                type="text" 
                                placeholder="Recherche par nom, prénom ou email." 
                                className={`search-client ${clientExist ? 'client-exists-show' : ''}`} 
                                value={customer} 
                                onChange={handleChange}
                            />
                            {showSuggestions &&
                            <div className="customers-suggestions">
                                {suggestions.map((suggestion, index) => (
                                <div key={index} className="single-customer-suggestion" onClick={() => handleSuggestionClick(suggestion)}
                                >
                                    {highlightMatch(`${suggestion.first_name} ${suggestion.last_name} - ${suggestion.email}`)}
                                </div>
                                ))}
                            </div>
                            }

                        </div>
                        <p className="exist-client" onClick={handleClientExistClick}>Le client existe déjà?</p>
                        <div className="user-infos-buttons">
                            <button className="user-infos-submit" onClick={handleSubmit}>Envoye</button>
                            <button className="user-infos-cancel">Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserInfos;

// DEVIS MUST BE STOCKED WITH INFOS
// WHEN GENERATING DEVIS YOU CAN MODIFY STUFF AFTER GENERATING IT
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.left-right{
    width: 100%;
    height: 100vh;
    padding: 30px;
    display: flex;
    background-color: #eff0f5;
}
.left{
    height: 100%;
    width: 50vw;
}
.main-image{
    height: 400px;
}
.main-image img{
    height: 500px;
    border-radius: 10px;
}
.images-variants{
    display: flex;
    gap: 13px;
    margin-top: 120px;
}
.images-variants img{
    width: 115px;
    border-radius: 6px;
}
.right{
    width: 100%;
    padding: 40px 120px;
}
.right label{
    margin-top: 20px;
}
.right select{
    width: 100%;
}
.breadcrumbs-redirect{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
}
.breadcrumbs-redirect p{
    margin-top: 30px;
    text-decoration: underline;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/visselle.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,SAAS;IACT,iBAAiB;AACrB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;AACf;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;AACnB","sourcesContent":[".left-right{\n    width: 100%;\n    height: 100vh;\n    padding: 30px;\n    display: flex;\n    background-color: #eff0f5;\n}\n.left{\n    height: 100%;\n    width: 50vw;\n}\n.main-image{\n    height: 400px;\n}\n.main-image img{\n    height: 500px;\n    border-radius: 10px;\n}\n.images-variants{\n    display: flex;\n    gap: 13px;\n    margin-top: 120px;\n}\n.images-variants img{\n    width: 115px;\n    border-radius: 6px;\n}\n.right{\n    width: 100%;\n    padding: 40px 120px;\n}\n.right label{\n    margin-top: 20px;\n}\n.right select{\n    width: 100%;\n}\n.breadcrumbs-redirect{\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    gap: 40px;\n}\n.breadcrumbs-redirect p{\n    margin-top: 30px;\n    text-decoration: underline;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import './AuthStyles.css';
import { Link } from 'react-router-dom';

const Register = ({ onRegister }) => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    onRegister({ username, email, password });
  };

  return (
    <div className='auth-app'>
      <div className="auth-container">
        <h2>Register</h2>
        <form onSubmit={handleSubmit}>
          <label className='register-label' htmlFor="username">Username:</label>
          <input
            placeholder='Username'
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />

          <label className='register-label' htmlFor="email">Email:</label>
          <input
            placeholder="Email"
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label className='register-label' htmlFor="password">Password:</label>
          <input
            placeholder="Password"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          
          <Link to='/'><button>Register</button></Link>

          <div className="already-have-account">
            Already have an account? <Link to='/login'>Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Register from './components/Register';
import LoginCopy from './components/LoginCopy';
//import Slider from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
//import RaportAdmin from './components/raportAdmin';
//import GestionDeStock from './components/gestion-de-stock';
//import LandingPageAdmin from './components/landing-page-admin';
//import TabletForm from './components/formAdminTablet';
//import CategoriesFetch from './components/CategoriesDelete';
import ParentComponent from './components/parent';
import UserInfos from './components/userInfos';
import WelcomeBack from './components/welcomeBack';
//import ListDevises from './components/listDevises';
//import ComponentTest from './components/testComponent';
import ModifyDevis from './components/modifyDevis';
import Visselle from './components/visselle';
import DynamicSelectsVisselle from './components/visselleForm';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <BrowserRouter>
      <Routes>
        <Route path='/register' element={<Register />} />
        <Route path='/login' element={<LoginCopy />} />
        <Route path='new-form' element={<ParentComponent />} />
        <Route path='/' element={<UserInfos />} />
        <Route path='/welcome-back' element={<WelcomeBack />} />
        <Route path='/modify' element={<ModifyDevis />} />
        <Route path='/visselle' element={<Visselle />} />
        <Route path='/visselleD' element={<DynamicSelectsVisselle />} />

      </Routes>
  </BrowserRouter>

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

let models=[
  {
    id:1,
    model:"I843 - SURF",
    modelImageUrl:'/images/Canapé-2pl.png',
    categorie:[
      {id:1,title:'V020 - Canapé 2pl'},
      {id:2,title:'V029 - canapé 2.5pl'},
      {id:3,title:'V030 - canapé 3pl'},
      {id:4,title:'V063 - canapé 3pl Maxi'},
      {id:5,title:'V0283 - Meridienne gauche'},
      {id:6,title:'V0284 - Meridienne droite'},
    ],
    sousCategorie:[
      {id:30,id_categorie:1,version:'V020A - Supports dossiers amovibles canapé 2pl', imageUrl:'./images/hocker.png'},
      {id:31,id_categorie:1,version:'V020B - Coussins assise canapé 2pl', imageUrl:'./images/fauteuil.png'},
      {id:32,id_categorie:1,version:'V020C - Coussins dossier canapé 2pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:34,id_categorie:1,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:35,id_categorie:1,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:36,id_categorie:2,version:'V029A - Supports dossiers amovibles canapé 2.5pl', imageUrl:'./images/hocker.png'},
      {id:37,id_categorie:2,version:'V029B - Coussins assise canapé 2.5pl', imageUrl:'./images/fauteuil.png'},
      {id:38,id_categorie:2,version:'V29C - Coussins dossier canapé 2.5pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:39,id_categorie:2,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:40,id_categorie:2,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:41,id_categorie:3,version:'V030A - Supports dossiers amovibles canapé 3pl', imageUrl:'./images/hocker.png'},
      {id:42,id_categorie:3,version:'V030B - Coussins assise canapé 3pl', imageUrl:'./images/fauteuil.png'},
      {id:43,id_categorie:3,version:'V030C - Coussins dossier canapé 3pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:44,id_categorie:3,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:45,id_categorie:3,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:46,id_categorie:4,version:'V063A - Supports dossiers amovibles canapé 3pl Maxi',imageUrl:'./images/hocker.png'},
      {id:47,id_categorie:4,version:'V063B - Coussins assise canapé 3pl Maxi', imageUrl:'./images/fauteuil.png'},
      {id:48,id_categorie:4,version:'V63C - Coussins dossier canapé 3pl Maxi', imageUrl:'./images/Canapé-2pl.png'},
      {id:49,id_categorie:4,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:50,id_categorie:4,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:51,id_categorie:5,version:'V283A - Supports dossiers amovibles merid. gauche', imageUrl:'./images/hocker.png'},
      {id:52,id_categorie:5,version:'V283B - Coussins assise meridienne gauche', imageUrl:'./images/fauteuil.png'},
      {id:53,id_categorie:5,version:'V283C - Coussins dossier meridienne gauche', imageUrl:'./images/Canapé-2pl.png'},
      {id:54,id_categorie:5,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:55,id_categorie:5,version:'V284A - Supports dossiers amovibles merid. droite', imageUrl:'./images/hocker.png'},
      {id:56,id_categorie:5,version:'V284B - Coussins assise meridienne droite', imageUrl:'./images/fauteuil.png'},
      {id:57,id_categorie:5,version:'V284C - Coussins dossier meridienne droite', imageUrl:'./images/Canapé-2pl.png'},
      {id:58,id_categorie:5,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
    ],
      orientation:[


      {id:1, L:166, H:85, P:92, id_souscategorie:1},
      {id:2, L:166, H:85, P:92, id_souscategorie:2},
      {id:3, L:166, H:85, P:92, id_souscategorie:3},
      {id:4, L:166, H:85, P:92, id_souscategorie:4},
      {id:5, L:166, H:85, P:92, id_souscategorie:5},
      {id:6, L:182, H:85, P:92, id_souscategorie:6},
      {id:7, L:182, H:85, P:92, id_souscategorie:7},
      {id:8, L:182, H:85, P:92, id_souscategorie:8},
      {id:9, L:182, H:85, P:92, id_souscategorie:9},
      {id:10, L:182, H:85, P:92, id_souscategorie:10},
      {id:11, L:214, H:85, P:92, id_souscategorie:11},
      {id:12, L:214, H:85, P:92, id_souscategorie:12},
      {id:13, L:214, H:85, P:92, id_souscategorie:13},
      {id:14, L:214, H:85, P:92, id_souscategorie:14},
      {id:15, L:214, H:85, P:92, id_souscategorie:15},
      {id:16, L:238, H:85, P:92, id_souscategorie:16},
      {id:17, L:238, H:85, P:92, id_souscategorie:17},
      {id:18, L:238, H:85, P:92, id_souscategorie:18},
      {id:19, L:238, H:85, P:92, id_souscategorie:19},
      {id:20, L:238, H:85, P:92, id_souscategorie:20},
      {id:21, L:156, H:85, P:92, id_souscategorie:21},
      {id:22, L:156, H:85, P:92, id_souscategorie:22},
      {id:23, L:156, H:85, P:92, id_souscategorie:23},
      {id:24, L:156, H:85, P:92, id_souscategorie:24},
      {id:25, L:156, H:85, P:92, id_souscategorie:25},



      ],
      textiles:[
          {id:1,id_souscategorie:1,type:'Tissu'},
          {id:2,id_souscategorie:1,type:'Cuir'},
          {id:3,id_souscategorie:1,type:'VELOUR'},
          {id:4,id_souscategorie:2,type:'Tissu'},
          {id:5,id_souscategorie:2,type:'Cuir'},
          {id:6,id_souscategorie:2,type:'VELOUR'},
          {id:7,id_souscategorie:3,type:'Tissu'},
          {id:8,id_souscategorie:3,type:'Cuir'},
          {id:9,id_souscategorie:3,type:'VELOUR'},
          {id:10,id_souscategorie:4,type:'Tissu'},
          {id:11,id_souscategorie:4,type:'Cuir'},
          {id:12,id_souscategorie:4,type:'VELOUR'},
          {id:13,id_souscategorie:5,type:'Tissu'},
          {id:14,id_souscategorie:5,type:'Cuir'},
          {id:15,id_souscategorie:5,type:'VELOUR'},
          {id:16,id_souscategorie:6,type:'Tissu'},
          {id:17,id_souscategorie:6,type:'Cuir'},
          {id:18,id_souscategorie:6,type:'VELOUR'},
          {id:19,id_souscategorie:7,type:'Tissu'},
          {id:20,id_souscategorie:7,type:'Cuir'},
          {id:21,id_souscategorie:7,type:'VELOUR'},
          {id:22,id_souscategorie:8,type:'Tissu'},
          {id:23,id_souscategorie:8,type:'Cuir'},
          {id:24,id_souscategorie:8,type:'VELOUR'},
          {id:25,id_souscategorie:9,type:'Tissu'},
          {id:26,id_souscategorie:9,type:'Cuir'},
          {id:27,id_souscategorie:9,type:'VELOUR'},
          {id:28,id_souscategorie:10,type:'Tissu'},
          {id:29,id_souscategorie:10,type:'Cuir'},
          {id:30,id_souscategorie:10,type:'VELOUR'},
      ],
      prix:[
          {id:1,id_textiles:1,serie:'TB10',prix:20245},
          {id:2,id_textiles:1,serie:'TC11',prix:21285},
          {id:3,id_textiles:1,serie:'TE15',prix:24301},
          {id:6,id_textiles:2,serie:'P10',prix:24821},
          {id:7,id_textiles:2,serie:'P11',prix:26277},
          {id:8,id_textiles:2,serie:'P15',prix:29224},
          {id:9,id_textiles:2,serie:'P25',prix:33176},
          {id:10,id_textiles:2,serie:'P30 ',prix:36608},
          {id:11,id_textiles:3,serie:'TB10',prix:10000},
          {id:11,id_textiles:4,serie:'TB10',prix:10000},
          {id:12,id_textiles:4,serie:'TC11',prix:10000},
          {id:13,id_textiles:4,serie:'TE15',prix:10000},
          {id:16,id_textiles:5,serie:'P10',prix:10000},
          {id:17,id_textiles:5,serie:'P11',prix:10000},
          {id:18,id_textiles:5,serie:'TP15',prix:10000},
          {id:19,id_textiles:5,serie:'P25',prix:10000},
          {id:20,id_textiles:5,serie:'P30 ',prix:10000},
          {id:21,id_textiles:6,serie:'TB10',prix:10000},
          {id:21,id_textiles:7,serie:'TB10',prix:10000},
          {id:22,id_textiles:7,serie:'TC11',prix:10000},
          {id:23,id_textiles:7,serie:'TE15',prix:10000},
          {id:26,id_textiles:8,serie:'P10',prix:10000},
          {id:27,id_textiles:8,serie:'P11',prix:10000},
          {id:28,id_textiles:8,serie:'P15',prix:10000},
          {id:29,id_textiles:8,serie:'P25',prix:10000},
          {id:30,id_textiles:8,serie:'P30 ',prix:10000},
          {id:31,id_textiles:9,serie:'TB10',prix:10000},
          {id:31,id_textiles:10,serie:'TB10',prix:10000},
          {id:32,id_textiles:10,serie:'TC11',prix:10000},
          {id:33,id_textiles:10,serie:'TE15',prix:10000},
          {id:36,id_textiles:11,serie:'P10',prix:10000},
          {id:37,id_textiles:11,serie:'P11',prix:10000},
          {id:38,id_textiles:11,serie:'P15',prix:10000},
          {id:39,id_textiles:11,serie:'P25',prix:10000},
          {id:40,id_textiles:11,serie:'P30 ',prix:10000},
          {id:41,id_textiles:12,serie:'TB10',prix:10000},
          {id:41,id_textiles:13,serie:'TB10',prix:10000},
          {id:42,id_textiles:13,serie:'TC11',prix:10000},
          {id:43,id_textiles:13,serie:'TE15',prix:10000},
          {id:46,id_textiles:14,serie:'P10',prix:10000},
          {id:47,id_textiles:14,serie:'P11',prix:10000},
          {id:48,id_textiles:14,serie:'P15',prix:10000},
          {id:49,id_textiles:14,serie:'P25',prix:10000},
          {id:50,id_textiles:14,serie:'P30 ',prix:10000},

          {id:51,id_textiles:15,serie:'TB10',prix:20245},
          {id:51,id_textiles:16,serie:'TB10',prix:20245},
          {id:52,id_textiles:16,serie:'TC11',prix:21285},
          {id:53,id_textiles:16,serie:'TE15',prix:24301},
          {id:56,id_textiles:17,serie:'P10',prix:24821},
          {id:57,id_textiles:17,serie:'P11',prix:26277},
          {id:58,id_textiles:17,serie:'P15',prix:29224},
          {id:59,id_textiles:17,serie:'P25',prix:33176},
          {id:60,id_textiles:17,serie:'P30 ',prix:36608},
          {id:61,id_textiles:18,serie:'TB10',prix:10000},
          {id:61,id_textiles:19,serie:'TB10',prix:10000},
          {id:62,id_textiles:19,serie:'TC11',prix:10000},
          {id:63,id_textiles:19,serie:'TE15',prix:10000},
          {id:66,id_textiles:20,serie:'P10',prix:10000},
          {id:67,id_textiles:20,serie:'P11',prix:10000},
          {id:68,id_textiles:20,serie:'P15',prix:10000},
          {id:69,id_textiles:20,serie:'P25',prix:10000},
          {id:70,id_textiles:20,serie:'P30 ',prix:10000},
          {id:71,id_textiles:21,serie:'TB10',prix:10000},
          {id:71,id_textiles:22,serie:'TB10',prix:10000},
          {id:72,id_textiles:22,serie:'TC11',prix:10000},
          {id:73,id_textiles:22,serie:'TE15',prix:10000},
          {id:76,id_textiles:23,serie:'P10',prix:10000},
          {id:77,id_textiles:23,serie:'P11',prix:10000},
          {id:78,id_textiles:23,serie:'P15',prix:10000},
          {id:79,id_textiles:23,serie:'P25',prix:10000},
          {id:80,id_textiles:23,serie:'P30 ',prix:10000},
          {id:81,id_textiles:24,serie:'TB10',prix:10000},
          {id:81,id_textiles:25,serie:'TB10',prix:10000},
          {id:82,id_textiles:25,serie:'TC11',prix:10000},
          {id:83,id_textiles:25,serie:'TE15',prix:10000},
          {id:86,id_textiles:26,serie:'P10',prix:10000},
          {id:87,id_textiles:26,serie:'P11',prix:10000},
          {id:88,id_textiles:26,serie:'P15',prix:10000},
          {id:89,id_textiles:26,serie:'P25',prix:10000},
          {id:90,id_textiles:26,serie:'P30 ',prix:10000},
          {id:91,id_textiles:27,serie:'TB10',prix:10000},
          {id:91,id_textiles:28,serie:'TB10',prix:10000},
          {id:92,id_textiles:28,serie:'TC11',prix:10000},
          {id:93,id_textiles:28,serie:'TE15',prix:10000},
          {id:96,id_textiles:29,serie:'P10',prix:10000},
          {id:97,id_textiles:29,serie:'P11',prix:10000},
          {id:98,id_textiles:29,serie:'P15',prix:10000},
          {id:99,id_textiles:29,serie:'P25',prix:10000},
          {id:100,id_textiles:29,serie:'P30 ',prix:10000},
          {id:101,id_textiles:30,serie:'TB10 ',prix:10000},
      ]
  },






  {
    id:2,
    model:"I553",
    modelImageUrl:'/images/hocker.png',
    categorie:[
      {id:1,title:'V001'},
      {id:2,title:'V010'},
      {id:3,title:'V020'},
      {id:4,title:'V029'},
      {id:5,title:'V030'},
      {id:6,title:'V090'},
      {id:7,title:'V185'},
      {id:8,title:'V002 / V003'},
      {id:9,title:'V008 / V009'},
      {id:10,title:'V114'},
      {id:11,title:'V115'},
      {id:12,title:'V050'},
      {id:13, title:'V627'}
    ],
    sousCategorie:[
      {id:1,id_categorie:1,version:'V001 - Hocker', imageUrl:'./images/hocker.png'},
      {id:2,id_categorie:2,version:'V010 - Fauteuil', imageUrl:'./images/fauteuil.png'},
      {id:3,id_categorie:3,version:'V020 - Canapé 2pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:4,id_categorie:4,version:'V029 - Canapé 2,5pl', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:5,id_categorie:5,version:'V030 - Canapé 3pl', imageUrl:'./images/Canapé-3pl.png'},
      {id:6,id_categorie:6,version:'V064 - Canapé 4pl', imageUrl:'./images/Canapé-4pl.png'},
      {id:7,id_categorie:7,version:'V185 - Canapé 5pl', imageUrl:'./images/Canapé-5pl.png'},
      {id:8,id_categorie:8,version:'V002 - Petit bâtard acc.gauche', imageUrl:'./images/Petit-bâtard-acc.gauche.png'},
      {id:9,id_categorie:8,version:'V003 - Petit bâtard acc.droite', imageUrl:'./images/Petit-bâtard-acc.droite.png'},
      {id:10,id_categorie:9,version:'V008 - Grand bâtard acc.gauche', imageUrl:'./images/Grand-bâtard-acc.gauche.png'},
      {id:11,id_categorie:9,version:'V009 - Grand bâtard acc.droite', imageUrl:'./images/Grand-bâtard-acc.droite.png'},
      {id:12,id_categorie:10,version:'V115 - Chauffeuse petite', imageUrl:'./images/Chauffeuse-petite.png'},
      {id:13,id_categorie:11,version:'V115 - Chauffeuse grand', imageUrl:'./images/Chauffeuse-grand.png'},
      {id:14,id_categorie:12,version:'V050 - Angle', imageUrl:'./images/Angle.png'},
      {id:15,id_categorie:13,version:'V627 - coussin deco (plumes)', imageUrl:'./images/coussin-deco.png'},
    ],
    orientation:[
      {id:1, L:100, H:73, T:40, id_souscategorie:1},
      {id:2, L:114, H:72, T:106, id_souscategorie:2},
      {id:3, L:189, H:72, T:106, id_souscategorie:3},
      {id:4, L:213, H:72, T:106, id_souscategorie:4},
      {id:5, L:237, H:72, T:106, id_souscategorie:5},
      {id:6, L:261, H:72, T:106, id_souscategorie:6},
      {id:7, L:296, H:72, T:106, id_souscategorie:7},
      {id:8, L:95, H:72, T:106, id_souscategorie:8},
      {id:9, L:95, H:72, T:106, id_souscategorie:9},
      {id:10, L:107, H:72, T:106, id_souscategorie:10},
      {id:11, L:107, H:72, T:106, id_souscategorie:11},
      {id:12, L:72, H:72, T:106, id_souscategorie:12},
      {id:13, L:84, H:72, T:106, id_souscategorie:13},
      {id:14, L:103, H:72, T:106, id_souscategorie:14},
      {id:15, L:0, H:0, T:0, id_souscategorie:15},
  ],
      textiles:[
          {id:1,id_souscategorie:1,type:'Tissu'},
          {id:2,id_souscategorie:1,type:'Cuir'},
          {id:2,id_souscategorie:1,type:'CuirTissyHaHa'},
          {id:3,id_souscategorie:2,type:'Tissu'},
          {id:4,id_souscategorie:2,type:'Cuir'},
          {id:5,id_souscategorie:3,type:'Tissu'},
          {id:6,id_souscategorie:3,type:'Cuir'},
          {id:7,id_souscategorie:4,type:'Tissu'},
          {id:8,id_souscategorie:4,type:'Cuir'},
          {id:9,id_souscategorie:5,type:'Tissu'},
          {id:10,id_souscategorie:5,type:'Cuir'},
          {id:11,id_souscategorie:6,type:'Tissu'},
          {id:12,id_souscategorie:6,type:'Cuir'},
          {id:13,id_souscategorie:7,type:'Tissu'},
          {id:14,id_souscategorie:7,type:'Cuir'},
          {id:15,id_souscategorie:8,type:'Tissu'},
          {id:16,id_souscategorie:8,type:'Cuir'},
          {id:17,id_souscategorie:9,type:'Tissu'},
          {id:18,id_souscategorie:9,type:'Cuir'},
          {id:19,id_souscategorie:10,type:'Tissu'},
          {id:20,id_souscategorie:10,type:'Cuir'},
          {id:21,id_souscategorie:11,type:'Tissu'},
          {id:22,id_souscategorie:11,type:'Cuir'},
          {id:23,id_souscategorie:12,type:'Tissu'},
          {id:24,id_souscategorie:12,type:'Cuir'},
          {id:24,id_souscategorie:12,type:'Duir'},            
      ],
      prix:[
          {id:1,id_textiles:1,serie:'TB P10 en TTC',prix:10764},
          {id:2,id_textiles:1,serie:'TC P11',prix:10857.6},
          {id:3,id_textiles:1,serie:'TE P15',prix:11793.6},
          {id:4,id_textiles:1,serie:'P20 en Dhs en TTC',prix:0},
          {id:5,id_textiles:1,serie:'P25 en dhs en TTC',prix:0},
          {id:6,id_textiles:1,serie:'P30 en dhs en TTC',prix:0},
          {id:7,id_textiles:2,serie:'TB P10 en TTC',prix:12292.8},
          {id:8,id_textiles:2,serie:'TC P11',prix:12885.6},
          {id:9,id_textiles:2,serie:'TE P15',prix:13665.6},
          {id:10,id_textiles:2,serie:'P20 en Dhs en TTC',prix:14289.6},
          {id:11,id_textiles:2,serie:'P25 en dhs en TTC',prix:14913.6},
          {id:12,id_textiles:2,serie:'P30 en dhs en TTC',prix:15693.6},
          {id:13,id_textiles:3,serie:'TB P10 en TTC',prix:27005.33},
          {id:14,id_textiles:3,serie:'TC P11',prix:27421.33},
          {id:15,id_textiles:3,serie:'TE P15',prix:31338.66},
          {id:16,id_textiles:3,serie:'P20 en Dhs en TTC',prix:0},
          {id:17,id_textiles:3,serie:'P25 en dhs en TTC',prix:0},
          {id:18,id_textiles:3,serie:'P30 en dhs en TTC',prix:0},
          {id:19,id_textiles:4,serie:'TB P10 en TTC',prix:33349.33},
          {id:20,id_textiles:4,serie:'TC P11',prix:35949.33},
          {id:21,id_textiles:4,serie:'TE P15',prix:39173.33},
          {id:22,id_textiles:4,serie:'P20 en Dhs en TTC',prix:41738.66},
          {id:23,id_textiles:4,serie:'P25 en dhs en TTC',prix:44338.66},
          {id:24,id_textiles:4,serie:'P30 en dhs en TTC',prix:47562.66},
          {id:25,id_textiles:5,serie:'TB P10 en TTC',prix:41669.33},
          {id:26,id_textiles:5,serie:'TC P11',prix:42224},
          {id:27,id_textiles:5,serie:'TE P15',prix:47701.33},
          {id:28,id_textiles:5,serie:'P20 en Dhs en TTC',prix:0},
          {id:29,id_textiles:5,serie:'P25 en dhs en TTC',prix:0},
          {id:30,id_textiles:5,serie:'P30 en dhs en TTC',prix:0},
          {id:31,id_textiles:6,serie:'TB P10 en TTC',prix:50578.66},
          {id:32,id_textiles:6,serie:'TC P11',prix:54184},
          {id:33,id_textiles:6,serie:'TE P15',prix:58725.33},
          {id:34,id_textiles:6,serie:'P20 en Dhs en TTC',prix:62365.33},
          {id:35,id_textiles:6,serie:'P25 en dhs en TTC',prix:65970.66},
          {id:36,id_textiles:6,serie:'P30 en dhs en TTC',prix:70512},
          {id:37,id_textiles:7,serie:'TB P10 en TTC',prix:48013.33},
          {id:38,id_textiles:7,serie:'TC P11',prix:48637.33},
          {id:39,id_textiles:7,serie:'TE P15',prix:54877.33},
          {id:40,id_textiles:7,serie:'P20 en Dhs en TTC',prix:0},
          {id:41,id_textiles:7,serie:'P25 en dhs en TTC',prix:0},
          {id:42,id_textiles:7,serie:'P30 en dhs en TTC',prix:0},
          {id:43,id_textiles:8,serie:'TB P10 en TTC',prix:58136},
          {id:44,id_textiles:8,serie:'TC P11',prix:62296},
          {id:45,id_textiles:8,serie:'TE P15',prix:67461.33},
          {id:46,id_textiles:8,serie:'P20 en Dhs en TTC',prix:71586.66},
          {id:47,id_textiles:8,serie:'P25 en dhs en TTC',prix:75712},
          {id:48,id_textiles:8,serie:'P30 en dhs en TTC',prix:80912},
          {id:49,id_textiles:9,serie:'TB P10 en TTC',prix:54149.33},
          {id:50,id_textiles:9,serie:'TC P11',prix:54877.33},

          {id:54,id_textiles:9,serie:'P30 en dhs en TTC',prix:0},
          {id:55,id_textiles:10,serie:'TB P10 en TTC',prix:65554.66},
          {id:56,id_textiles:10,serie:'TC P11',prix:70200},
          {id:57,id_textiles:10,serie:'TE P15',prix:76024},
          {id:58,id_textiles:10,serie:'P20 en Dhs en TTC',prix:80669.33},
          {id:59,id_textiles:10,serie:'P25 en dhs en TTC',prix:85314.66},
          {id:60,id_textiles:10,serie:'P30 en dhs en TTC',prix:91104},
          {id:49,id_textiles:11,serie:'TB P10 en TTC',prix:64618.66},
          {id:50,id_textiles:11,serie:'TC P11',prix:65381.33},
          {id:51,id_textiles:11,serie:'TE P15',prix:73181.33},
          {id:52,id_textiles:11,serie:'P20 en Dhs en TTC',prix:0},
          {id:53,id_textiles:11,serie:'P25 en dhs en TTC',prix:0},
          {id:54,id_textiles:11,serie:'P30 en dhs en TTC',prix:0},
          {id:55,id_textiles:12,serie:'TB P10 en TTC',prix:77272},
          {id:56,id_textiles:12,serie:'TC P11',prix:82402.66},
          {id:57,id_textiles:12,serie:'TE P15',prix:88850.66},
          {id:58,id_textiles:12,serie:'P20 en Dhs en TTC',prix:94016},
          {id:59,id_textiles:12,serie:'P25 en dhs en TTC',prix:99181.33},
          {id:60,id_textiles:12,serie:'P30 en dhs en TTC',prix:105629.33},
          {id:61,id_textiles:13,serie:'TB P10 en TTC',prix:73493.33},
          {id:62,id_textiles:13,serie:'TC P11',prix:74325.33},
          {id:63,id_textiles:13,serie:'TE P15',prix:82922.66},
          {id:64,id_textiles:13,serie:'P20 en Dhs en TTC',prix:0},
          {id:65,id_textiles:13,serie:'P25 en dhs en TTC',prix:0},
          {id:66,id_textiles:13,serie:'P30 en dhs en TTC',prix:0},
          {id:67,id_textiles:14,serie:'TB P10 en TTC',prix:87394.66},
          {id:68,id_textiles:14,serie:'TC P11',prix:93080},
          {id:69,id_textiles:14,serie:'TE P15',prix:100186.66},
          {id:70,id_textiles:14,serie:'P20 en Dhs en TTC',prix:105872},
          {id:71,id_textiles:14,serie:'P25 en dhs en TTC',prix:111522.66},
          {id:72,id_textiles:14,serie:'P30 en dhs en TTC',prix:83962.66},
          {id:73,id_textiles:15,serie:'TB P10 en TTC',prix:22013.33},
          {id:74,id_textiles:15,serie:'TC P11',prix:22325.33},
          {id:75,id_textiles:15,serie:'TE P15',prix:25237.33},
          {id:76,id_textiles:15,serie:'P20 en Dhs en TTC',prix:0},
          {id:77,id_textiles:15,serie:'P25 en dhs en TTC',prix:0},
          {id:78,id_textiles:15,serie:'P30 en dhs en TTC',prix:0},
          {id:79,id_textiles:16,serie:'TB P10 en TTC',prix:26762.66},
          {id:80,id_textiles:16,serie:'TC P11',prix:28704},
          {id:81,id_textiles:16,serie:'TE P15',prix:31096},
          {id:82,id_textiles:16,serie:'P20 en Dhs en TTC',prix:33037.33},
          {id:83,id_textiles:16,serie:'P25 en dhs en TTC',prix:34978.66},
          {id:84,id_textiles:16,serie:'P30 en dhs en TTC',prix:37370.66},
          {id:85,id_textiles:17,serie:'TB P10 en TTC',prix:22013.33},
          {id:86,id_textiles:17,serie:'TC P11',prix:22325.33},
          {id:87,id_textiles:17,serie:'TE P15',prix:25237.33},
          {id:88,id_textiles:17,serie:'P20 en Dhs en TTC',prix:0},
          {id:89,id_textiles:17,serie:'P25 en dhs en TTC',prix:0},
          {id:90,id_textiles:17,serie:'P30 en dhs en TTC',prix:0},
          {id:91,id_textiles:18,serie:'TB P10 en TTC',prix:26762.66},
          {id:92,id_textiles:18,serie:'TC P11',prix:28704},
          {id:93,id_textiles:18,serie:'TE P15',prix:31096},
          {id:94,id_textiles:18,serie:'P20 en Dhs en TTC',prix:33037.33},
          {id:95,id_textiles:18,serie:'P25 en dhs en TTC',prix:34978.66},
          {id:96,id_textiles:18,serie:'P30 en dhs en TTC',prix:37370.66},
          {id:97,id_textiles:19,serie:'TB P10 en TTC',prix:24648},
          {id:98,id_textiles:19,serie:'TC P11',prix:24960},
          {id:99,id_textiles:19,serie:'TE P15',prix:28357.33},
          {id:100,id_textiles:19,serie:'P20 en Dhs en TTC',prix:0},
          {id:101,id_textiles:19,serie:'P25 en dhs en TTC',prix:0},
          {id:102,id_textiles:19,serie:'P30 en dhs en TTC',prix:0},
          {id:103,id_textiles:20,serie:'TB P10 en TTC',prix:30090.66},
          {id:104,id_textiles:20,serie:'TC P11',prix:32344},
          {id:105,id_textiles:20,serie:'TE P15',prix:35117.33},
          {id:106,id_textiles:20,serie:'P20 en Dhs en TTC',prix:37336},
          {id:107,id_textiles:20,serie:'P25 en dhs en TTC',prix:39554.66},
          {id:108,id_textiles:20,serie:'P30 en dhs en TTC',prix:42362.66},
          {id:109,id_textiles:21,serie:'TB P10 en TTC',prix:24648},
          {id:110,id_textiles:21,serie:'TC P11',prix:24960},
          {id:111,id_textiles:21,serie:'TE P15',prix:28357.33},
          {id:112,id_textiles:21,serie:'P20 en Dhs en TTC',prix:0},
          {id:113,id_textiles:21,serie:'P25 en dhs en TTC',prix:0},
          {id:114,id_textiles:21,serie:'P30 en dhs en TTC',prix:0},
          {id:115,id_textiles:22,serie:'TB P10 en TTC',prix:30090.66},
          {id:116,id_textiles:22,serie:'TC P11',prix:32344},
          {id:117,id_textiles:22,serie:'TE P15',prix:35117.33},
          {id:118,id_textiles:22,serie:'P20 en Dhs en TTC',prix:37336},
          {id:119,id_textiles:22,serie:'P25 en dhs en TTC',prix:39554.66},
          {id:120,id_textiles:22,serie:'P30 en dhs en TTC',prix:42362.66},
          {id:121,id_textiles:23,serie:'TB P10 en TTC',prix:19205.33},
          {id:122,id_textiles:23,serie:'TC P11',prix:19378.66},
          {id:123,id_textiles:23,serie:'TE P15',prix:21424},
          {id:124,id_textiles:23,serie:'P20 en Dhs en TTC',prix:0},
          {id:125,id_textiles:23,serie:'P25 en dhs en TTC',prix:0},
          {id:126,id_textiles:23,serie:'P30 en dhs en TTC',prix:0},
          {id:127,id_textiles:24,serie:'TB P10 en TTC',prix:22464},
          {id:128,id_textiles:24,serie:'TC P11',prix:23816},
          {id:129,id_textiles:24,serie:'TE P15',prix:25480},
          {id:130,id_textiles:24,serie:'P20 en Dhs en TTC',prix:26832},
          {id:131,id_textiles:24,serie:'P25 en dhs en TTC',prix:28149.33},
          {id:132,id_textiles:24,serie:'P30 en dhs en TTC',prix:29848},
          {id:133,id_textiles:25,serie:'TB P10 en TTC',prix:20349.33},
          {id:134,id_textiles:25,serie:'TC P11',prix:20592},
          {id:135,id_textiles:25,serie:'TE P15',prix:22845.33},
          {id:136,id_textiles:25,serie:'P20 en Dhs en TTC',prix:0},
          {id:137,id_textiles:25,serie:'P25 en dhs en TTC',prix:0},
          {id:138,id_textiles:25,serie:'P30 en dhs en TTC',prix:0},
          {id:139,id_textiles:26,serie:'TB P10 en TTC',prix:24058.66},
          {id:140,id_textiles:26,serie:'TC P11',prix:25549.33},
          {id:141,id_textiles:26,serie:'TE P15',prix:27421.33},
          {id:142,id_textiles:26,serie:'P20 en Dhs en TTC',prix:28946.66},
          {id:143,id_textiles:26,serie:'P25 en dhs en TTC',prix:30437.33},
          {id:144,id_textiles:26,serie:'P30 en dhs en TTC',prix:32309.33},
          {id:145,id_textiles:27,serie:'TB P10 en TTC',prix:27178.66},
          {id:146,id_textiles:27,serie:'TC P11',prix:27490.66},
          {id:147,id_textiles:27,serie:'TE P15',prix:30922.66},
          {id:148,id_textiles:27,serie:'P20 en Dhs en TTC',prix:0},
          {id:149,id_textiles:27,serie:'P25 en dhs en TTC',prix:0},
          {id:150,id_textiles:27,serie:'P30 en dhs en TTC',prix:0},
          {id:151,id_textiles:28,serie:'TB P10 en TTC',prix:32690.66},
          {id:152,id_textiles:28,serie:'TC P11',prix:34944},
          {id:153,id_textiles:28,serie:'TE P15',prix:37752},
          {id:154,id_textiles:28,serie:'P20 en Dhs en TTC',prix:40005.33},
          {id:155,id_textiles:28,serie:'P25 en dhs en TTC',prix:42258.66},
          {id:156,id_textiles:28,serie:'P30 en dhs en TTC',prix:45066.66},
          {id:157,id_textiles:29,serie:'TB P10 en TTC',prix:1525.33},
          {id:158,id_textiles:29,serie:'TC P11',prix:1560},
          {id:159,id_textiles:29,serie:'TE P15',prix:1802.66},
          {id:160,id_textiles:29,serie:'P20 en Dhs en TTC',prix:0},
          {id:161,id_textiles:29,serie:'P25 en dhs en TTC',prix:0},
          {id:162,id_textiles:29,serie:'P30 en dhs en TTC',prix:0},
          {id:163,id_textiles:30,serie:'TB P10 en TTC',prix:1941.33},
          {id:164,id_textiles:30,serie:'TC P11',prix:2114.66},
          {id:165,id_textiles:30,serie:'TE P15',prix:2322.66},
          {id:166,id_textiles:30,serie:'P20 en Dhs en TTC',prix:2461.33},
          {id:167,id_textiles:30,serie:'P25 en dhs en TTC',prix:2634.66},
          {id:168,id_textiles:30,serie:'P30 en dhs en TTC',prix:2842.66},
      ],
  },




  {
    id:3,
    model:"model3",
    modelImageUrl:'/images/fauteuil.png',
    categorie:[
      {id:1,title:'V020 - Canapé 2pl'},
      {id:2,title:'V029 - canapé 2.5pl'},
      {id:3,title:'V030 - canapé 3pl'},
      {id:4,title:'V063 - canapé 3pl Maxi'},
      {id:5,title:'V0283 - Meridienne gauche'},
      {id:6,title:'V0284 - Meridienne droite'},
    ],
    sousCategorie:[
      {id:1,id_categorie:1,version:'V020A - Supports dossiers amovibles canapé 2pl', imageUrl:'./images/hocker.png'},
      {id:2,id_categorie:1,version:'V020B - Coussins assise canapé 2pl', imageUrl:'./images/fauteuil.png'},
      {id:3,id_categorie:1,version:'V020C - Coussins dossier canapé 2pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:4,id_categorie:1,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:5,id_categorie:1,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:11,id_categorie:2,version:'V029A - Supports dossiers amovibles canapé 2.5pl', imageUrl:'./images/hocker.png'},
      {id:12,id_categorie:2,version:'V029B - Coussins assise canapé 2.5pl', imageUrl:'./images/fauteuil.png'},
      {id:13,id_categorie:2,version:'V29C - Coussins dossier canapé 2.5pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:14,id_categorie:2,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:15,id_categorie:2,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:16,id_categorie:3,version:'V030A - Supports dossiers amovibles canapé 3pl', imageUrl:'./images/hocker.png'},
      {id:17,id_categorie:3,version:'V030B - Coussins assise canapé 3pl', imageUrl:'./images/fauteuil.png'},
      {id:18,id_categorie:3,version:'V030C - Coussins dossier canapé 3pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:19,id_categorie:3,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:20,id_categorie:3,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:21,id_categorie:4,version:'V063A - Supports dossiers amovibles canapé 3pl Maxi',imageUrl:'./images/hocker.png'},
      {id:22,id_categorie:4,version:'V063B - Coussins assise canapé 3pl Maxi', imageUrl:'./images/fauteuil.png'},
      {id:23,id_categorie:4,version:'V63C - Coussins dossier canapé 3pl Maxi', imageUrl:'./images/Canapé-2pl.png'},
      {id:24,id_categorie:4,version:'V694 - Coussin manchette', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:25,id_categorie:4,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:26,id_categorie:5,version:'V283A - Supports dossiers amovibles merid. gauche', imageUrl:'./images/hocker.png'},
      {id:27,id_categorie:5,version:'V283B - Coussins assise meridienne gauche', imageUrl:'./images/fauteuil.png'},
      {id:28,id_categorie:5,version:'V283C - Coussins dossier meridienne gauche', imageUrl:'./images/Canapé-2pl.png'},
      {id:29,id_categorie:5,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
      {id:31,id_categorie:5,version:'V284A - Supports dossiers amovibles merid. droite', imageUrl:'./images/hocker.png'},
      {id:32,id_categorie:5,version:'V284B - Coussins assise meridienne droite', imageUrl:'./images/fauteuil.png'},
      {id:33,id_categorie:5,version:'V284C - Coussins dossier meridienne droite', imageUrl:'./images/Canapé-2pl.png'},
      {id:34,id_categorie:5,version:'Z205 - Têtière', imageUrl:'./images/Canapé-3pl.png'},
    ],
      orientation:[null],
      textiles:[
          {id:1,id_souscategorie:1,type:'Tissu'},
          {id:2,id_souscategorie:1,type:'Cuir'},
          {id:3,id_souscategorie:2,type:'Tissu'},
          {id:4,id_souscategorie:2,type:'Cuir'},
          {id:5,id_souscategorie:3,type:'Tissu'},
          {id:6,id_souscategorie:3,type:'Cuir'},
          {id:7,id_souscategorie:4,type:'Tissu'},
          {id:8,id_souscategorie:4,type:'Cuir'},
          {id:9,id_souscategorie:5,type:'Tissu'},
          {id:10,id_souscategorie:5,type:'Cuir'},
      ],
      prix:[
          {id:1,id_textiles:1,serie:'TB P10',prix:20245},
          {id:2,id_textiles:1,serie:'TC P11',prix:21285},
          {id:3,id_textiles:1,serie:'TE P15',prix:24301},
          {id:4,id_textiles:1,serie:'P25',prix:0},
          {id:5,id_textiles:1,serie:'P30 ',prix:0},
          {id:6,id_textiles:2,serie:'TB P10',prix:24821},
          {id:7,id_textiles:2,serie:'TC P11',prix:26277},
          {id:8,id_textiles:2,serie:'TE P15',prix:29224},
          {id:9,id_textiles:2,serie:'P25',prix:33176},
          {id:10,id_textiles:2,serie:'P30 ',prix:36608},
          {id:11,id_textiles:3,serie:'TB P10',prix:10000},
          {id:12,id_textiles:3,serie:'TC P11',prix:10000},
          {id:13,id_textiles:3,serie:'TE P15',prix:10000},
          {id:14,id_textiles:3,serie:'P25',prix:10000},
          {id:15,id_textiles:3,serie:'P30 ',prix:10000},
          {id:16,id_textiles:4,serie:'TB P10',prix:10000},
          {id:17,id_textiles:4,serie:'TC P11',prix:10000},
          {id:18,id_textiles:4,serie:'TE P15',prix:10000},
          {id:19,id_textiles:4,serie:'P25',prix:10000},
          {id:20,id_textiles:4,serie:'P30 ',prix:10000},
          {id:21,id_textiles:5,serie:'TB P10',prix:10000},
          {id:22,id_textiles:5,serie:'TC P11',prix:10000},
          {id:23,id_textiles:5,serie:'TE P15',prix:10000},
          {id:24,id_textiles:5,serie:'P25',prix:10000},
          {id:25,id_textiles:5,serie:'P30 ',prix:10000},
          {id:26,id_textiles:6,serie:'TB P10',prix:10000},
          {id:27,id_textiles:6,serie:'TC P11',prix:10000},
          {id:28,id_textiles:6,serie:'TE P15',prix:10000},
          {id:29,id_textiles:6,serie:'P25',prix:10000},
          {id:30,id_textiles:6,serie:'P30 ',prix:10000},
          {id:31,id_textiles:7,serie:'TB P10',prix:10000},
          {id:32,id_textiles:7,serie:'TC P11',prix:10000},
          {id:33,id_textiles:7,serie:'TE P15',prix:10000},
          {id:34,id_textiles:7,serie:'P25',prix:10000},
          {id:35,id_textiles:7,serie:'P30 ',prix:10000},
          {id:36,id_textiles:8,serie:'TB P10',prix:10000},
          {id:37,id_textiles:8,serie:'TC P11',prix:10000},
          {id:38,id_textiles:8,serie:'TE P15',prix:10000},
          {id:39,id_textiles:8,serie:'P25',prix:10000},
          {id:40,id_textiles:8,serie:'P30 ',prix:10000},
          {id:41,id_textiles:9,serie:'TB P10',prix:10000},
          {id:42,id_textiles:9,serie:'TC P11',prix:10000},
          {id:43,id_textiles:9,serie:'TE P15',prix:10000},
          {id:44,id_textiles:9,serie:'P25',prix:10000},
          {id:45,id_textiles:9,serie:'P30 ',prix:10000},
          {id:46,id_textiles:10,serie:'TB P10',prix:10000},
          {id:47,id_textiles:10,serie:'TC P11',prix:10000},
          {id:48,id_textiles:10,serie:'TE P15',prix:10000},
          {id:49,id_textiles:10,serie:'P25',prix:10000},
          {id:50,id_textiles:10,serie:'P30 ',prix:10000},
      ]
  },




  {
    id:4,
    model:"model4",
    modelImageUrl:'/images/Petit-bâtard-acc.gauche.png',
    categorie:[null],
    sousCategorie:[
      {id:1,id_categorie:0,version:'V001 - Hocker', imageUrl:'./images/hocker.png'},
      {id:2,id_categorie:0,version:'V010 - Fauteuil', imageUrl:'./images/fauteuil.png'},
      {id:3,id_categorie:0,version:'V020 - Canapé 2pl', imageUrl:'./images/Canapé-2pl.png'},
      {id:4,id_categorie:0,version:'V029 - Canapé 2,5pl', imageUrl:'./images/Canapé-2,5pl.png'},
      {id:5,id_categorie:0,version:'V030 - Canapé 3pl', imageUrl:'./images/Canapé-3pl.png'},
      {id:6,id_categorie:0,version:'V064 - Canapé 4pl', imageUrl:'./images/Canapé-4pl.png'},
      {id:7,id_categorie:0,version:'V185 - Canapé 5pl', imageUrl:'./images/Canapé-5pl.png'},
      {id:8,id_categorie:0,version:'V002 - Petit bâtard acc.gauche', imageUrl:'./images/Petit-bâtard-acc.gauche.png'},
      {id:9,id_categorie:0,version:'V003 - Petit bâtard acc.droite', imageUrl:'./images/Petit-bâtard-acc.droite.png'},
      {id:10,id_categorie:0,version:'V008 - Grand bâtard acc.gauche', imageUrl:'./images/Grand-bâtard-acc.gauche.png'},
      {id:11,id_categorie:0,version:'V009 - Grand bâtard acc.droite', imageUrl:'./images/Grand-bâtard-acc.droite.png'},
      {id:12,id_categorie:0,version:'V115 - Chauffeuse petite', imageUrl:'./images/Chauffeuse-petite.png'},
      {id:13,id_categorie:0,version:'V115 - Chauffeuse grand', imageUrl:'./images/Chauffeuse-grand.png'},
      {id:14,id_categorie:0,version:'V050 - Angle', imageUrl:'./images/Angle.png'},
      {id:15,id_categorie:0,version:'V627 - coussin deco (plumes)', imageUrl:'./images/coussin-deco.png'},
    ],
    orientation:[
      {id:1, L:100, H:73, T:40, id_souscategorie:1},
      {id:2, L:114, H:72, T:106, id_souscategorie:2},
      {id:3, L:189, H:72, T:106, id_souscategorie:3},
      {id:4, L:213, H:72, T:106, id_souscategorie:4},
      {id:5, L:237, H:72, T:106, id_souscategorie:5},
      {id:6, L:261, H:72, T:106, id_souscategorie:6},
      {id:7, L:296, H:72, T:106, id_souscategorie:7},
      {id:8, L:95, H:72, T:106, id_souscategorie:8},
      {id:9, L:95, H:72, T:106, id_souscategorie:9},
      {id:10, L:107, H:72, T:106, id_souscategorie:10},
      {id:11, L:107, H:72, T:106, id_souscategorie:11},
      {id:12, L:72, H:72, T:106, id_souscategorie:12},
      {id:13, L:84, H:72, T:106, id_souscategorie:13},
      {id:14, L:103, H:72, T:106, id_souscategorie:14},
      {id:15, L:0, H:0, T:0, id_souscategorie:15},
  ],
      textiles:[
          {id:1,id_souscategorie:1,type:'Tissu'},
          {id:2,id_souscategorie:1,type:'Cuir'},
          {id:2,id_souscategorie:1,type:'CuirTissyHaHa'},
          {id:3,id_souscategorie:2,type:'Tissu'},
          {id:4,id_souscategorie:2,type:'Cuir'},
          {id:5,id_souscategorie:3,type:'Tissu'},
          {id:6,id_souscategorie:3,type:'Cuir'},
          {id:7,id_souscategorie:4,type:'Tissu'},
          {id:8,id_souscategorie:4,type:'Cuir'},
          {id:9,id_souscategorie:5,type:'Tissu'},
          {id:10,id_souscategorie:5,type:'Cuir'},
          {id:11,id_souscategorie:6,type:'Tissu'},
          {id:12,id_souscategorie:6,type:'Cuir'},
          {id:13,id_souscategorie:7,type:'Tissu'},
          {id:14,id_souscategorie:7,type:'Cuir'},
          {id:15,id_souscategorie:8,type:'Tissu'},
          {id:16,id_souscategorie:8,type:'Cuir'},
          {id:17,id_souscategorie:9,type:'Tissu'},
          {id:18,id_souscategorie:9,type:'Cuir'},
          {id:19,id_souscategorie:10,type:'Tissu'},
          {id:20,id_souscategorie:10,type:'Cuir'},
          {id:21,id_souscategorie:11,type:'Tissu'},
          {id:22,id_souscategorie:11,type:'Cuir'},
          {id:23,id_souscategorie:12,type:'Tissu'},
          {id:24,id_souscategorie:12,type:'Cuir'},
      ],
      prix:[
          {id:1,id_textiles:1,serie:'TB P10 en TTC',prix:10764},
          {id:2,id_textiles:1,serie:'TC P11',prix:10857.6},
          {id:3,id_textiles:1,serie:'TE P15',prix:11793.6},
          {id:4,id_textiles:1,serie:'P20 en Dhs en TTC',prix:0},
          {id:5,id_textiles:1,serie:'P25 en dhs en TTC',prix:0},
          {id:6,id_textiles:1,serie:'P30 en dhs en TTC',prix:0},
          {id:7,id_textiles:2,serie:'TB P10 en TTC',prix:12292.8},
          {id:8,id_textiles:2,serie:'TC P11',prix:12885.6},
          {id:9,id_textiles:2,serie:'TE P15',prix:13665.6},
          {id:10,id_textiles:2,serie:'P20 en Dhs en TTC',prix:14289.6},
          {id:11,id_textiles:2,serie:'P25 en dhs en TTC',prix:14913.6},
          {id:12,id_textiles:2,serie:'P30 en dhs en TTC',prix:15693.6},
          {id:13,id_textiles:3,serie:'TB P10 en TTC',prix:27005.33},
          {id:14,id_textiles:3,serie:'TC P11',prix:27421.33},
          {id:15,id_textiles:3,serie:'TE P15',prix:31338.66},
          {id:16,id_textiles:3,serie:'P20 en Dhs en TTC',prix:0},
          {id:17,id_textiles:3,serie:'P25 en dhs en TTC',prix:0},
          {id:18,id_textiles:3,serie:'P30 en dhs en TTC',prix:0},
          {id:19,id_textiles:4,serie:'TB P10 en TTC',prix:33349.33},
          {id:20,id_textiles:4,serie:'TC P11',prix:35949.33},
          {id:21,id_textiles:4,serie:'TE P15',prix:39173.33},
          {id:22,id_textiles:4,serie:'P20 en Dhs en TTC',prix:41738.66},
          {id:23,id_textiles:4,serie:'P25 en dhs en TTC',prix:44338.66},
          {id:24,id_textiles:4,serie:'P30 en dhs en TTC',prix:47562.66},
          {id:25,id_textiles:5,serie:'TB P10 en TTC',prix:41669.33},
          {id:26,id_textiles:5,serie:'TC P11',prix:42224},
          {id:27,id_textiles:5,serie:'TE P15',prix:47701.33},
          {id:28,id_textiles:5,serie:'P20 en Dhs en TTC',prix:0},
          {id:29,id_textiles:5,serie:'P25 en dhs en TTC',prix:0},
          {id:30,id_textiles:5,serie:'P30 en dhs en TTC',prix:0},
          {id:31,id_textiles:6,serie:'TB P10 en TTC',prix:50578.66},
          {id:32,id_textiles:6,serie:'TC P11',prix:54184},
          {id:33,id_textiles:6,serie:'TE P15',prix:58725.33},
          {id:34,id_textiles:6,serie:'P20 en Dhs en TTC',prix:62365.33},
          {id:35,id_textiles:6,serie:'P25 en dhs en TTC',prix:65970.66},
          {id:36,id_textiles:6,serie:'P30 en dhs en TTC',prix:70512},
          {id:37,id_textiles:7,serie:'TB P10 en TTC',prix:48013.33},
          {id:38,id_textiles:7,serie:'TC P11',prix:48637.33},
          {id:39,id_textiles:7,serie:'TE P15',prix:54877.33},
          {id:40,id_textiles:7,serie:'P20 en Dhs en TTC',prix:0},
          {id:41,id_textiles:7,serie:'P25 en dhs en TTC',prix:0},
          {id:42,id_textiles:7,serie:'P30 en dhs en TTC',prix:0},
          {id:43,id_textiles:8,serie:'TB P10 en TTC',prix:58136},
          {id:44,id_textiles:8,serie:'TC P11',prix:62296},
          {id:45,id_textiles:8,serie:'TE P15',prix:67461.33},
          {id:46,id_textiles:8,serie:'P20 en Dhs en TTC',prix:71586.66},
          {id:47,id_textiles:8,serie:'P25 en dhs en TTC',prix:75712},
          {id:48,id_textiles:8,serie:'P30 en dhs en TTC',prix:80912},
          {id:49,id_textiles:9,serie:'TB P10 en TTC',prix:54149.33},
          {id:50,id_textiles:9,serie:'TC P11',prix:54877.33},

          {id:54,id_textiles:9,serie:'P30 en dhs en TTC',prix:0},
          {id:55,id_textiles:10,serie:'TB P10 en TTC',prix:65554.66},
          {id:56,id_textiles:10,serie:'TC P11',prix:70200},
          {id:57,id_textiles:10,serie:'TE P15',prix:76024},
          {id:58,id_textiles:10,serie:'P20 en Dhs en TTC',prix:80669.33},
          {id:59,id_textiles:10,serie:'P25 en dhs en TTC',prix:85314.66},
          {id:60,id_textiles:10,serie:'P30 en dhs en TTC',prix:91104},
          {id:49,id_textiles:11,serie:'TB P10 en TTC',prix:64618.66},
          {id:50,id_textiles:11,serie:'TC P11',prix:65381.33},
          {id:51,id_textiles:11,serie:'TE P15',prix:73181.33},
          {id:52,id_textiles:11,serie:'P20 en Dhs en TTC',prix:0},
          {id:53,id_textiles:11,serie:'P25 en dhs en TTC',prix:0},
          {id:54,id_textiles:11,serie:'P30 en dhs en TTC',prix:0},
          {id:55,id_textiles:12,serie:'TB P10 en TTC',prix:77272},
          {id:56,id_textiles:12,serie:'TC P11',prix:82402.66},
          {id:57,id_textiles:12,serie:'TE P15',prix:88850.66},
          {id:58,id_textiles:12,serie:'P20 en Dhs en TTC',prix:94016},
          {id:59,id_textiles:12,serie:'P25 en dhs en TTC',prix:99181.33},
          {id:60,id_textiles:12,serie:'P30 en dhs en TTC',prix:105629.33},
          {id:61,id_textiles:13,serie:'TB P10 en TTC',prix:73493.33},
          {id:62,id_textiles:13,serie:'TC P11',prix:74325.33},
          {id:63,id_textiles:13,serie:'TE P15',prix:82922.66},
          {id:64,id_textiles:13,serie:'P20 en Dhs en TTC',prix:0},
          {id:65,id_textiles:13,serie:'P25 en dhs en TTC',prix:0},
          {id:66,id_textiles:13,serie:'P30 en dhs en TTC',prix:0},
          {id:67,id_textiles:14,serie:'TB P10 en TTC',prix:87394.66},
          {id:68,id_textiles:14,serie:'TC P11',prix:93080},
          {id:69,id_textiles:14,serie:'TE P15',prix:100186.66},
          {id:70,id_textiles:14,serie:'P20 en Dhs en TTC',prix:105872},
          {id:71,id_textiles:14,serie:'P25 en dhs en TTC',prix:111522.66},
          {id:72,id_textiles:14,serie:'P30 en dhs en TTC',prix:83962.66},
          {id:73,id_textiles:15,serie:'TB P10 en TTC',prix:22013.33},
          {id:74,id_textiles:15,serie:'TC P11',prix:22325.33},
          {id:75,id_textiles:15,serie:'TE P15',prix:25237.33},
          {id:76,id_textiles:15,serie:'P20 en Dhs en TTC',prix:0},
          {id:77,id_textiles:15,serie:'P25 en dhs en TTC',prix:0},
          {id:78,id_textiles:15,serie:'P30 en dhs en TTC',prix:0},
          {id:79,id_textiles:16,serie:'TB P10 en TTC',prix:26762.66},
          {id:80,id_textiles:16,serie:'TC P11',prix:28704},
          {id:81,id_textiles:16,serie:'TE P15',prix:31096},
          {id:82,id_textiles:16,serie:'P20 en Dhs en TTC',prix:33037.33},
          {id:83,id_textiles:16,serie:'P25 en dhs en TTC',prix:34978.66},
          {id:84,id_textiles:16,serie:'P30 en dhs en TTC',prix:37370.66},
          {id:85,id_textiles:17,serie:'TB P10 en TTC',prix:22013.33},
          {id:86,id_textiles:17,serie:'TC P11',prix:22325.33},
          {id:87,id_textiles:17,serie:'TE P15',prix:25237.33},
          {id:88,id_textiles:17,serie:'P20 en Dhs en TTC',prix:0},
          {id:89,id_textiles:17,serie:'P25 en dhs en TTC',prix:0},
          {id:90,id_textiles:17,serie:'P30 en dhs en TTC',prix:0},
          {id:91,id_textiles:18,serie:'TB P10 en TTC',prix:26762.66},
          {id:92,id_textiles:18,serie:'TC P11',prix:28704},
          {id:93,id_textiles:18,serie:'TE P15',prix:31096},
          {id:94,id_textiles:18,serie:'P20 en Dhs en TTC',prix:33037.33},
          {id:95,id_textiles:18,serie:'P25 en dhs en TTC',prix:34978.66},
          {id:96,id_textiles:18,serie:'P30 en dhs en TTC',prix:37370.66},
          {id:97,id_textiles:19,serie:'TB P10 en TTC',prix:24648},
          {id:98,id_textiles:19,serie:'TC P11',prix:24960},
          {id:99,id_textiles:19,serie:'TE P15',prix:28357.33},
          {id:100,id_textiles:19,serie:'P20 en Dhs en TTC',prix:0},
          {id:101,id_textiles:19,serie:'P25 en dhs en TTC',prix:0},
          {id:102,id_textiles:19,serie:'P30 en dhs en TTC',prix:0},
          {id:103,id_textiles:20,serie:'TB P10 en TTC',prix:30090.66},
          {id:104,id_textiles:20,serie:'TC P11',prix:32344},
          {id:105,id_textiles:20,serie:'TE P15',prix:35117.33},
          {id:106,id_textiles:20,serie:'P20 en Dhs en TTC',prix:37336},
          {id:107,id_textiles:20,serie:'P25 en dhs en TTC',prix:39554.66},
          {id:108,id_textiles:20,serie:'P30 en dhs en TTC',prix:42362.66},
          {id:109,id_textiles:21,serie:'TB P10 en TTC',prix:24648},
          {id:110,id_textiles:21,serie:'TC P11',prix:24960},
          {id:111,id_textiles:21,serie:'TE P15',prix:28357.33},
          {id:112,id_textiles:21,serie:'P20 en Dhs en TTC',prix:0},
          {id:113,id_textiles:21,serie:'P25 en dhs en TTC',prix:0},
          {id:114,id_textiles:21,serie:'P30 en dhs en TTC',prix:0},
          {id:115,id_textiles:22,serie:'TB P10 en TTC',prix:30090.66},
          {id:116,id_textiles:22,serie:'TC P11',prix:32344},
          {id:117,id_textiles:22,serie:'TE P15',prix:35117.33},
          {id:118,id_textiles:22,serie:'P20 en Dhs en TTC',prix:37336},
          {id:119,id_textiles:22,serie:'P25 en dhs en TTC',prix:39554.66},
          {id:120,id_textiles:22,serie:'P30 en dhs en TTC',prix:42362.66},
          {id:121,id_textiles:23,serie:'TB P10 en TTC',prix:19205.33},
          {id:122,id_textiles:23,serie:'TC P11',prix:19378.66},
          {id:123,id_textiles:23,serie:'TE P15',prix:21424},
          {id:124,id_textiles:23,serie:'P20 en Dhs en TTC',prix:0},
          {id:125,id_textiles:23,serie:'P25 en dhs en TTC',prix:0},
          {id:126,id_textiles:23,serie:'P30 en dhs en TTC',prix:0},
          {id:127,id_textiles:24,serie:'TB P10 en TTC',prix:22464},
          {id:128,id_textiles:24,serie:'TC P11',prix:23816},
          {id:129,id_textiles:24,serie:'TE P15',prix:25480},
          {id:130,id_textiles:24,serie:'P20 en Dhs en TTC',prix:26832},
          {id:131,id_textiles:24,serie:'P25 en dhs en TTC',prix:28149.33},
          {id:132,id_textiles:24,serie:'P30 en dhs en TTC',prix:29848},
          {id:133,id_textiles:25,serie:'TB P10 en TTC',prix:20349.33},
          {id:134,id_textiles:25,serie:'TC P11',prix:20592},
          {id:135,id_textiles:25,serie:'TE P15',prix:22845.33},
          {id:136,id_textiles:25,serie:'P20 en Dhs en TTC',prix:0},
          {id:137,id_textiles:25,serie:'P25 en dhs en TTC',prix:0},
          {id:138,id_textiles:25,serie:'P30 en dhs en TTC',prix:0},
          {id:139,id_textiles:26,serie:'TB P10 en TTC',prix:24058.66},
          {id:140,id_textiles:26,serie:'TC P11',prix:25549.33},
          {id:141,id_textiles:26,serie:'TE P15',prix:27421.33},
          {id:142,id_textiles:26,serie:'P20 en Dhs en TTC',prix:28946.66},
          {id:143,id_textiles:26,serie:'P25 en dhs en TTC',prix:30437.33},
          {id:144,id_textiles:26,serie:'P30 en dhs en TTC',prix:32309.33},
          {id:145,id_textiles:27,serie:'TB P10 en TTC',prix:27178.66},
          {id:146,id_textiles:27,serie:'TC P11',prix:27490.66},
          {id:147,id_textiles:27,serie:'TE P15',prix:30922.66},
          {id:148,id_textiles:27,serie:'P20 en Dhs en TTC',prix:0},
          {id:149,id_textiles:27,serie:'P25 en dhs en TTC',prix:0},
          {id:150,id_textiles:27,serie:'P30 en dhs en TTC',prix:0},
          {id:151,id_textiles:28,serie:'TB P10 en TTC',prix:32690.66},
          {id:152,id_textiles:28,serie:'TC P11',prix:34944},
          {id:153,id_textiles:28,serie:'TE P15',prix:37752},
          {id:154,id_textiles:28,serie:'P20 en Dhs en TTC',prix:40005.33},
          {id:155,id_textiles:28,serie:'P25 en dhs en TTC',prix:42258.66},
          {id:156,id_textiles:28,serie:'P30 en dhs en TTC',prix:45066.66},
          {id:157,id_textiles:29,serie:'TB P10 en TTC',prix:1525.33},
          {id:158,id_textiles:29,serie:'TC P11',prix:1560},
          {id:159,id_textiles:29,serie:'TE P15',prix:1802.66},
          {id:160,id_textiles:29,serie:'P20 en Dhs en TTC',prix:0},
          {id:161,id_textiles:29,serie:'P25 en dhs en TTC',prix:0},
          {id:162,id_textiles:29,serie:'P30 en dhs en TTC',prix:0},
          {id:163,id_textiles:30,serie:'TB P10 en TTC',prix:1941.33},
          {id:164,id_textiles:30,serie:'TC P11',prix:2114.66},
          {id:165,id_textiles:30,serie:'TE P15',prix:2322.66},
          {id:166,id_textiles:30,serie:'P20 en Dhs en TTC',prix:2461.33},
          {id:167,id_textiles:30,serie:'P25 en dhs en TTC',prix:2634.66},
          {id:168,id_textiles:30,serie:'P30 en dhs en TTC',prix:2842.66},
      ],
  },
]
export default models;
import React, { useState } from 'react';
import DropdownMenu from './dropDown';
import Swal from 'sweetalert2';
import models from './data';
import { Link, redirect } from 'react-router-dom';
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faCheck, faTrash, faXmark, faCircleInfo, faStar} from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { useEffect } from 'react';
import formatNumberWithTwoDecimals from './numberFormat';
import axios from 'axios';
import data2 from './data2';
import { Breadcrumbs } from '@material-tailwind/react';
import { v4 as uuidv4 } from 'uuid';
const DynamicSelects = (props) => { //selectedMaterial
  const [selectedVersion, setSelectedVersion] = useState('');
  const [selectedVersionText, setSelectedVersionText] = useState(''); {/* text category */}
  const [selectedSubOption, setSelectedSubOption] = useState('');
  const [currentSubcategoryId, setCurrentSubcategoryId] = useState('')
  const [currentMaterialId, setCurrentMaterialId] = useState('');
  const [selectedSubOptionText, setSelectedSubOptionText] = useState(''); {/* text sous-category */}
  const [selectedAdditionalOption, setSelectedAdditionalOption] = useState('');
  const [selectedAdditionalOptionId, setSelectedAdditionalOptionId] = useState();
  const [currentAdditionalOptionId, setCurrentAdditionalOptionId] = useState();
  const [selectedColor, setSelectedColor] = useState('');
  const [price, setPrice] = useState(0);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceTTC, setTotalPriceTTC] = useState(0);
  const [objects, setObjects] = useState([])
  const [countObjects, setCountObjects] = useState(1);
  const [isPopupVisible, setPopupVisibility] = useState(false)
  const [isActivePopupSection, setActivePopupSection] = useState('');
  const [isActivePopupSectionCat, setActivePopupSectionCat] = useState('');
  const [disabledOptions, setDisabledOptions] = useState([]);
  const [isCategorieDisabled, setCategorieDisabled] = useState(false);
  const [isModifyPopupVisible, setModifyPopupVisible] = useState(false);
  const [modifiedObject, setModifiedObject] = useState({});
  const [modifiedSubOption, setModifiedSubOption] = useState('');
  const [modifiedMaterial, setModifiedMaterial] = useState('');
  const [modifiedMaterialText, setModifiedMaterialText] = useState('')
  const [modifiedAdditionalOption, setModifiedAdditionalOption] = useState('');
  const [modifiedColor, setModifiedColor] = useState('');
  const [modifiedPrice, setModifiedPrice] = useState(0);
  const [isAjouterVisible, setAjouterVisible] = useState(false)
  const [isVersionDefault, setVersionDefault] = useState(true);
  const [isSuboptionsVisible, setVisibleSuboptions] = useState(false);
  const [pdfReference, setPdfReference] = useState(1);
  const [selectedSubOptionImage, setSelectedSubOptionImage] = useState('');
  const [selectedSubOptionImage64, setSelectedSubOptionImage64] = useState();
  const [categorieActive, setCategorieActive] = useState();
  const [categoriess, setCategoriess] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [nothingFound, setNothingFound] = useState(false);
  const [orientationText, setOrientationText] = useState('');
  const [colorValue, setColorValue] = useState('');
  const [isInfosHovered, setInfosHovered] = useState(false);
  const [pdfType, setPdfType] = useState('');
  const [nom, setNom] = useState("");
  const [cin, setCin] = useState("");
  const [adresse, setAdresse] = useState("");
  const [telephone, setTelephone] = useState("");
  const [eemail, setEmail] = useState("");
  const [serialNumber, setSerialNumber] = useState('');
  const [modifiedAdditionalOptionId, setModifiedAdditionalOptionId] = useState();
  const [colorsArray, setColorsArray] = useState([])
  const [quantity, setQuantity] = useState(1);
  const [fabricTypeId, setFabricTypeId] = useState();
  const [situation, setSituation] = useState('');
  const [fabricc, setFabricc] = useState()
  const [fabriccTypes ,setFabriccTypes] = useState([])
  const [discount, setDiscount] = useState(0)
  const urlParams = new URLSearchParams(window.location.search);
const customerId = urlParams.get('customerId');
const firstName = urlParams.get('first_name');
const lastName = urlParams.get('last_name');
const email = urlParams.get('email');
const phone = urlParams.get('phone');
const address = urlParams.get('email');
const serial = urlParams.get('serialNumber');



const redirectToHome = () =>{
  window.location.href = `/`
}
const redirectToWelcome = () =>{
  window.location.href = `/welcome-back?customerId=${customerId}`
}


const redirectToVisselle = () =>{
  window.location.href = `/visselleD?customerId=${customerId}&serialNumber=${serial}&first_name=${firstName}&last_name=${lastName}&email=${address}&phone=${telephone}`
}
/*
    const handleSubmit = async () => {
        console.log('Submitting form...');
        try {
            const response = await fetch('http://localhost:5000/api/customers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id:5,
                    first_name: nom,
                    last_name: cin,
                    email: email,
                    phone: telephone,
                    address: adresse,
                    city:"rabat",
                    country:"maroc"
                })
            });
    
            console.log('Response:', response);
    
            if (response.ok) {
                const data = await response.json();
                const queryParams = new URLSearchParams({
                    id: data.id, // Assuming the response contains the generated ID
                    first_name: nom,
                    last_name: cin,
                    email: email,
                    phone: telephone,
                    address: adresse
                    // Add other data as needed
                });
                // reset fields here
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'User information saved successfully!'
                });
                setTimeout(window.location.href = `/new-form?${queryParams.toString()}`, 9000);
            } else {
                const errorData = await response.json();
                console.error('Error:', errorData);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: errorData.error || 'An error occurred while saving user information'
                });
            }
        } catch (error) {
            console.error('Network Error:', error);
            Swal.fire({
                icon: 'error',
                title: 'Network Error',
                text: 'Failed to communicate with the server. Please try again later.'
            });
        }
    };
*/

const handleDiscountChange = (e) => {
  const newDiscount = parseInt(e.target.value) || 0; // Get the discount value from input
  setDiscount(newDiscount); // Update the discount state
  const discountAmount = (originalPrice * newDiscount) / 100; // Calculate discount amount based on percentage
  const newPrice = originalPrice - discountAmount; // Calculate new price based on discount amount
  setPrice(newPrice); // Update the price state
};



const updateSerialNumber = async (subCategoryId, quantity) => {
  try {
    const updateResponse = await fetch('http://localhost:5000/api/sub-category-items/update-serial-number', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        serial_number: "emiliaSerialNumber",
        sub_categoy_item_quantities: [{
          sub_category_item_id: subCategoryId,
          quantity: quantity
        }]
      })
    });

    if (updateResponse.ok) {
      console.log('Sub-category item updated successfully!');
    } else {
      const errorData = await updateResponse.json();
      console.error('Failed to update sub-category item:', errorData);
    }
  } catch (error) {
    console.error('Network Error:', error);
    Swal.fire({
      icon: 'error',
      title: 'Network Error',
      text: 'Failed to communicate with the server. Please try again later.'
    });
  }
};


// Example usage:
// const objectsArray = [
//   { version: 1, 'sou-categorie': 2, prix: 100, quantity: 5 },
//   { version: 2, 'sou-categorie': 3, prix: 200, quantity: 10 }
// ];
// Generate(objectsArray);

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<< ADDED STUFF >>>>>>>>>>>>>>>>>>>>>>>>>>>>


const handleIncrease = () => {
  const newQuantity = quantity + 1;
  setQuantity(newQuantity);
  setPrice(originalPrice * newQuantity);
};

const handleDecrease = () => {
  const newQuantity = Math.max(quantity - 1, 1);
  setQuantity(newQuantity);
  setPrice(originalPrice * newQuantity);
};
const generateBoneDeLivraison = () =>{
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    
  
  setPdfReference(pdfReference + 1)
    const currentPage = 1;
  
    const docDefinition = { 
      pageMargins: [40, 160, 40, 160],
        header:[
        {
          height:500,
          columns:[
            {image: 'logo', width:200, fit:[200, 100], alignment:'left', margin:[40, 0, 0, 0] },
            {text:'Bon De Livraison', fontSize:26, color:'#7b6248', bold:true,margin:[0, 70, 40, 0], alignment:'right'},
        ]},
        { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248'}]},
      ],
      footer:[
        { canvas: [{ type: 'line', x1: 40, y1: 60, x2: 552, y2: 60, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style:'footer11'},
        { text: 'IF : 60118501 - RIB : 000000000', style:'footer1'},
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style:'footer1'},
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style:'footer1'},
        { text: 'Email : cornerliving2023@gmail.com', style:'footer1'},
        { text: 'Tel : 00212661093929', style:'footer1'},
        {text: currentPage, style:'footer1' }
    ],
      content: [
        {columns:[
          { text: 'Client: '+nom, style:'client'},
          { text: 'BL N°:', style:'devis'},
          { text: pdfReference, style:'x'},
          //{ text: type+' N°:', style:'devis'},
        ]},
        {columns:[
          { text: 'Adresse Client: '+adresse, style:'adressClient',},
          { text: "Date:", style:'dateE'},
          { text: '4/30/2022', style:'date'},
        ]},
        {columns:[
          { text: 'Telephone: '+telephone, style:'adressClient',},
          { text: ""},
          { text: ''}, //<<<<<<<<<<<<<<<<<<<< Ce devis est valable pendant 6 mois. >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        ]},
        {columns:[
          {text:'', style:'vidText'},
          //{text:type, style:'devis'}
        ]},
        {
          layout: 'lightHorizontalLines',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
               { text: 'Gamme', style: 'tableHeader'},
               { text: 'Détails', style: 'tableHeader'},
               { text: 'Quantité Commandée', style: 'tableHeader' },
              ],
              ...objects.map(item => [
              {text: item['modelName'], fillColor: 'f3f3f3'},
              {
                text: '-' + item['sou-categorie-text'] + '. \n -' + item['version-text'] + '. \n -' + item['version-material'] + '. \n -' + item['couleur'] + '. \n -H: ' +item['height']+' L: '+item['width']+' P: '+item['depth']
                , fillColor:'f3f3f3', fontSize:8, alignment:'start', bold:true
              },
              {text: item['quantity'], fillColor:'f3f3f3'},
            ]),
            ],
            border: [],
          }, style: 'tableStyle'
        },
        {
          layout: 'noBorders',
          table:{
          widths: [100, 100],
          body: [
            [
            { text: '', style: 'tableHeader1'},
            { text:'', style: 'value',},
            ],
            [
              { text: '', style: 'tableHeader1' },
              { text: '', style: 'value' },
            ],
            [
              { text: '', style: 'tableHeaderTTC' },
              { text: '', style: 'valueTTC' },
            ]
            ],
      }, style:'', margin: [307, 15, null, null], 
  
      },
        { text: 'Signature', alignment: 'right', marginTop:20, bold:true, marginRight:50},
      ],
      
      styles: {
        foot:{
          alignment:'center'
        },
        facture:{
          fontSize:30,
          color:'#7b6248',
          bold:true,
          margin:[40, 40, 0, 0]
        },
        tableStyle:{
          alignment: 'center',
          marginTop:50,
          color: '#7b6248',
        },
        adressText:{
          color: '#7b6248',
        },
        factureA:{
          marginTop: 15,
          bold: true,
          color: '#7b6248',
        },
        client:{
          color: '#7b6248',
          alignment: 'left'
        },
        devis:{
          alignment: 'center',
          color: '#7b6248', 
          margin:[110, 0, 0, 0],
          bold:true,
          fontSize:12
        },
        x:{
          alignment: 'right',
          marginRight:9,
          color: '#7b6248',
          bold:true
        },
  
        adressClient:{
          marginTop:5,
          color: '#7b6248',
        },
  
        dateE:{
          alignment: 'start',
          color: '#7b6248', 
          margin:[116, 2, 0, 0],
          bold:true,
        },
        date:{
          alignment: 'start',
          color: '#7b6248',
          bold:true,
          margin:[107, 2, 0, 4],
        },
        ref:{
          alignment: 'start',
          color: '#7b6248', 
          margin:[118, 8, 0, 0],
          bold:true,
        },
        refN:{
          alignment: 'right',
          color: '#7b6248',
          bold:true,
          margin:[135, 8, 9, 4],
        },
        tableHeader: {
          bold: true,
          fillColor: '#f3f3f3',
          color: '#7b6248',
          alignment: 'center'
        },
        tableHeaderTTC:{
          fillColor: '#7b6248',
          color: 'white'
        },
        valueTTC:{
          fillColor: '#7b6248',
          color: 'white'
        },
  
        table2:{
        },
        tableHeader1:{
          fillColor:'#f3f3f3',
        },
        value:{
          fillColor:'#f3f3f3',
        },
        footer1:{
          fontSize:10,
          bold:true,
          alignment:'center',
        },
        warning:{
          fontSize:14,
          bold:true,
          alignment:'center',
          color:'red'
        },
        footer11:{
          margin:[0, 5, 0, 0],
          fontSize:10,
          bold:true,
          alignment:'center',
        },
        /*footer:{
          fontSize:10,
          bold:true,
          alignment:'center'
        },*/
        total: {
          alignment: 'right',
          margin:[0, 15, 0, 0],
  
        },
        totalTitle: {
          margin:[0, 15, 0, 0],
          bold: true,
          alignment: 'right', 
        },
        HT:{
          margin:[0, 30, 0, 0],
  
        },
        totalPriceBackground: {
          fillColor: '#D9D6DC',
        },
        
      },
    };
  
    const logoPath = './images/logo1.png'; 
    const sofaImagePath = './images/sofa2.png';
  
    convertImageToBase64(logoPath, base64Logo => {
        convertImageToBase64(sofaImagePath, base64SofaImage => {
            docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
            const pdfGenerator = pdfMake.createPdf(docDefinition);
            pdfGenerator.getBlob(blob => {
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
            });
        });
    });
  ;
    
  };


  
  
  const generatePdf = (type) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const chunkSize = 6;
    const chunks = [];
    const totalPages = Math.ceil(objects.length / chunkSize);
  
    for (let i = 0; i < objects.length; i += chunkSize) {
      chunks.push(objects.slice(i, i + chunkSize));
    }
  
    setPdfReference(pdfReference + 1);
    const currentPage = 1;
  
    const docDefinition = {
      pageMargins: [40, 160, 40, 160],
      header: [
        {
          height: 500,
          columns: [
            { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
            { text: type, fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
          ]
        },
        { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248' }] },
      ],
      footer: function(currentPage, pageCount) {
        return [
          { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
          { text: 'ICE : 003378241000085', style: 'footer11' },
          { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
          { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
          { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
          { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
          { text: 'Tel : 00212661093929', style: 'footer1' },
          { text: 'Ce ' + type + ' est valable pendant 6 mois.', style: 'warning' },
          { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
        ];
      },
      
      content: [
        {
          columns: [
            { text: 'Client: ' + nom, style: 'client' },
            { text: ' ' + type + ' N°:', style: 'devis' },
            { text: pdfReference, style: 'x' },
          ]
        },
        {
          columns: [
            { text: 'Adresse Client: ' + adresse, style: 'adressClient' },
            { text: "Date:", style: 'dateE' },
            { text: '4/30/2022', style: 'date' },
          ]
        },
        {
          columns: [
            { text: 'Telephone: ' + telephone, style: 'adressClient' },
            { text: "" },
            { text: '' },
          ]
        },
        {
          columns: [
            { text: '', style: 'vidText' },
          ]
        }
      ],
      styles: {
        foot: { alignment: 'center' },
        facture: { fontSize: 30, color: '#7b6248', bold: true, margin: [40, 40, 0, 0] },
        tableStyle: { alignment: 'center', marginTop: 70, color: '#7b6248' },
        adressText: { color: '#7b6248' },
        factureA: { marginTop: 15, bold: true, color: '#7b6248' },
        client: { color: '#7b6248', alignment: 'left' },
        devis: { alignment: 'center', color: '#7b6248', margin: [110, 0, 0, 0], bold: true, fontSize: 12 },
        x: { alignment: 'right', marginRight: 9, color: '#7b6248', bold: true },
        adressClient: { marginTop: 5, color: '#7b6248' },
        dateE: { alignment: 'start', color: '#7b6248', margin: [116, 2, 0, 0], bold: true },
        date: { alignment: 'start', color: '#7b6248', bold: true, margin: [107, 2, 0, 4] },
        ref: { alignment: 'start', color: '#7b6248', margin: [118, 8, 0, 0], bold: true },
        refN: { alignment: 'right', color: '#7b6248', bold: true, margin: [135, 8, 9, 4] },
        tableHeader: { bold: true, fillColor: 'white', color: '#7b6248', alignment: 'center' },
        tableHeaderTTC: { fillColor: '#7b6248', color: 'white' },
        valueTTC: { fillColor: '#7b6248', color: 'white' },
        table2: {},
        tableHeader1: { fillColor: '#f3f3f3' },
        value: { fillColor: '#f3f3f3' },
        footer1: { fontSize: 10, bold: true, alignment: 'center' },
        warning: { fontSize: 14, bold: true, alignment: 'center', color: 'red' },
        footer11: { margin: [0, 5, 0, 0], fontSize: 10, bold: true, alignment: 'center' },
        total: { alignment: 'right', margin: [0, 15, 0, 0] },
        totalTitle: { margin: [0, 15, 0, 0], bold: true, alignment: 'right' },
        HT: { margin: [0, 30, 0, 0] },
        totalPriceBackground: { fillColor: '#D9D6DC' },
      },
    };
  
    // Append tables for each chunk
    chunks.forEach(chunk => {
      docDefinition.content.push({
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              // { text: 'Image', style: 'tableHeader' },
              { text: 'Gamme', style: 'tableHeader' },
              { text: 'Détails', style: 'tableHeader' },
              { text: 'Quantite', style: 'tableHeader' },
              { text: 'Prix (Dhs)', style: 'tableHeader' },
            ],
            ...chunk.map(item => [
              // { image: item.image64, fillColor: 'f3f3f3', width: 40, height: 40 },
              { text: item['modelName'], fillColor: '#f3f3f3' },
              {
                text: '-' + item['sou-categorie-text'] + '. \n -' + item['version-text'] + '. \n -' + item['version-material'] + '. \n -' + item['couleur'] + '. \n -H: ' + item['height'] + ' L: ' + item['width'] + ' P: ' + item['depth'],
                fillColor: '#f3f3f3',
                fontSize: 8,
                bold: true
              },
              { text: item['quantity'], fillColor: '#f3f3f3' },
              { text: item['prix'], fillColor: '#f3f3f3' },
            ]),
          ],
          border: [],
        },
        style: 'tableStyle'
      });
    });
  
    docDefinition.content.push({
      layout: 'noBorders',
      table: {
        widths: [100, 100],
        body: [
          [
            { text: 'TOTAL HT', style: 'tableHeader1' },
            { text: formatNumberWithTwoDecimals(totalPrice), style: 'value' },
          ],
          [
            { text: 'TVA', style: 'tableHeader1' },
            { text: '20%', style: 'value' },
          ],
          [
            { text: 'TOTAL TTC', style: 'tableHeaderTTC' },
            { text: formatNumberWithTwoDecimals(totalPriceTTC), style: 'valueTTC' },
          ]
        ]
      },
      style: 'table2',
      margin: [307, 15, null, null]
    });
  
    docDefinition.content.push({ text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 });
  
    const logoPath = './images/logo1.png';
    const sofaImagePath = './images/sofa2.png';
  
    convertImageToBase64(logoPath, base64Logo => {
      convertImageToBase64(sofaImagePath, base64SofaImage => {
        docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
        const pdfGenerator = pdfMake.createPdf(docDefinition);
        pdfGenerator.getBlob(blob => {
          const url = URL.createObjectURL(blob);
          window.open(url, '_blank');
        });
      });
    });
  };
  
const convertImageToBase64 = (url, callback) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function() {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
  };
  img.src = url;
};

const convertImageUrlToBase64 = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const base64Image = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return base64Image;
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return null;
  }
};
const fetchData = async () => {
  const selectedModel = models.find(model => model.model === 'I843 - SURF');
  if (selectedModel) {
    const selectedSubCat = selectedModel.sousCategorie.find(sousCat => sousCat.id === props.selectedSubOption);
    if (selectedSubCat) {
      setSelectedSubOptionImage(selectedSubCat.imageUrl);
      try {
        const base64Image = await convertImageUrlToBase64(selectedSubCat.imageUrl);
        setSelectedSubOptionImage64(base64Image);
        console.log(selectedSubOptionImage64);
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  }
};








useEffect(() => {            // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< GETING DATA FROM LOCAL STORAGE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  const storedNom = localStorage.getItem("nom");
  const storedCin = localStorage.getItem("cin");
  const storedAdresse = localStorage.getItem("adresse");
  const storedTelephone = localStorage.getItem("telephone");
  const storedEmail = localStorage.getItem("email");

  if (storedNom) setNom(storedNom);
  if (storedCin) setCin(storedCin);
  if (storedAdresse) setAdresse(storedAdresse);
  if (storedTelephone) setTelephone(storedTelephone);
  if (storedEmail) setEmail(storedEmail);
}, []);




useEffect(() => {
  fetchData();
}, [props.selectedSubOption]);


const handleHoverOn = () =>{
  setInfosHovered(true)
}
const handleHoverOff = () =>{
  setInfosHovered(false)
}
const suggestionSelected = (item) => {
  let color = `${item.color}`;
  let id = `${item.id}`
  let price = `${item.price}`
  setOriginalPrice(item.price)
    setSelectedColor(color);
    setPrice(price*quantity);
    setFabricTypeId(id)
  //if (detail.fabric_type.color === ) {
    
  //}
  //setSelectedAdditionalOptionId(additionalOptionId)
  setInputValue(color);
  setShowSuggestions(false);
};

  useEffect(()=>{
    if (selectedColor && selectedColor!=='') {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "the color "+selectedColor+" selected succesfuly"
      });
    }
 
  },[selectedColor])
const handleInputChange = (e) => {
  const inputValue = e.target.value.toLowerCase();
  setInputValue(inputValue);

  
  // Filter suggestions based on input value
  const filteredSuggestions = colorsArray&&colorsArray.filter(item =>
    item.color &&item.id&& item.color.toLowerCase().includes(inputValue)
    );

  // Update state with filtered suggestions
  setSuggestions(filteredSuggestions);

  // Show suggestions if input value is not empty
  setShowSuggestions(inputValue !== '');
};
const handleInputBlur = () =>{
  setTimeout(() => {
    setShowSuggestions(false);
  }, 300);
}
const handleInputFocus = () =>{
  if (inputValue !== '') {
    setShowSuggestions(true)
  }
}



        {/* ********************************************************************************************************
        subcategorieItemsD && subcategorieItemsD.map((item) => (
    item.details && item.details.map((detail) => (
        detail.fabric_type && (
            <h1 key={detail.fabric_type.id}>jdida {detail.fabric_type.category}</h1>
        )
    ))
        ))*/}




const handleCancelColor = () => {
  // Check if the current input value is already empty
  if (inputValue !== '') {
    // Only update the input value if it's not already empty
    setInputValue('');
  }
}
/*const handleConfirmColor = (colorr) => {
  if (colorr !== selectedColor) { // Only update state if the new color is different from the current selected color
    setSelectedColor(colorr);
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "La couleur a été enregistrée avec succès.",
      showConfirmButton: false,
      timer: 1500,
      customClass: {
        popup: "color-saved-alert",
        title:"color-saved-alert-title"
      }
    });
  }
}*/




  const handleSee = () =>{
    if (isActivePopupSection == '') {
      Swal.fire({
        text: "Aucune sélection précédente n'est disponible.",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#7b6248",
        confirmButtonText: "OK"
      })
    }
    else{
      setPopupVisibility(true)
      setModifyPopupVisible(true)
    }

  }
  {console.log(props.subcategorieItemsD)}

  /*useEffect(() => {
    if (props.selectedSubOption !== null) {
      fetch(`https://icanto-api.gisway.org/api/v1/fabrics/${props.material}/${props.selectedSubOption}/fabric-types`)
        .then(response => response.json())
        .then(data => setFabriccTypes(data.items))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [props.selectedSubOption, props.material]);*/


  const ModifyPopup = ({
    isActive,
    modifiedObject,
    modifiedSubOption,
    modifiedMaterial,
    modifiedAdditionalOption,
    modifiedColor,
    handleModificationConfirm,
    handleModificationCancel,
    availableCategories,
  }) => {
    const [selectedAdditionalOption, setSelectedAdditionalOption] = useState(modifiedAdditionalOption);

    const renderUniqueCategories = () => {
      const filteredItems = props.subcategorieItemsD.filter(item => parseInt(item.fabric.id) === parseInt(modifiedMaterial));
      const uniqueCategories = {};
      filteredItems.forEach(item => {
        item.details.forEach(detail => {
          uniqueCategories[detail.fabric_type.category] = detail.fabric_type.id;
        });
      });
        if (!props.subcategorieItemsD || props.subcategorieItemsD.length === 0) {
          console.log("Subcategorie items are not available.");
          return null;
        }

        // Check if any items match the selected material
        if (filteredItems.length === 0) {
          console.log("No items match the selected material.");
          return null;
        }
      return (
        <div className='additional-options'>
          {/* Map through unique categories and render each as an additional option */}
          {Object.entries(uniqueCategories).map(([category, id], index) => (
          <div
            className={`additional-option ${selectedAdditionalOption === category ? 'active-version-material' : ''}`}
            onClick={() => handleAdditionalOptionClick(props.selectedMaterial, category)}
            key={index}
          >
            {category}
          </div>
        ))}
        </div>
      );
    };

    const handleConfirmColor = (color) => {
      if (color !== selectedColor) {
        setModifiedColor(color);
        modifiedObject['couleur'] = color;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "La couleur a été enregistrée avec succès.",
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: "color-saved-alert",
            title: "color-saved-alert-title"
          }
        });
      }
    };
    
    const handleCancelColor = () => {
      modifiedObject['couleur'] = '';
    };
    
    const suggestionSelected = (suggestion) => {
      const suggestionText = `${suggestion.reference} - ${suggestion.color}`;
      modifiedObject['couleur'] = suggestionText;
      setColorValue(suggestion['COULEUR ']);
      setShowSuggestions(false);
      setFabricTypeId()
    };
    
    const handleInputChange = (e) => {
      const inputValue = e.target.value.toLowerCase();
      modifiedObject['couleur'] = inputValue;
    
      const filteredSuggestions = props.subcategorieItemsD
        .filter(item =>
          item.details && item.details.some(detail =>
            detail.fabric_type &&
            detail.fabric_type.color.toLowerCase().includes(inputValue)
          )
        );
    
      setSuggestions(filteredSuggestions);
      setShowSuggestions(inputValue !== '');
    };

const handleMaterialClick = (materialText, material) =>{
  setModifiedMaterial(material)
  modifiedObject['version'] = parseInt(material);
  props.setSelectedMaterialText(materialText);
  modifiedObject['version-text'] = materialText;
}

    const uniqueCategories = {}; 
    props.subcategorieItemsD
      .filter(item => item.fabric.id === props.selectedMaterial)
      .forEach(item => {
        item.details.forEach(detail => {
          uniqueCategories[detail.fabric_type.category] = detail.fabric_type.id;
        });
      });
      const handleAdditionalOptionClick = (additionalOptionId, additionalOption) => {
        setModifiedAdditionalOption(additionalOption);
        setModifiedAdditionalOptionId(additionalOptionId);
        modifiedObject['version-material'] = additionalOption;
        modifiedObject['fabrictypeId'] = additionalOptionId;
        const findPriceByFabricTypeId = (fabricTypeId) => {
          const matchedItem = props.subcategorieItemsD.find(item =>
            item.sub_category.id === props.selectedSubOption &&
            item.details.some(detail => detail.fabric_type.id === fabricTypeId)
          );
      
          if (matchedItem) {
            const matchedDetail = matchedItem.details.find(detail => detail.fabric_type.id === fabricTypeId);
            if (matchedDetail) {
              return matchedDetail.amount;
            }
          }
          return null;
        };
      
        const matchedPrice = findPriceByFabricTypeId(parseInt(additionalOptionId));
      
        if (matchedPrice) {
          setPrice(matchedPrice);
          setOriginalPrice(matchedPrice);
        }
        console.log(modifiedAdditionalOption)
      };
      
    return (
      <div className={`popup-section ${isActive ? 'active-popup-section' : ''}`}>
      <div className="inside-modify-popup">
        <div className="inside-popup-content">
          <h2 className='sous-categorie'>{modifiedSubOption}</h2>

      <div className='pop-up-selects'>
      <label className='select1 mater'>Le Matériau:</label>
<div className='material-op'>{modifiedObject['version-text']}
  {/*
    props.subcategorieItemsD &&
    props.subcategorieItemsD.length > 0 &&  // Render only if subcategorieItemsD is not empty
    Array.from(new Set(props.subcategorieItemsD
      .filter(item => parseInt(item.sub_category.id) === parseInt(modifiedObject['sou-categorie']))
      .map(item => item.fabric.id)))
      .map((materialId, index) => {
        const materialItem = props.subcategorieItemsD.find(item => parseInt(item.fabric.id) === parseInt(materialId));
        return (
          <div className={`material-option ${modifiedObject['version'] === materialItem.fabric.id ? 'material-active' : ''}`} key={index} onClick={() => handleMaterialClick(materialItem.fabric.name, materialItem.fabric.id)}>
            {materialItem.fabric.name}
          </div>
        );
      })
    */}
</div>

<label className='select1'>Version Matériau: </label>{modifiedObject['version-material']}
{/*renderUniqueCategories()*/}













          






              {/* OOOOOOOOOOOOOOOOOOOOLD ALTEEEEEEEEEEEEEER VERSIOOOOOOOOOOON MATERIAAAAAAAAAAAAL!!!
              models.filter((model)=>model && model.model == 'I861 - MIRA')
              .map((model)=>model.prix
              .filter((pr)=>pr && pr.id_textiles !== null && pr.id_textiles == modifiedObject['version'])
              .map((p)=>
              p ?(
                <div className={`additional-option additional-option-pop ${modifiedObject['version-material'] === p.serie ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick(p.serie)}>{p.serie}</div>
              )
              : null
              )
              )
              */}

{console.log('objects kamlin are:', objects)}
<div className='colors-search'>
    <label className='select1'>Couleur</label>
    <div className='color-input-btn'>
      <input
      disabled
        type="text"
        value={modifiedObject['couleur']}
        onChange={handleInputChange}
        placeholder="Type a color reference..."
        className='color-input'
      />
      <div className='cancelColor-confirmColor'>
        <div className='confirm-color-div' onClick={() => handleConfirmColor(colorValue)}>
          <FontAwesomeIcon icon={faCheck} className="confirm-color-icon"/>
        </div>
        <div className='cancel-color-div' onClick={handleCancelColor}>
          <FontAwesomeIcon icon={faXmark} className="cancel-color-icon"/>
        </div>
      </div>
    </div>

    {showSuggestions && (
      <div className='suggestions-div'>
        {suggestions.map((item, index) => (
          <div key={index} className='suggestion-div' onClick={() => suggestionSelected(item)}>
            <p>
              {item['MATIERE '] && `MATIERE: ${item['MATIERE ']}`} 
              {item['ID'] && `ID: ${item['ID']}`} 
              {item['CATEGORIE'] && `CATEGORIE: ${item['CATEGORIE']}`} 
              {item['REFERENCE '] && `REFERENCE: ${item['REFERENCE ']}`} 
              {item['COULEUR '] && `COULEUR: ${item['COULEUR ']}`}
            </p>
          </div>
        ))}
      </div>
    )}
  </div>
          </div>
          {/*<h2 className='color-label'>Couleur</h2>
          <div className='colors'>
  <div
    className={`color-option blue ${modifiedObject['couleur'] === 'blue' ? 'selected' : ''}`}
    onClick={() => handleColorClick('blue')}
  ></div>

  <div
    className={`color-option black ${modifiedObject['couleur'] === 'black' ? 'selected' : ''}`}
    onClick={() => handleColorClick('black')}
  ></div>

  <div
    className={`color-option white ${modifiedObject['couleur'] === 'white' ? 'selected' : ''}`}
    onClick={() => handleColorClick('white')}
  ></div>

  <div
    className={`color-option brown ${modifiedObject['couleur'] === 'brown' ? 'selected' : ''}`}
    onClick={() => handleColorClick('brown')}
  ></div>
            </div> */}


  
  
          {/*<div className="popup-buttons">
            <button className='modify-confirmer' onClick={() => handleModificationConfirm()}>Confirmer</button>
            <button className='modify-annuler' onClick={() => handleModificationCancel()}>Annuler</button>
    </div> */}
        </div>
      </div>
      </div>
    );
  };
  const handleDeleteCat = () => {
    Swal.fire({
      title: "Confirmer la suppression ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7b6248",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Supprimer",
      customClass: {
        popup: "myAlert"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedObjects = objects.filter(obj => obj['categorie'] !== isActivePopupSectionCat);
        setObjects(updatedObjects);
        
        const deletedObjects = objects.filter(obj => obj['categorie'] === isActivePopupSectionCat);
        const subCategoriesArray = deletedObjects.map(obj => obj['sou-categorie']);
        
        const disabledOptionss = disabledOptions.filter(option => !subCategoriesArray.includes(option));
        setDisabledOptions(disabledOptionss);
  
        // Update prices based on deleted objects
        deletedObjects.forEach((obj) => {
          setTotalPrice(prevPrice => prevPrice - obj['prix']);
          setTotalPriceTTC(prevPriceTTC => prevPriceTTC - obj['prixTTC']);
        });
        
        setPopupVisibility(false);
        
        const firstSection = updatedObjects.length > 0 ? updatedObjects[0]['sou-categorie'] : '';
        setActivePopupSection(firstSection);
        
        Swal.fire({
          title: "L'élément a été supprimé avec succès.",
          icon: "success",
          confirmButtonColor: "#7b6248",
          customClass: {
            confirmButton: "my-ok-button"
          }
        });
      }
    });
  }
  
  
  const handleDelete = () => {

    Swal.fire({
      title: "Confirmer la suppression ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7b6248",
      cancelButtonColor: "#d33",
      cancelButtonText: "Annuler",
      confirmButtonText: "Supprimer",
      customClass: {
        popup: "myAlert"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedObjects = objects.filter(obj => obj['sou-categorie'] !== isActivePopupSection);
        setObjects(updatedObjects);
        const disabledOptionss = disabledOptions.filter((option)=>option !== isActivePopupSection);
        setDisabledOptions(disabledOptionss);
        const objDeleted = objects.find((obj)=>obj['sou-categorie'] === isActivePopupSection);
        setTotalPrice(totalPrice - objDeleted['prix']);
        setTotalPriceTTC(totalPriceTTC - objDeleted['prixTTC'])
        setPopupVisibility(false)
        const firstSection = updatedObjects.length > 0 ? updatedObjects[0]['sou-categorie'] : '';
        setActivePopupSection(firstSection);
        Swal.fire({
          title: "L'élément a été supprimé avec succès.",
          icon: "success",
          confirmButtonColor: "#7b6248",
          customClass: {
            confirmButton: "my-ok-button"
          }
        });
      }
    });
  }

  const handleModificationConfirm = () => {
    {console.log(modifiedObject)}
    const updatedObjects = objects.map((obj) => {
      {console.log(obj)}
      if (obj['sou-categorie'] === modifiedObject['sou-categorie']) {
        {/*setCategorieDisabled(true) */}
        console.log('I Found It!')
        {/*obj['sou-categorie'] = modifiedSubOption;
        obj['version'] = modifiedMaterial;
        obj['version-material'] = modifiedAdditionalOption;
      obj['couleur'] = modifiedColor; */}
        return {
          ...obj,
          'sou-categorie': modifiedObject['sou-categorie'],
          'sou-categorie-text': modifiedObject['sou-categorie-text'],
          'version': modifiedObject['version'],
          'version-text': modifiedObject['version-text'],
          'version-material': modifiedObject['version-material'],
          'fabrictypeId': modifiedObject['fabrictypeId'],
          'couleur': modifiedObject['couleur'],
          'prix': modifiedObject['prix'],
          'image': modifiedObject['image']
        };
      }
      return obj;
    });
    console.log('Modified Data:', {
      souCategorie: modifiedSubOption,
      version: modifiedMaterial,
      versionMaterial: modifiedAdditionalOption,
      couleur: modifiedColor,
    });
  
    setObjects(updatedObjects);
  
    setModifyPopupVisible(false);
    setPopupVisibility(true);
    {console.log(props.selectedMaterialText)}
      {objects.map((obj)=>{console.log(obj)})}
  };
  {objects.map((obj)=>{console.log(obj)})}

  
  const handleModificationCancel = () => {
    setModifiedSubOption(modifiedObject['sou-categorie']);
    props.setSelectedSubOptionText(modifiedObject['sou-categorie-text'])
    setModifiedMaterial(modifiedObject['version']);
    setModifiedMaterialText(modifiedObject['version-text'])
    setModifiedAdditionalOption(modifiedObject['version-material']);
    setModifiedColor(modifiedObject['couleur']);
    setModifyPopupVisible(false);
    setPopupVisibility(false);
  };
  
 
  const handleChangeActivePopupSection = (soucategorie) =>{
    setActivePopupSection(soucategorie);
  }
  const handleChangeActivePopupSectionCat = (categorie) =>{
    setActivePopupSectionCat(categorie);
    setCategorieActive(categorie);
  }
  const closePopup = () =>{
    setPopupVisibility(false)}
    const handleConfirm = () => {
      console.log(selectedAdditionalOptionId, props.selectedMaterialText)
      console.log(selectedAdditionalOption)
      if (props.selectedSubOption !== '' && props.selectedMaterial !== '' && selectedAdditionalOption !== '' && selectedColor !== '') {
        const prixTTC = price * 1.20;
          setObjects([...objects, {
          'modelName': props.selectedModelName,
          'model': props.selectedModel,
          'categorie': props.selectedVersion,
          'categorie-text': props.selectedVersionText,
          'sou-categorie': props.selectedSubOption, 
          'sou-categorie-text': props.selectedSubOptionText, 
          'version': props.selectedMaterial, 
          'version-text': props.selectedMaterialText, 
          'version-material': selectedAdditionalOption, 
          'fabrictypeId': fabricTypeId,
          'couleur': selectedColor, 
          'orientation': props.orientationText,
          'prix': price, 
          'quantity': quantity,
          'prixTTC': prixTTC,
          'image':selectedSubOptionImage,
          'image64':selectedSubOptionImage64,
          'height':props.height,
          'width':props.width,
          'depth':props.depth
        }]);
        
        setCategoriess([...categoriess, props.selectedVersionText]);
        const newTotalPrice = totalPrice + price;
        const newTotalPriceTTC = totalPriceTTC + prixTTC; 
        setTotalPrice(newTotalPrice);
        setTotalPriceTTC(newTotalPriceTTC); 
        setCurrentMaterialId(props.selectedMaterial)
        setCurrentSubcategoryId(props.selectedSubOption)
        setCurrentAdditionalOptionId(selectedAdditionalOptionId)
        props.setSelectedSubOption('');
        props.setSelectedSubOptionText('');
        props.setSelectedMaterial('');
        props.setSelectedMaterialText('');
        setSelectedAdditionalOption('');
        setSelectedAdditionalOptionId();
        setSelectedColor('');
        setInputValue('');
        props.setOrientationText('')
        setSelectedSubOptionImage('');
        setCountObjects(countObjects + 1);
        setModifyPopupVisible(true);
        setPopupVisibility(true)
        setDisabledOptions([...disabledOptions, props.selectedSubOption]);
        {/*setCategorieDisabled(true) */}
        setActivePopupSectionCat(props.selectedVersion);
        setActivePopupSection(props.selectedSubOption);
        setAjouterVisible(true)
        console.log('selected image is', selectedSubOptionImage)
        objects.forEach(obj => console.log(obj));
        {console.log(objects)}
        console.log('hana dkhalt l handleConfirm if');
      } else {
        Swal.fire({
          icon: "error",
          text: "Merci de sélectionner toutes les options.",
          confirmButtonColor: "#7b6248",
        });
      }
    }
  const handleModelChange = (event) =>{
  props.handleModelChange(event)
  }
  const handleVersionChange = (event) => {
    props.handleVersionChange(event);
  };


/*useEffect(() => {
  const fetchData = async () => {
    try {
      const [categoriesResponse, subcategoriesResponse, modelsResponse, fabrycsResponse, fabrycsTypeResponse, subcategorieItemsResponse] = await Promise.all([
        fetch('https://icanto-api.gisway.org/api/v1/categories/').then(response => response.json()),
        fetch('https://icanto-api.gisway.org/api/v1/sub-categories/').then(response => response.json()),
        fetch('https://icanto-api.gisway.org/api/v1/models/').then(response => response.json()),
        fetch('https://icanto-api.gisway.org/api/v1/fabrics/').then(response => response.json()),
        fetch('https://icanto-api.gisway.org/api/v1/fabric-types/').then(response => response.json()),
        fetch('https://icanto-api.gisway.org/api/v1/sub-category-items/').then(response => response.json()),
      ]);

      setCategorieD(categoriesResponse.items);
      setSubcategorieD(subcategoriesResponse.items);
      setModelsD(modelsResponse.items);
      setFabrycs(fabrycsResponse.items);
      setFabrycTypes(fabrycsTypeResponse.items);
      setSubcategorieItemsD(subcategorieItemsResponse.items);

      // You can move this logic inside the `fetchData` function
      const selectedItem = subcategorieItemsResponse.items.find(item => item.sub_category.id === selectedSubOption);
      console.log("selectedItem:", selectedItem);
      if (selectedItem && selectedItem.orientation) {
        const text = selectedItem.orientation.name;
        console.log("orientationText:", text);
        setOrientationText(text);


      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });

      Toast.fire({
        icon: "success",
        title: `${text}`
      });
      } else {
        console.log("No orientation found for selected sub option:", selectedSubOption);
        setOrientationText('');

      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [selectedSubOption]); */
 const handleSubOptionChange = async (event) => {
    props.handleSubOptionChange(event)
  };
  useEffect(() => {
    if (props.selectedSubOption !== null) {
      fetch(`https://icanto-api.gisway.org/api/v1/sub-categories/${props.selectedSubOption}/composants`)
        .then(response => response.json())
        .then(data => setFabricc(data.items))
        .catch(error => console.error('Error fetching data:', error));
    }
  }, [props.selectedSubOption]);

  const handleMaterialClick = (materilText, material) => {
    props.handleMaterialClick(materilText, material);
  };
  const uniqueCategories = {}; 
  props.subcategorieItemsD
    .filter(item => item.fabric.id === props.selectedMaterial)
    .forEach(item => {
      item.details.forEach(detail => {
        uniqueCategories[detail.fabric_type.category] = detail.fabric_type.id;
      })
    }); 
    const handleAdditionalOptionClick = async(fabricId, additionaOptionT) => {
      try {
        const response = await fetch(`https://icanto-api.gisway.org/api/v1/fabric-types/fabrics/${props.selectedSubOption}/${fabricId}/${additionaOptionT}/colors`);
        if (response.ok) {
          const data = await response.json();
          setColorsArray(data.items); 
        } else {
          console.error('Error fetching colors:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching colors:', error);
      }
      setSelectedAdditionalOption(additionaOptionT)
      /*setSelectedAdditionalOption(additionaOption)
      setSelectedAdditionalOptionId(additionalOptionId)
      const findPriceByFabricTypeId = (fabricTypeId) => {
        const matchedItem = props.subcategorieItemsD.find(item =>
          item.sub_category.id === props.selectedSubOption &&
          item.details.some(detail => detail.fabric_type.id === fabricTypeId)
        );
    
        if (matchedItem) {
          const matchedDetail = matchedItem.details.find(detail => detail.fabric_type.id === fabricTypeId);
          if (matchedDetail) {
            return matchedDetail.amount;
          }
        }
        return null;
      };
    
      // Call the findPriceByFabricTypeId function with the selected additional option ID
      const matchedPrice = findPriceByFabricTypeId(parseInt(additionalOptionId));
    
      if (matchedPrice) {
        setPrice(matchedPrice);
        setOriginalPrice(matchedPrice);
      }*/
    };
  

  


  const handleColorClick = (color) => {
    setSelectedColor(color);
  };
  const PopupSection = ({ modifiedObject, isActive }) => {
    const handleModifyClick = () => {

      setModifiedObject(modifiedObject);
      setModifiedSubOption(modifiedObject['sou-categorie']);
      setModifiedMaterial(modifiedObject['version']);
      setModifiedAdditionalOption(modifiedObject['version-material']);
      setModifiedColor(modifiedObject['couleur']);
      setModifyPopupVisible(true);
      setPopupVisibility(false);
    };

    return (
      <div className={`popup-section ${isActive ? 'active-popup-section' : ''}`}>
        <p>Sous-catégorie: {modifiedObject['sou-categorie']}</p>
        <p>Version: {modifiedObject['version']}</p>
        <p>Version Material: {modifiedObject['version-material']}</p>
        <p>Couleur: {modifiedObject['couleur']}</p>
        <button onClick={handleModifyClick}>Modifier</button>
      </div>
    );
  };
  
  
  const truncateString = (inputString, maxLength) => {
    if (inputString.length > maxLength) {
      return inputString.slice(0, maxLength) + '...';
    }
    return inputString;
  };





  const Generate = async (objectsArray) => {
    console.log("6666666666----------------------------");
    
    Swal.fire({
      icon: "question",
      title: "Choose an action",
      showCancelButton: true,
      confirmButtonText: "Devis",
      cancelButtonText: "Facture",
    }).then(async(result) => {
      console.log(price)
      setSerialNumber(serial) //nwli nakhdha gha men URL li deja 
      if (result.isConfirmed) {
        console.log(
          'customer_id:', customerId,
          'fabric_id:', props.selectedMaterial,
          'orientation_id: 1',
          'sub_category_id:', props.selectedSubOption,
          'serial_number:', serialNumber,
          'state:', 'CANCELLED',
          'details=>',
              'fabric_type_id: 1', fabricTypeId,
              'amount:', price,
              'quantity:', quantity
        )    
        for (const object of objectsArray) {






          console.log(object.quantity)
          console.log("666666 reslt------------- ",{
            customer_id: customerId,
            fabric_id: object.version,
            orientation_id: 8,
            sub_category_id: object['sou-categorie'],
            serial_number: serial,
            quantity: 1,
            state: 'PENDING',
            details: [
              {
                fabric_type_id: object['fabrictypeId'],
                amount: object['price'],
                quantity: object['quantity']
              }
            ]
          });
          
          try {
            const response = await fetch('http://localhost:5000/api/sub-category-items', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                customer_id: customerId,
                fabric_id: object.version,
                orientation_id: 8,
                sub_category_id: object['sou-categorie'],
                serial_number: serial,
                state: 'PENDING',
                details: [
                  {
                    fabric_type_id: object['fabrictypeId'],
                    amount: object['prix'],
                    quantity: object['quantity']
                  }
                ]
              })
            });
            console.log('object contain fabric type??:', object)
            console.log(
              'customer_id:', customerId,
              'fabric_id:', object.version,
              'orientation_id: 1',
              'sub_category_id:', object['sou-categorie'],
              'serial_number:', serialNumber,
              'state:', 'CANCELLED',
              'details=>',
                  'fabric_type_id: 1', fabricTypeId,
                  'amount:', price,
                  'quantity:', quantity
            )
            console.log('Response:', response);
  
            if (response.ok) {
              console.log('OK!');
              const responseData = await response.json();
              const { id: subCategoryId } = responseData;
              // Handle the response if needed
            } else {
              const errorData = await response.json();
              console.error('Error:', errorData);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorData.error || 'An error occurred while saving user information'
              });
            }
          } catch (error) {
            console.error('Network Error:', error);
            Swal.fire({
              icon: 'error',
              title: 'Network Error',
              text: 'Failed to communicate with the server. Please try again later.'
            });
          }
          
        }
  
        generatePdf('Devis');
        setPrice(0);
        setQuantity(1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        for (const object of objectsArray) {






          console.log(object.quantity)
          console.log("666666 reslt------------- ",{
            customer_id: customerId,
            fabric_id: object.version,
            orientation_id: 8,
            sub_category_id: object['sou-categorie'],
            serial_number: serial,
            quantity: 1,
            state: 'PENDING',
            details: [
              {
                fabric_type_id: object['fabrictypeId'],
                amount: object['price'],
                quantity: object['quantity']
              }
            ]
          });
          
          try {
            const response = await fetch('http://localhost:5000/api/sub-category-items', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                customer_id: customerId,
                fabric_id: object.version,
                orientation_id: 8,
                sub_category_id: object['sou-categorie'],
                serial_number: serial,
                state: 'DONE',
                details: [
                  {
                    fabric_type_id: object['fabrictypeId'],
                    amount: object['prix'],
                    quantity: object['quantity']
                  }
                ]
              })
            });
            console.log('object contain fabric type??:', object)
            console.log(
              'customer_id:', customerId,
              'fabric_id:', object.version,
              'orientation_id: 1',
              'sub_category_id:', object['sou-categorie'],
              'serial_number:', serialNumber,
              'state:', 'CANCELLED',
              'details=>',
                  'fabric_type_id: 1', fabricTypeId,
                  'amount:', price,
                  'quantity:', quantity
            )
            console.log('Response:', response);
  
            if (response.ok) {
              console.log('OK!');
              const responseData = await response.json();
              const { id: subCategoryId } = responseData;
              // Handle the response if needed
            } else {
              const errorData = await response.json();
              console.error('Error:', errorData);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorData.error || 'An error occurred while saving user information'
              });
            }
          } catch (error) {
            console.error('Network Error:', error);
            Swal.fire({
              icon: 'error',
              title: 'Network Error',
              text: 'Failed to communicate with the server. Please try again later.'
            });
          }
          
        }


        generatePdf('Facture');
        setPrice(0);
        setQuantity(1);
      }
    });
  };
  return (
    <div className='right-side'>
      <div className='breadcrumb-redirect'>
        <div className='bradcrumbs'>
          <span onClick={()=>redirectToHome()}>Home</span><span>/</span><span onClick={()=>redirectToWelcome()}>Client</span><span>/</span><span className='active-breadcrumb'>Product</span>
        </div>
        <p onClick={()=>redirectToVisselle()}>Autre Categorie?</p>
      </div>

      {/*<div className='title-reviews'>
        <h1 className='product-title'>Sofa Luxe Confort</h1>

        <div className='review'>
          <FontAwesomeIcon icon={faStar} style={{color: "#757575",}} />
          <FontAwesomeIcon icon={faStar} style={{color: "#757575",}}/>
          <FontAwesomeIcon icon={faStar} style={{color: "#757575",}}/>
          <FontAwesomeIcon icon={faStar} style={{color: "#757575",}}/>
          <FontAwesomeIcon icon={faStar} style={{color: "#757575",}}/>
          <p>{'(0)'}</p>
        </div>
 
        </div>
        <hr className='title-line'/> */}
      <div className='price'>
        <div className='new-price'>{price}&nbsp;DH</div>
      </div>
      <div className='selects'>
      <label className="select1" htmlFor="versionSelect">Gamme De Produit</label>
        <select onChange={handleModelChange} value={props.selectedModelName}>
          <option value="">sélectionner la gamme</option>
          {console.log(props.modelsD)}
          {props.modelsD &&
    props.modelsD
      
      .map((model)=> (
        <option key={model.id} value={model.id}>
          {model.name}
        </option>
      ))
  }
        </select>

      <label className="select1" htmlFor="versionSelect">Designation</label>
<select value={props.selectedVersion} onChange={handleVersionChange}>
  <option>Select an option</option>
  {props.categorieD &&
    props.categorieD
      .filter(cat => cat.product_model.name === props.selectedModelName)
      .map((cat) => (
        <option key={cat.id} value={cat.id}>
          {cat.name}
        </option>
      ))
  }
</select>
  {/*{models.filter((model) => model && model.model === 'I861 - MIRA' && model.categorie !== null).length > 1 ? (
    <>
      <label className="select1" htmlFor="versionSelect" >Catégorie</label>
      <select value={selectedVersion} onChange={handleVersionChange} disabled={isCategorieDisabled}>
        <option value="">Sélectionnez la catégorie</option>
        {models.filter((model) => model && model.model === 'I861 - MIRA').map((model) =>
          model.categorie.map((cat) =>
            cat && cat.id !== null ? (
              <option key={cat.id} value={cat.id}>
                {cat.title}
              </option>
            ) : null
          )
        )}
      </select>
    </>
            ) : null} */}
      <div className='sous-categorie-info'>
        <label className="select1" htmlFor="subOptionSelect">Composant</label>
        { props.orientationText !== '' &&(
        <FontAwesomeIcon icon={faCircleInfo} className='circle-info'onMouseEnter={handleHoverOn} onMouseLeave={handleHoverOff}/>
        )
        }
        {isInfosHovered &&(
          <div className={`demensions-infos ${isInfosHovered ? 'active' : ''}`}>
            <p>Les dimensions de ce canapé sont : {props.orientationText}</p>
            <p>
            Si vous avez des questions sur ce sujet, vous pouvez nous envoyer un e-mail à exemple@email.com ou nous appeler au 0600000000.
            </p>
          </div>
        )
        }
      </div>
      <select id="subOptionSelect" value={props.selectedSubOption} onChange={handleSubOptionChange}>
        {console.log(isSuboptionsVisible)}
    <option value="">Sélectionnez la sous-catégorie</option>
    {props.isSuboptionsVisible && props.selectedVersion !== '' ? (
    props.subcategorieD.length > 0 ? (
        props.subcategorieD.map((subc) => {
            if (subc && parseInt(subc.category.id) === parseInt(props.selectedVersion)) {
                return (
                    <option key={subc.id} value={subc.id} disabled={disabledOptions.includes(subc.id)}>
                        {subc.name}
                    </option>
                );
            } else {
                return null;
            }
        })
    ) : (
        <option value="">Loading...</option>
    )
) : (
    <option value="">No subcategories available</option>
)}









</select>



      </div>

      {/*
  models
    .filter((model) => model && model.model === 'I861 - MIRA')
    .map((model) => {
      console.log("Model:", model); 
      const filteredOrientations = model.orientation.filter(or => or && or.id_souscategorie !== null && or.id_souscategorie === selectedSubOption);
      console.log("Filtered Orientations:", filteredOrientations); 
      return filteredOrientations.map((or) => (
        <div key={or.id} className='orientation'>Orientation: H={or.H}, T={or.T}, L={or.L}</div>
      ))
    }) */
}





<label className='select1 mater'>Le Matériau:</label>
<div className='material-op'>

  {
    fabricc&&fabricc.fabrics.map((fabric, index) => {
        return (
          <div className={`material-option ${props.selectedMaterial === fabric.id ? 'material-active' : ''}`} key={index} onClick={() => handleMaterialClick(fabric.name, fabric.id)}>
            {fabric.name}
          </div>
        );
      })
  }
</div>




      {/*}
        <label className='select1'>Le Matériau</label>
        <div className='material'>
          
        {models.filter((model) => model && model.model == 'I861 - MIRA')
          .map((model) =>
            model.textiles
            .filter((textile) => textile && textile.id !== null && textile.id_souscategorie == selectedSubOption)
              .map((t) =>
              t ?(
                <div className={`material-option ${selectedMaterial === t.id ? 'material-active' : ''}`} onClick={() => handleMaterialClick(t.id)}>{t.type}</div>
              )
              : null
            )
          )}
                          </div>

          {isDefault &&(
            <div className='material'>
              <div className={`material-option ${selectedMaterial === 'tissu' ? 'material-active' : ''}`} onClick={() => handleMaterialClick('tissu')}>Tissu</div>
              <div className={`material-option ${selectedMaterial === 'cuir' ? 'material-active' : ''}`} onClick={() => handleMaterialClick('cuir')}>Cuir</div>
            </div>
          )} */}



          {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<Filter>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 
          {props.subcategorieItemsD && props.subcategorieItemsD
    .filter(item => item.fabric.id === props.selectedMaterial)    
    .reduce((uniqueCategories, fab) => {
      if (!uniqueCategories.includes(fab.category)) {
        uniqueCategories.push(fab.category);
      }
      return uniqueCategories;
    }, []) // Filter items with fabric id equal to 1
    .map((item, index) => (
      <div
        className={`additional-option ${selectedAdditionalOption === category ? 'active-version-material' : ''}`}
        onClick={() => handleAdditionalOptionClick(category)}
        key={index}
      >
      {item.details && item.details.map(detail => (
                <p key={detail.id}>{detail.fabric_type.category}</p>
            ))}
        </div>
    ))}
          */}



<label className='select1'>Version Matériau</label>
<div className='additional-options'>
  
  {props.fabriccTypes&&props.fabriccTypes.map((fab, index)=>(//console.log('<<<<<<<<< >>>>>>>>>',props.selectedMaterial, '<<<<<<<<<>>>>>>>>', fab.category),
    <div key={index} onClick={() => handleAdditionalOptionClick(props.selectedMaterial, fab.category)}
    className={`additional-option ${selectedAdditionalOption === fab.category ? 'active-version-material' : ''}`}
    >{fab.category}</div>
  ))}
</div>









              {/*models.filter((model)=>model && model.model == 'I861 - MIRA')
              .map((model)=>model.prix
              .filter((pr)=>pr && pr.id_textiles !== null && pr.id_textiles == selectedMaterial)
              .map((p)=>
              p ?(
                <div className={`additional-option ${selectedAdditionalOption === p.serie ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick(p.serie)}>{p.serie}</div>
              )
              : null
              )
              )
              */}

            {/*<div className={`additional-option ${selectedAdditionalOption === 'TB P10 En dhs TTC' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TB P10 En dhs TTC')}>TB P10 En dhs TTC</div>
            <div className={`additional-option ${selectedAdditionalOption === 'TC P11 En dhs TTC' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TC P11 En dhs TTC')}>TC P11 En dhs TTC</div>
            <div className={`additional-option ${selectedAdditionalOption === 'TE P15 en dhs TTC' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TE P15 en dhs TTC')}>TE P15 en dhs TTC</div>
            */}
            {/*isVersionDefault &&(
              <div className='additional-options'>
                <div className={`additional-option ${selectedAdditionalOption === 'TB P10' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TB P10')}>TB P10</div>
            <div className={`additional-option ${selectedAdditionalOption === 'TC P11' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TC P11')}>TC P11</div>
            <div className={`additional-option ${selectedAdditionalOption === 'TE P15' ? 'active-version-material' : ''}`} onClick={() => handleAdditionalOptionClick('TE P15')}>TE P15</div>
              </div>
            )*/}
<div className='colors-search'>
        <label className='select1'>Couleur</label>
        <div className='color-input-btn'>
          <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder="Type a color reference..."
          className='color-input'
          />
            <div className='cancel-color-div' onClick={handleCancelColor}>
              <FontAwesomeIcon icon={faXmark} className="cancel-color-icon"/>
            </div>
        </div>
{/*console.log(suggestions)*/}
        {suggestions && suggestions.length > 0 && showSuggestions && (
  <div className='suggestions-div'>
    
    {suggestions
       // Filter suggestions based on sub_category.id
      .flatMap((item, index) =>
        (
          
          <div className='suggestion-div' key={index} onClick={() => suggestionSelected(item)}>
            <p>{`${item.reference || 'N/A'}`} {`ID: ${item.color || 'N/A'}`}</p>
          </div>
        )
      )}
  </div>
)}




{ nothingFound ?(
    <div className='suggestions-div'>
      <div className='suggestion-div'>
        <p>Nothing Found!</p>
      </div>
    </div>
    )
    :null
    }
</div>
{/*<div className='serial-numeber'>
<input
        type="text"
        id="serialNumberInput"
        value={serialNumber} 
        onChange={handleSerialNumberChange} 
        placeholder='enter the serial number'
      />
  </div> */}











        {/*<div className='colors'>
            <div
            className={`color-option blue ${selectedColor === 'blue' && 'selected'}`}
            onClick={() => handleColorClick('blue')}
            ></div>

            <div
            className={`color-option black ${selectedColor === 'black' && 'selected'}`}
            onClick={() => handleColorClick('black')}
            ></div>

            <div
            className={`color-option white ${selectedColor === 'white' && 'selected'}`}
            onClick={() => handleColorClick('white')}
            ></div>

            <div
            className={`color-option brown ${selectedColor === 'brown' && 'selected'}`}
            onClick={() => handleColorClick('brown')}
            ></div>
  </div> */}
      {/*<div className="quantity-input">
        <button onClick={() => handleQuantityChange(-1)}>-</button>
        <input type="number" value={quantity} onChange={(e) => handleInputChange(e)} />
        <button onClick={() => handleQuantityChange(1)}>+</button>
        </div> */}
            <label className='select1'>Quantité</label>
            <div className='quantity'>
                <div className='increase' onClick={handleIncrease}>+</div>
                <p>{quantity}</p>
                <div className='decrease' onClick={handleDecrease}>-</div>
            </div>


            <div>
        <label>
          Discount (%):
          <input
            type="number"
            value={discount}
            onChange={handleDiscountChange}
            min="0"
            max="100"
          />
        </label>
      </div>
            {/*{isPopupVisible && (
            <div className={`popup-container ${isPopupVisible ? 'active' : ''}`}>
              <div className={`popup ${isPopupVisible ? 'active' : ''}`}>
                <span className="close-popup" onClick={closePopup}>&times;</span>
                <div className='pop-up-header'>
                  {objects.map((ob, index)=>(
                    <div key={index}>
                      <span value={ob['sou-categorie']} title={ob['sou-categorie']}
                      className={`popup-section ${isActivePopupSection === ob['sou-categorie'] ? 'active-span': ''}`}
                      onClick={()=>handleChangeActivePopupSection(ob['sou-categorie'])}>
                        {truncateString(ob['sou-categorie'], 5)}
                      </span>
                    </div>
                  ))}
                </div>
                <div className='popup-infos'>
                {objects
                .filter((ob) => ob['sou-categorie'] === isActivePopupSection)
                .map((modifiedObject, index) => (
                  <PopupSection
                    key={index}
                    modifiedObject={modifiedObject}
                    isActive={isActivePopupSection === modifiedObject['sou-categorie']}
                    modifiedSubOption={modifiedSubOption}
                      modifiedMaterial={modifiedMaterial}
                      modifiedAdditionalOption={modifiedAdditionalOption}
                      modifiedColor={modifiedColor}
                      handleModificationConfirm={handleModificationConfirm}
                      handleModificationCancel={handleModificationCancel}
                  />
              ))}

                </div>
                <button onClick={closePopup}>Confirmer</button>
              </div>
            </div>
                )} */}

{isModifyPopupVisible && (
  <div className={`popup-container ${isPopupVisible ? 'active' : ''}`}>
    <div className={`popup ${isPopupVisible ? 'active' : ''}`}>
      
      <span className="close-popup" onClick={closePopup}>    <FontAwesomeIcon 
                    icon={faXmark} 
                    onClick={closePopup} 
                    className='close-popup'
                    /></span>
      <div className='infos-container'>
      <div className='tabs-container'>
        <div className='categories-wrapper'>
          {Array.from(new Set(objects.map(obj => obj['categorie'])))
            .filter(categoryId => !categoriess.includes(categoryId))
            .map((categoryId, index) => {
              const category = objects.find(obj => obj['categorie'] === categoryId);
              return (
                <div className='cat-trash-popup'>
                  <div
                    value={category['categorie']}
                    title={category['categorie-text']}
                    className={`category-container ${isActivePopupSectionCat === category['categorie'] ? 'active-span': ''}`}
                    onClick={() => handleChangeActivePopupSectionCat(category['categorie'])}>
                    {truncateString(category['categorie-text'], 5)}
                    <FontAwesomeIcon 
                    icon={faXmark} 
                    onClick={handleDeleteCat} 
                    className={`delete-cat ${isActivePopupSectionCat === category['categorie'] ? 'delete-cat-active' : ''}`}
                    />

                  </div>

                </div>

              );
            })}
          </div>

            <div className='subcategorie-wrapper'>
            {Array.from(new Set(objects.map(obj => obj['categorie'])))
            .filter(categoryId => !categoriess.includes(categoryId))
            .map((categoryId, index) => {
              const category = objects.find(obj => obj['categorie'] === categoryId);
              return (
                  
                  <div className={`subcategory-container ${isActivePopupSectionCat === category['categorie'] ? 'active' : ''}`}>
                    {isActivePopupSectionCat === category['categorie'] && 
                      objects
                        .filter(obj => obj['categorie'] === category['categorie'])
                        .map((subcategory, subIndex) => (
                          <div key={subIndex} className='sub-cat-trash-popup'>
                            <div
                              value={subcategory['sou-categorie']}
                              title={subcategory['sou-categorie-text']}
                              className={`subcategorie-tab ${isActivePopupSection === subcategory['sou-categorie'] ? 'active-span': ''}`}
                              onClick={() => handleChangeActivePopupSection(subcategory['sou-categorie'])}>
                              {truncateString(subcategory['sou-categorie-text'], 5)}
                            </div>
                            <FontAwesomeIcon 
                            icon={faXmark} 
                            onClick={handleDelete}
                            className={`delete-sub-cat ${isActivePopupSection === subcategory['sou-categorie'] ? 'delete-subcat-active' : ''}`}
                            />
                          </div>
                        ))
                    }
                  </div>

              );
            })}
            </div>


      </div>
      <div className='popup-infos'>
        {/* Display ModifyPopup for Selected Subcategory */}
        {objects
          .filter(obj => obj['sou-categorie'] === isActivePopupSection)
          .map((modifiedObject, index) => (
            <ModifyPopup
              key={index}
              modifiedObject={modifiedObject}
              isActive={isActivePopupSection === modifiedObject['sou-categorie']}
              modifiedSubOption={modifiedSubOption}
              modifiedMaterial={modifiedMaterial}
              modifiedAdditionalOption={modifiedAdditionalOption}
              modifiedColor={modifiedColor}
              handleModificationConfirm={handleModificationConfirm}
              handleModificationCancel={handleModificationCancel}
              handleDelete={handleDelete}
            />
        ))}
      </div>
      <div className='popup-buttons'>
        <button onClick={()=>Generate(objects)} className='supprimer'>Generate</button>
          <button className='supprimer' onClick={()=>generateBoneDeLivraison()}>Bon De Livraison <FontAwesomeIcon className='pdf-icon' icon={faFilePdf}/></button>
        <button onClick={handleModificationConfirm} className='confirm-modification'>Confirmer</button>
      </div>
    </div>
    </div>
  </div>
)}



      <div className='last-buttons'>
        <button onClick={handleConfirm} className='confirm-selections'>Confirmer</button>
        <button onClick={handleSee} className='see-selections'>Voir Les Selections</button>
      {/*<DropdownMenu className='dropDown'
        handleConfirmSelections={handleConfirm}
        handleViewSelections={handleSee}
                /> */}
      {/*} {isAjouterVisible && (<button className='submit-button' onClick={handleSubmit}>AJOUTER AU PANIER <FontAwesomeIcon className='addToCart' icon={faCartShopping} /></button>)} */}
      </div>
      {/*<button id="generatePdfButton" onClick={generatePdf}>Generate Devis <FontAwesomeIcon className='pdf-icon' icon={faFilePdf}/></button> */}
    </div>
  );
};

export default DynamicSelects;


















{
/* 
-They have other stuff to take in consideration like tbasl ma3n ans stuff like this(they don't require the entering of largeur and longeur and depth and stuff in the same logiciel they must have marge bigger)
-the stock will not impacted by generating devis but by the facture
-the logo will change
-the colors should not be selected but it can selected just optionally not required cause they import goods from the outside
-the comand (reference designation the quantity)
-choice for TVA 0
-suplier interace(with name of them each one of them)
-the posibility of modify the facture
-designation in the title of meridienne ....
-add TA to tissu (TB TC .. TA)
-sou categorie instead of it element in the desktop logiciel
-model  then designation then element
-refrence unique and if its exist system will stop you
-when creating product  when search with customer same logic as colors

*/
}
















import '../App.css';
import './pop-up.css';
import DynamicSelects from './form';
import ListDevises from './listDevises';
import { useEffect, useState } from 'react';
import MyHeader from './header';
import Register from './Register';
import {Routes, Route} from 'react-router-dom'
import LoginCopy from './LoginCopy';
import Swal from 'sweetalert2';

function ParentComponent() {
  const images = ['sofaPic1.jpg', 'sofa2.png', 'sofa3.jpg', 'sofa4.jpg'];
  const [selectedImage, setSelectedImage] = useState(images[0]);
  const [categorieD, setCategorieD] = useState([]);
  const [subcategorieD, setSubcategorieD] = useState([]);
  const [modelsD, setModelsD] = useState([]);
  const [fabrycs, setFabrycs] = useState([]);
  const [fabrycTypes, setFabrycTypes] = useState([]);
  const [subcategorieItemsD, setSubcategorieItemsD] = useState([]);
  const [customers, setCustomers] = useState('');
  const [selectedModel, setSelectedModel] = useState()
  const [selectedModelName, setSelectedModelName] = useState('');
  const [selectedVersion, setSelectedVersion] = useState('');
  const [selectedVersionText, setSelectedVersionText] = useState('');
  const [isSuboptionsVisible, setVisibleSuboptions] = useState(false);
  const [selectedSubOption, setSelectedSubOption] = useState('');
  const [selectedSubOptionText, setSelectedSubOptionText] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [selectedMaterialText, setSelectedMaterialText] = useState(''); {/* text material */}
  const [selectedAdditionalOption, setSelectedAdditionalOption] = useState('');
  const [isVersionDefault, setVersionDefault] = useState(true);
  const [orientationText, setOrientationText] = useState('');
  const [fabricTypesArray, setFabricTypesArray] = useState([])
  const [height, setHeight] = useState(0);
  const [depth, setDepth] = useState(0);
  const [width, setWidth] = useState(0);
  const [fabriccTypes, setFabriccTypes] = useState()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const subcategorieItemsResponse = await fetch(`https://icanto-api.gisway.org/api/v1/sub-categories/${selectedSubOption}/sub-category-items`);
        const subcategorieItemsData = await subcategorieItemsResponse.json();
        setSubcategorieItemsD(subcategorieItemsData.items);
        console.log(subcategorieItemsD);
      } catch (error) {
        console.error('Error fetching subcategory items:', error);
      }
      /*const selectedModel = models.find(model => model.model === 'I843 - SURF');
          if (selectedModel) {
              const selectedSubCat = selectedModel.sousCategorie.find(sousCat => sousCat.id === selectedSubOption);
              if (selectedSubCat) {
                  setSelectedSubOptionImage(selectedSubCat.imageUrl);
                  const base64Image = await convertImageUrlToBase64(selectedSubCat.imageUrl);
                  setSelectedSubOptionImage64(base64Image);
                  console.log(selectedSubOptionImage64);
              }
          }*/
  
    };
  
    if (selectedSubOption) {
      fetchData();
    }
  }, [selectedSubOption]);
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, subcategoriesResponse, modelsResponse, fabrycsResponse, fabrycsTypeResponse, subcategorieItemsResponse, customersResponse] = await Promise.all([
          fetch('https://icanto-api.gisway.org/api/v1/categories/').then(response => response.json()),
          fetch('https://icanto-api.gisway.org/api/v1/sub-categories/').then(response => response.json()),
          fetch('https://icanto-api.gisway.org/api/v1/models/').then(response => response.json()),
          //fetch('https://icanto-api.gisway.org/api/v1/fabrics/').then(response => response.json()),
          //fetch('https://icanto-api.gisway.org/api/v1/fabric-types/').then(response => response.json()),
          //fetch('https://icanto-api.gisway.org/api/v1/sub-category-items/').then(response => response.json()),
          fetch('https://icanto-api.gisway.org/api/v1/customers/').then(response => response.json())
        ]);
  
        setCategorieD(categoriesResponse.items);
        setSubcategorieD(subcategoriesResponse.items);
        setModelsD(modelsResponse.items);
        //setFabrycs(fabrycsResponse.items);
        //setFabrycTypes(fabrycsTypeResponse.items);
        //setSubcategorieItemsD(subcategorieItemsResponse.items);
        setCustomers(customersResponse)
        console.log(subcategorieItemsResponse.items)

      const selectedItem = subcategorieItemsResponse.items.find(item => item.sub_category.id === selectedSubOption);
      console.log("selectedItem:", selectedItem);
      if (subcategorieItemsResponse.items.find(item => item.sub_category.id === selectedSubOption) && selectedItem.orientation) {
        const text = selectedItem.orientation.name;
        console.log("orientationText:", text);
        setOrientationText(text);


      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });

      Toast.fire({
        icon: "success",
        title: `the selected orientation is: ${text}!`
      });
      } else {
        console.log("No orientation found for selected sub option:", selectedSubOption);
        setOrientationText('');

      }
        /*console.log("selectedItem:", selectedItem);
        if (selectedItem && selectedItem.orientation) {
          const text = selectedItem.orientation.name;
          console.log("orientationText:", text);
          setOrientationText(text);
  
  
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          }
        });
  
        Toast.fire({
          icon: "success",
          title: `${text}`
        });
        } else {
          console.log("No orientation found for selected sub option:", selectedSubOption);
          setOrientationText('');
  
        } */
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [selectedSubOption]);
  
  
  
  
  
  
  

//MQTERIEL ====RECORD-ID
//QPI/V1/FABRICS/.....;QTERIEL---/FABRICS-TYPES
useEffect(() => {
  if (selectedSubOption !== null) {
    fetch(`https://icanto-api.gisway.org/api/v1/fabrics/${selectedMaterial}/${selectedSubOption}/fabric-types`)
      .then(response => response.json())
      .then(data => setFabriccTypes(data.items))
      .catch(error => console.error('Error fetching data:', error));
  }
}, [selectedSubOption, selectedMaterial]);

  const handleMaterialClick = async(materialText, material) => {
    setSelectedMaterial(material);
    setSelectedMaterialText(materialText);
    setSelectedAdditionalOption('');
    try {
      const response = await fetch(`https://icanto-api.gisway.org/api/v1/fabrics/${material}/fabric-types`);
      if (response.ok) {
        const data = await response.json();
        setFabricTypesArray(data.items); 
      } else {
        console.error('Error fetching fabric types:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching fabric types:', error);
    }
    //setVersionDefault(false)
  };
  const handleModelChange = (event) =>{ // CHANGE MODEL METHOD
    const model = event.target.value;
    setSelectedModel(model);
    const modelName = event.target.options[event.target.selectedIndex].text;
    setSelectedModelName(modelName);
  }

  const handleVersionChange = (event) => { // CHANGE CATEGORY METHOD
    setVisibleSuboptions(true);
    const version = event.target.value;
    setSelectedVersion(version);
    const categoryText = event.target.options[event.target.selectedIndex].text;
    setSelectedVersionText(categoryText);
    
    const versionId = event.target.value;
    const category = categorieD.find(cat => cat.id === parseInt(versionId));
    if (category) {
      setHeight(category.height);
      setDepth(category.depth);
      setWidth(category.length);
    }
  };
  const handleSubOptionChange = (event) => { // CHANGE SUB CATEGORY METHOD
    const subOption = parseInt(event.target.value);
    setSelectedSubOption(subOption);
    const versionText = event.target.options[event.target.selectedIndex].text;
    setSelectedSubOptionText(versionText);
  };



  const handleClick = () => {
    console.log('methods passed succesfully!')
};
  const handleImageClick = (image) => {
    setSelectedImage(image);
  <Routes>
    <Route path='/Register' element={<Register />} />
    <Route path='/loginCopy' element={<LoginCopy />} />

  </Routes>
  };
  return (
    <div className="ParentComponent">
      {/*<VeryHeader /> */}
      <MyHeader />
      <div className='main'>
      <div className='left-side'>
      <div className='image-list large-screen'>
        {images.map((image, index) => (
          <img
            key={index}
            src={`/images/${image}`}
            alt="beautiful sofa"
            onClick={() => handleImageClick(image)}
            className={selectedImage === image ? 'selected' : ''}
          />
        ))}
      </div>
      <div className='main-image'>
        <img
          className="main-image-display"
          src={`/images/${selectedImage}`}
          alt="beautiful sofa"
        />
      </div>
      <div className='image-list small-screen'>
        {images.map((image, index) => (
          <img
            key={index}
            src={`/images/${image}`}
            alt="beautiful sofa"
            onClick={() => handleImageClick(image)}
            className={selectedImage === image ? 'selected' : ''}
          />
        ))}
      </div>
    </div>

    <DynamicSelects
        handleClick={handleClick}
        categorieD={categorieD}
        setCategorieD={setCategorieD}
        subcategorieD={subcategorieD}
        setSubcategorieD={setSubcategorieD}
        modelsD={modelsD}
        setModelsD={setModelsD}
        fabrycs={fabrycs}
        setFabrycs={setFabrycs}
        fabrycTypes={fabrycTypes}
        setFabrycTypes={setFabrycTypes}
        subcategorieItemsD={subcategorieItemsD}
        setSubcategorieItemsD={setSubcategorieItemsD}
        handleVersionChange={handleVersionChange}
        selectedVersion={selectedVersion}
        setSelectedVersion={setSelectedVersion}
        selectedVersionText={selectedVersionText}
        setSelectedVersionText={setSelectedVersionText}
        isSuboptionsVisible={isSuboptionsVisible}
        setVisibleSuboptions={setVisibleSuboptions}
        handleSubOptionChange={handleSubOptionChange}
        selectedSubOption={selectedSubOption}
        setSelectedSubOption={setSelectedSubOption}
        selectedSubOptionText={selectedSubOptionText}
        setSelectedSubOptionText={setSelectedSubOptionText}
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        selectedMaterialText={selectedMaterialText}
        setSelectedMaterialText={setSelectedMaterialText}
        selectedAdditionalOption={selectedAdditionalOption}
        setSelectedAdditionalOption={setSelectedAdditionalOption}
        isVersionDefault={isVersionDefault}
        setVersionDefault={setVersionDefault}
        handleMaterialClick={handleMaterialClick}
        handleModelChange={handleModelChange}
        selectedModel={selectedModel}
        setSelectedModel={setSelectedModel}
        selectedModelName={selectedModelName}
        setSelectedModelName={setSelectedModelName}
        orientationText={orientationText}
        setOrientationText={setOrientationText}
        height={height}
        setHeight={setHeight}
        width={width}
        setWidth={setWidth}
        depth={depth}
        setDepth={setDepth}
        fabricTypesArray={fabricTypesArray}
        fabriccTypes={fabriccTypes}
        
    />

      </div>
      <ListDevises subcategorieItemsD={subcategorieItemsD} />

      {/*<hr className='half-line'/>
      <Description /> */}
    </div>
  );
}

export default ParentComponent;

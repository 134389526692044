import React, { useState } from 'react';
import './dropDownStyle.css'
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faEye } from '@fortawesome/free-regular-svg-icons';

const DropdownMenu = ({ handleConfirmSelections, handleViewSelections }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleConfirmClick = () =>{
    setIsOpen(false);
    handleConfirmSelections();
  }

  const handleViewClick = () =>{
    setIsOpen(false);
    handleViewSelections();
  }

  return (
    <div className="dropdown">
      <button className="dropdown-toggle" onClick={toggleMenu}>
        Sélections
      </button>
      <div className={`dropdown-content ${isOpen ? 'show' : ''}`}>
        <button className="option1 dropdown-option" onClick={handleConfirmClick}>
          Confirmer <FontAwesomeIcon icon={faCircleCheck}/>
        </button>
        <button className="option2 dropdown-option" onClick={handleViewClick}>
          Voir Sélections <FontAwesomeIcon icon={faEye}/>
        </button>
      </div>
    </div>
  );
};

export default DropdownMenu;

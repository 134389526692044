import React, { useState, useEffect } from 'react';
import '../App.css';
import DropdownMenu from './dropDown';
import Swal from 'sweetalert2';
import models from './data';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { faCartShopping, faCheck, faTrash, faXmark, faCircleInfo, faStar } from '@fortawesome/free-solid-svg-icons';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import formatNumberWithTwoDecimals from './numberFormat';
import { v4 as uuidv4 } from 'uuid';
import MyHeader from './header';

const DynamicSelectsVisselle = () => { 
  const [visselles, setVisselles] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('');
  const [produitId, setProduitId] = useState(null);
  const [produitText, setProduitText] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [serviceText, setServiceText] = useState('');
  const [serviceId, setServiceId] = useState(null);
  const [prixVente, setPrixVente] = useState(0);
  const [basePrixVente, setBasePrixVente] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [selections, setSelections] = useState([]);
  const [pdfReference, setPdfReference] = useState(0)
  const [totalPrix, setTotalPrix] = useState(0);
  const [totalPrixTTC, setTotalPrixTTC] = useState(0);
  const [discount, setDiscount] = useState(0)

  const urlParams = new URLSearchParams(window.location.search);
  const firstName = urlParams.get('first_name');
  const lastName = urlParams.get('last_name');
  const phone = urlParams.get('phone');
  const address = urlParams.get('email');
  const serial = urlParams.get('serialNumber');
  const customerId = urlParams.get('customerId');


  const handleDiscountChange = (e) => {
    const newDiscount = parseInt(e.target.value) || 0; // Get the discount value from input
    setDiscount(newDiscount); // Update the discount state
    const discountAmount = (basePrixVente * newDiscount) / 100; // Calculate discount amount based on percentage
    const newPrice = basePrixVente - discountAmount; // Calculate new price based on discount amount
    setPrixVente(newPrice); // Update the price state
  };

  const redirectToHome = () =>{
    window.location.href = `/`
  }
  const redirectToWelcome = () =>{
    window.location.href = `/welcome-back?customerId=${customerId}`
  }
  
  //customerId
  const generate = () =>{
    console.log("---------------------");
    console.log({
      customer_id: customerId,
      fabric_id: null,
      orientation_id: null,
      sub_category_id: null,
      serial_number: serial,
      state: 'PENDING',
      details: [],
      accessories: selections.map((obj) => ({
        product_id: obj.serviceId,
        quantity: obj.quantity,
        price: obj['prix'],
        serial_number: serial,
      })),
    });
    console.log("---------------------");

    Swal.fire({
      icon: "question",
      title: "Choose an action",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Devis",
      denyButtonText: "Bonne De Livraison",
      cancelButtonText: "Facture",
    }).then(async(result) => {
      if (result.isConfirmed) {
        //<<<<<<<<<<<<<<<<<<<<<<<<<<< STOCKING DEVIS VISSELLEEEEEE >>>>>>>>>>>>>>>>>>>>>>>>>>
        try {
          const response = await fetch('http://localhost:5000/api/sub-category-items/store-optional', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              customer_id: customerId,
              fabric_id: null,
              orientation_id: null,
              sub_category_id: null,
              serial_number: serial,
              state: 'PENDING',
              details: [],
              accessories: selections.map((obj) => ({
                product_id: obj.serviceId,
                quantity: obj.quantity,
                price: obj['prix'],
                serial_number: serial,
              })),
            }),
          });
    
          if (response.ok) {
            const data = await response.json();
            Swal.fire({
              title: 'Success!',
              text: 'Data has been stored successfully.',
              icon: 'success',
              confirmButtonText: 'OK',
            });
          } else {
            const errorData = await response.json();
            Swal.fire({
              title: 'Error!',
              text: `Failed to store data: ${errorData.message}`,
              icon: 'error',
              confirmButtonText: 'OK',
            });
          }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire({
            title: 'Error!',
            text: 'An error occurred while storing data.',
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
        //<<<<<<<<<<<<<<<<<<<<<<<<<<< END STOCKING VISSELLEEEEE >>>>>>>>>>>>>>>>>>>>>>>>>
        generatePdf()
  }
  else if (result.dismiss === Swal.DismissReason.cancel) {
    generateFacture();
  }
  else if (result.isDenied) {
    generateBon();
  }
});
  }

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<BON DE LIVRAISON >>>>>>>>>>>>>>>>>>>>>>>>>>>

const generateBon = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const chunkSize = 6;
  const chunks = [];
  const totalPages = Math.ceil(selections.length / chunkSize);

  for (let i = 0; i < selections.length; i += chunkSize) {
    chunks.push(selections.slice(i, i + chunkSize));
  }

  setPdfReference(pdfReference + 1);
  const currentPage = 1;

  const docDefinition = {
    pageMargins: [40, 160, 40, 160],
    header: [
      {
        height: 500,
        columns: [
          { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
          { text: 'Bone de livraison', fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
        ]
      },
      { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248' }] },
    ],
    footer: function(currentPage, pageCount) {
      return [
        { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style: 'footer11' },
        { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
        { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
        { text: 'Tel : 00212661093929', style: 'footer1' },
        { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
      ];
    },
    
    content: [
      {
        columns: [
          { text: 'Client: ' + firstName+' '+lastName, style: 'client' },
          { text: ' ' + 'bon de livraison' + ' N°:', style: 'devis' },
          { text: pdfReference, style: 'x' },
        ]
      },
      {
        columns: [
          { text: 'Adresse Client: ' + address, style: 'adressClient' },
          { text: "Date:", style: 'dateE' },
          { text: '4/30/2022', style: 'date' },
        ]
      },
      {
        columns: [
          { text: 'Telephone: ' + phone, style: 'adressClient' },
          { text: "" },
          { text: '' },
        ]
      },
      {
        columns: [
          { text: '', style: 'vidText' },
        ]
      }
    ],
    styles: {
      foot: { alignment: 'center' },
      facture: { fontSize: 30, color: '#7b6248', bold: true, margin: [40, 40, 0, 0] },
      tableStyle: { alignment: 'center', marginTop: 70, color: '#7b6248' },
      adressText: { color: '#7b6248' },
      factureA: { marginTop: 15, bold: true, color: '#7b6248' },
      client: { color: '#7b6248', alignment: 'left' },
      devis: { alignment: 'center', color: '#7b6248', margin: [110, 0, 0, 0], bold: true, fontSize: 12 },
      x: { alignment: 'right', marginRight: 9, color: '#7b6248', bold: true },
      adressClient: { marginTop: 5, color: '#7b6248' },
      dateE: { alignment: 'start', color: '#7b6248', margin: [116, 2, 0, 0], bold: true },
      date: { alignment: 'start', color: '#7b6248', bold: true, margin: [107, 2, 0, 4] },
      ref: { alignment: 'start', color: '#7b6248', margin: [118, 8, 0, 0], bold: true },
      refN: { alignment: 'right', color: '#7b6248', bold: true, margin: [135, 8, 9, 4] },
      tableHeader: { bold: true, fillColor: 'white', color: '#7b6248', alignment: 'center' },
      tableHeaderTTC: { fillColor: '#7b6248', color: 'white' },
      valueTTC: { fillColor: '#7b6248', color: 'white' },
      table2: {},
      tableHeader1: { fillColor: '#f3f3f3' },
      value: { fillColor: '#f3f3f3' },
      footer1: { fontSize: 10, bold: true, alignment: 'center' },
      warning: { fontSize: 14, bold: true, alignment: 'center', color: 'red' },
      footer11: { margin: [0, 5, 0, 0], fontSize: 10, bold: true, alignment: 'center' },
      total: { alignment: 'right', margin: [0, 15, 0, 0] },
      totalTitle: { margin: [0, 15, 0, 0], bold: true, alignment: 'right' },
      HT: { margin: [0, 30, 0, 0] },
      totalPriceBackground: { fillColor: '#D9D6DC' },
    },
  };

  // Append tables for each chunk
  chunks.forEach(chunk => {
    docDefinition.content.push({
      layout: 'lightHorizontalLines',
      table: {
        widths: ['*', '*', '*'],
        body: [
          [
            // { text: 'Image', style: 'tableHeader' },
            { text: 'Produit', style: 'tableHeader' },
            { text: 'Service', style: 'tableHeader' },
            { text: 'Quantite', style: 'tableHeader' },
          ],
          ...chunk.map(item => [
            // { image: item.image64, fillColor: 'f3f3f3', width: 40, height: 40 },
            { text: item['produitText'], fillColor: '#f3f3f3' },
            {
              text: '-' + item.serviceText,
              fillColor: '#f3f3f3',
              fontSize: 8,
              bold: true
            },
            { text: item.quantity, fillColor: '#f3f3f3' },
          ]),
        ],
        border: [],
      },
      style: 'tableStyle'
    });
  });



  docDefinition.content.push({ text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 });

  const logoPath = './images/logo1.png';
  const sofaImagePath = './images/sofa2.png';

  convertImageToBase64(logoPath, base64Logo => {
    convertImageToBase64(sofaImagePath, base64SofaImage => {
      docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    });
  });
};

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END BON DE LIVRAISON >>>>>>>>>>>>>>>>>>>>>>>>>









//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< Devis Generation >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const generatePdf = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const chunkSize = 6;
  const chunks = [];
  const totalPages = Math.ceil(selections.length / chunkSize);

  for (let i = 0; i < selections.length; i += chunkSize) {
    chunks.push(selections.slice(i, i + chunkSize));
  }

  setPdfReference(pdfReference + 1);
  const currentPage = 1;

  const docDefinition = {
    pageMargins: [40, 160, 40, 160],
    header: [
      {
        height: 500,
        columns: [
          { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
          { text: 'devis', fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
        ]
      },
      { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248' }] },
    ],
    footer: function(currentPage, pageCount) {
      return [
        { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style: 'footer11' },
        { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
        { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
        { text: 'Tel : 00212661093929', style: 'footer1' },
        { text: 'Ce ' + 'devis' + ' est valable pendant 6 mois.', style: 'warning' },
        { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
      ];
    },
    
    content: [
      {
        columns: [
          { text: 'Client: ' + firstName+' '+lastName, style: 'client' },
          { text: ' ' + 'devis' + ' N°:', style: 'devis' },
          { text: pdfReference, style: 'x' },
        ]
      },
      {
        columns: [
          { text: 'Adresse Client: ' + address, style: 'adressClient' },
          { text: "Date:", style: 'dateE' },
          { text: '4/30/2022', style: 'date' },
        ]
      },
      {
        columns: [
          { text: 'Telephone: ' + phone, style: 'adressClient' },
          { text: "" },
          { text: '' },
        ]
      },
      {
        columns: [
          { text: '', style: 'vidText' },
        ]
      }
    ],
    styles: {
      foot: { alignment: 'center' },
      facture: { fontSize: 30, color: '#7b6248', bold: true, margin: [40, 40, 0, 0] },
      tableStyle: { alignment: 'center', marginTop: 70, color: '#7b6248' },
      adressText: { color: '#7b6248' },
      factureA: { marginTop: 15, bold: true, color: '#7b6248' },
      client: { color: '#7b6248', alignment: 'left' },
      devis: { alignment: 'center', color: '#7b6248', margin: [110, 0, 0, 0], bold: true, fontSize: 12 },
      x: { alignment: 'right', marginRight: 9, color: '#7b6248', bold: true },
      adressClient: { marginTop: 5, color: '#7b6248' },
      dateE: { alignment: 'start', color: '#7b6248', margin: [116, 2, 0, 0], bold: true },
      date: { alignment: 'start', color: '#7b6248', bold: true, margin: [107, 2, 0, 4] },
      ref: { alignment: 'start', color: '#7b6248', margin: [118, 8, 0, 0], bold: true },
      refN: { alignment: 'right', color: '#7b6248', bold: true, margin: [135, 8, 9, 4] },
      tableHeader: { bold: true, fillColor: 'white', color: '#7b6248', alignment: 'center' },
      tableHeaderTTC: { fillColor: '#7b6248', color: 'white' },
      valueTTC: { fillColor: '#7b6248', color: 'white' },
      table2: {},
      tableHeader1: { fillColor: '#f3f3f3' },
      value: { fillColor: '#f3f3f3' },
      footer1: { fontSize: 10, bold: true, alignment: 'center' },
      warning: { fontSize: 14, bold: true, alignment: 'center', color: 'red' },
      footer11: { margin: [0, 5, 0, 0], fontSize: 10, bold: true, alignment: 'center' },
      total: { alignment: 'right', margin: [0, 15, 0, 0] },
      totalTitle: { margin: [0, 15, 0, 0], bold: true, alignment: 'right' },
      HT: { margin: [0, 30, 0, 0] },
      totalPriceBackground: { fillColor: '#D9D6DC' },
    },
  };

  // Append tables for each chunk
  chunks.forEach(chunk => {
    docDefinition.content.push({
      layout: 'lightHorizontalLines',
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [
            // { text: 'Image', style: 'tableHeader' },
            { text: 'Produit', style: 'tableHeader' },
            { text: 'Service', style: 'tableHeader' },
            { text: 'Quantite', style: 'tableHeader' },
            { text: 'Prix (Dhs)', style: 'tableHeader' },
          ],
          ...chunk.map(item => [
            // { image: item.image64, fillColor: 'f3f3f3', width: 40, height: 40 },
            { text: item['produitText'], fillColor: '#f3f3f3' },
            {
              text: '-' + item.serviceText,
              fillColor: '#f3f3f3',
              fontSize: 8,
              bold: true
            },
            { text: item.quantity, fillColor: '#f3f3f3' },
            { text: item['prix'], fillColor: '#f3f3f3' },
          ]),
        ],
        border: [],
      },
      style: 'tableStyle'
    });
  });

  docDefinition.content.push({
    layout: 'noBorders',
    table: {
      widths: [100, 100],
      body: [
        [
          { text: 'TOTAL HT', style: 'tableHeader1' },
          { text: formatNumberWithTwoDecimals(totalPrix), style: 'value' },
        ],
        [
          { text: 'TVA', style: 'tableHeader1' },
          { text: '20%', style: 'value' },
        ],
        [
          { text: 'TOTAL TTC', style: 'tableHeaderTTC' },
          { text: formatNumberWithTwoDecimals(totalPrixTTC), style: 'valueTTC' },
        ]
      ]
    },
    style: 'table2',
    margin: [307, 15, null, null]
  });

  docDefinition.content.push({ text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 });

  const logoPath = './images/logo1.png';
  const sofaImagePath = './images/sofa2.png';

  convertImageToBase64(logoPath, base64Logo => {
    convertImageToBase64(sofaImagePath, base64SofaImage => {
      docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    });
  });
};

const convertImageToBase64 = (url, callback) => {
  var img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function() {
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL('image/png');
      callback(dataURL);
  };
  img.src = url;
};

//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END GENERATE DEVIS >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>



//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< FACTURE Generation >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

const generateFacture = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const chunkSize = 6;
  const chunks = [];
  const totalPages = Math.ceil(selections.length / chunkSize);

  for (let i = 0; i < selections.length; i += chunkSize) {
    chunks.push(selections.slice(i, i + chunkSize));
  }

  setPdfReference(pdfReference + 1);
  const currentPage = 1;

  const docDefinition = {
    pageMargins: [40, 160, 40, 160],
    header: [
      {
        height: 500,
        columns: [
          { image: 'logo', width: 200, fit: [200, 100], alignment: 'left', margin: [40, 0, 0, 0] },
          { text: 'facture', fontSize: 26, color: '#7b6248', bold: true, margin: [0, 70, 40, 0], alignment: 'right' },
        ]
      },
      { canvas: [{ type: 'line', x1: 40, y1: 30, x2: 552, y2: 30, lineWidth: 3, color: '#7b6248' }] },
    ],
    footer: function(currentPage, pageCount) {
      return [
        { canvas: [{ type: 'line', x1: 40, y1: 40, x2: 552, y2: 40, lineWidth: 1, color: '#7b6248' }] },
        { text: 'ICE : 003378241000085', style: 'footer11' },
        { text: 'IF : 60118501 - RIB : 000000000', style: 'footer1' },
        { text: 'RC : 77612/RABAT - PATENTE : 25742554 ', style: 'footer1' },
        { text: 'Adresse : 20, Rue du 16 novembre Agdal Rabat', style: 'footer1' },
        { text: 'Email : cornerliving2023@gmail.com', style: 'footer1' },
        { text: 'Tel : 00212661093929', style: 'footer1' },
        { text: currentPage.toString() + '/' + pageCount.toString(), style: 'footer1', alignment: 'center', fontSize:16 }
      ];
    },
    
    content: [
      {
        columns: [
          { text: 'Client: ' + firstName+' '+lastName, style: 'client' },
          { text: ' ' + 'devis' + ' N°:', style: 'devis' },
          { text: pdfReference, style: 'x' },
        ]
      },
      {
        columns: [
          { text: 'Adresse Client: ' + address, style: 'adressClient' },
          { text: "Date:", style: 'dateE' },
          { text: '4/30/2022', style: 'date' },
        ]
      },
      {
        columns: [
          { text: 'Telephone: ' + phone, style: 'adressClient' },
          { text: "" },
          { text: '' },
        ]
      },
      {
        columns: [
          { text: '', style: 'vidText' },
        ]
      }
    ],
    styles: {
      foot: { alignment: 'center' },
      facture: { fontSize: 30, color: '#7b6248', bold: true, margin: [40, 40, 0, 0] },
      tableStyle: { alignment: 'center', marginTop: 70, color: '#7b6248' },
      adressText: { color: '#7b6248' },
      factureA: { marginTop: 15, bold: true, color: '#7b6248' },
      client: { color: '#7b6248', alignment: 'left' },
      devis: { alignment: 'center', color: '#7b6248', margin: [110, 0, 0, 0], bold: true, fontSize: 12 },
      x: { alignment: 'right', marginRight: 9, color: '#7b6248', bold: true },
      adressClient: { marginTop: 5, color: '#7b6248' },
      dateE: { alignment: 'start', color: '#7b6248', margin: [116, 2, 0, 0], bold: true },
      date: { alignment: 'start', color: '#7b6248', bold: true, margin: [107, 2, 0, 4] },
      ref: { alignment: 'start', color: '#7b6248', margin: [118, 8, 0, 0], bold: true },
      refN: { alignment: 'right', color: '#7b6248', bold: true, margin: [135, 8, 9, 4] },
      tableHeader: { bold: true, fillColor: 'white', color: '#7b6248', alignment: 'center' },
      tableHeaderTTC: { fillColor: '#7b6248', color: 'white' },
      valueTTC: { fillColor: '#7b6248', color: 'white' },
      table2: {},
      tableHeader1: { fillColor: '#f3f3f3' },
      value: { fillColor: '#f3f3f3' },
      footer1: { fontSize: 10, bold: true, alignment: 'center' },
      warning: { fontSize: 14, bold: true, alignment: 'center', color: 'red' },
      footer11: { margin: [0, 5, 0, 0], fontSize: 10, bold: true, alignment: 'center' },
      total: { alignment: 'right', margin: [0, 15, 0, 0] },
      totalTitle: { margin: [0, 15, 0, 0], bold: true, alignment: 'right' },
      HT: { margin: [0, 30, 0, 0] },
      totalPriceBackground: { fillColor: '#D9D6DC' },
    },
  };

  // Append tables for each chunk
  chunks.forEach(chunk => {
    docDefinition.content.push({
      layout: 'lightHorizontalLines',
      table: {
        widths: ['*', '*', '*', '*'],
        body: [
          [
            // { text: 'Image', style: 'tableHeader' },
            { text: 'Produit', style: 'tableHeader' },
            { text: 'Service', style: 'tableHeader' },
            { text: 'Quantite', style: 'tableHeader' },
            { text: 'Prix (Dhs)', style: 'tableHeader' },
          ],
          ...chunk.map(item => [
            // { image: item.image64, fillColor: 'f3f3f3', width: 40, height: 40 },
            { text: item['produitText'], fillColor: '#f3f3f3' },
            {
              text: '-' + item.serviceText,
              fillColor: '#f3f3f3',
              fontSize: 8,
              bold: true
            },
            { text: item.quantity, fillColor: '#f3f3f3' },
            { text: item['prix'], fillColor: '#f3f3f3' },
          ]),
        ],
        border: [],
      },
      style: 'tableStyle'
    });
  });

  docDefinition.content.push({
    layout: 'noBorders',
    table: {
      widths: [100, 100],
      body: [
        [
          { text: 'TOTAL HT', style: 'tableHeader1' },
          { text: formatNumberWithTwoDecimals(totalPrix), style: 'value' },
        ],
        [
          { text: 'TVA', style: 'tableHeader1' },
          { text: '20%', style: 'value' },
        ],
        [
          { text: 'TOTAL TTC', style: 'tableHeaderTTC' },
          { text: formatNumberWithTwoDecimals(totalPrixTTC), style: 'valueTTC' },
        ]
      ]
    },
    style: 'table2',
    margin: [307, 15, null, null]
  });

  docDefinition.content.push({ text: 'Signature', alignment: 'right', marginTop: 20, bold: true, marginRight: 50 });

  const logoPath = './images/logo1.png';
  const sofaImagePath = './images/sofa2.png';

  convertImageToBase64(logoPath, base64Logo => {
    convertImageToBase64(sofaImagePath, base64SofaImage => {
      docDefinition.images = { logo: base64Logo, sofa: base64SofaImage };
      const pdfGenerator = pdfMake.createPdf(docDefinition);
      pdfGenerator.getBlob(blob => {
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    });
  });
};


//<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END GENERATE FACTURE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>





  const increaseQuantity = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(prevQuantity => prevQuantity - 1);
    }
  };

  useEffect(() => {
    fetch('https://icanto-api.gisway.org/api/v1/services/')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        setVisselles(data.items);
        if (data.items.length > 0) {
          setSelectedTitle(data.items[0].title);
          setProduitId(data.items[0].id);
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (produitId) {
      fetch(`https://icanto-api.gisway.org/api/v1/products/services/${produitId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
          }
          return response.json();
        })
        .then(data => {
          setProductDetails(data.items);
        })
        .catch(error => {
          setError(error);
        });
    }
  }, [produitId]);

  useEffect(() => {
    setPrixVente(basePrixVente * quantity);
  }, [quantity, basePrixVente]);

  const handleChange = (event) => {
    if (event.target.value === 'MEUBLES') {
      window.location.href = `/new-form?customerId=${customerId}&serialNumber=${serial}&first_name=${firstName}&last_name=${lastName}&email=${address}&phone=${phone}`
    }
    else{
      const selectedIndex = event.target.options.selectedIndex;
      setProduitId(visselles[selectedIndex].id);
      setProduitText(event.target.value)
    }

  };

  const handleChangeDetails = (event) => {
    const selectedIndex = event.target.options.selectedIndex - 1;
    if (selectedIndex >= 0 && selectedIndex < productDetails.length) {
      const selectedProductDetail = productDetails[selectedIndex];
      setServiceId(event.target.value);
      setServiceText(selectedProductDetail?.designation || '');
      const price = selectedProductDetail?.prix_vente || 0;
      setBasePrixVente(price);
      setPrixVente(price * quantity);
    } else {
      setServiceId(null);
      setServiceText('');
      setBasePrixVente(0);
      setPrixVente(0);
    }
  };

  const handleConfirm = () => {
    const prixTTC = prixVente * 1.20;

    const newSelection = {
      produitId,
      produitText: produitText,
      serviceId,
      serviceText,
      quantity,
      prix: prixVente,
      prixTTC:prixTTC
    };
    setSelections([...selections, newSelection]);
    console.log(';y selections',selections);
    const newTotalPrice = totalPrix + prixVente;
    const newTotalPriceTTC = totalPrixTTC + prixTTC;
    setTotalPrix(newTotalPrice)
    setTotalPrixTTC(newTotalPriceTTC)
    Swal.fire({
      title: 'Success!',
      text: 'Your selection has been added.',
      icon: 'success',
      confirmButtonText: 'OK'
    });
  };
  const redirectToVisselle = () =>{
    window.location.href = `/new-form?customerId=${customerId}&serialNumber=${serial}&first_name=${firstName}&last_name=${lastName}&email=${address}&phone=${phone}`
  }
  return (
    <div className="ParentComponent">
      <MyHeader />
      <div className='main'>
        <div className='left-side'>
          <div className="image-list large-screen">
            <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
            <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
            <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
            <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
          </div>
          <div className="main-image">
            <img src={`${process.env.PUBLIC_URL}/images/visselle.jpg`} alt="visselle" />
          </div>
        </div>
        <div className='right-side'>
          <div className='breadcrumbs-redirect'>
            <div className='bradcrumbs'>
            <span onClick={()=>redirectToHome()}>Home</span><span>/</span><span onClick={()=>redirectToWelcome()}>Client</span><span>/</span><span className='active-breadcrumb'>Product</span>            </div>
            <p onClick={()=>redirectToVisselle()}>Autre Categorie?</p>
          </div>
          
          <h3>{serviceText ? serviceText : 'Visselle'}</h3>
          <div className='price'>
            <div className='new-price'>{prixVente ? (prixVente) : '0'} DHs</div>
          </div>
          <div className='selects'>
            <label className="select1" htmlFor="versionSelect">Le Produit</label>
            <select onChange={handleChange} >{console.log(visselles)}
              {visselles.map((visselle) => (
//.filter((visselle)=>visselle.title!=='MEUBLES')
                <option key={visselle.id} value={visselle.title}>
                  {visselle.title}
                </option>
              ))}
            </select>

            <label className="select1" htmlFor="versionSelect">Service</label>
            <select onChange={handleChangeDetails} value={serviceId}>
              <option value="">Choisisser Le Service</option>
              {productDetails.map((productDetail) => (
                <option key={productDetail.id} value={productDetail.id}>
                  {productDetail.designation}
                </option>
              ))}
            </select>
          </div>
          <label>
          Discount (%):
          <input
            type="number"
            value={discount}
            onChange={handleDiscountChange}
            min="0"
            max="100"
          />
        </label>


          <label className='select1'>Quantité</label>
          <div className='quantity'>
            <div className='increase' onClick={increaseQuantity}>+</div>
            <p>{quantity}</p>
            <div className='decrease' onClick={decreaseQuantity}>-</div>
          </div>

          <div className='last-buttons'>
            <button className='confirm-selections' onClick={handleConfirm}>Confirmer</button>
            <button className='see-selections' onClick={()=>generate()}>Generer Pdf 1</button>
            
            {console.log(selections)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicSelectsVisselle;

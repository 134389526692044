// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  margin-top: 70px;
  width: 100%;
  }
  
  .dropdown-toggle {
    background-color: #7b6248;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }
  .dropdown-toggle:hover{
    background-color: #a7a7a7;
  }
  
  .dropdown-content {
    display: flex;
    flex-direction: column;
    opacity: 0; /* Initially hide the dropdown content */
    transition: opacity 0.3s ease; /* Add transition effect */
  }
  
  .dropdown-content.show {
    opacity: 1; /* Show the dropdown content with fade-in effect */
  }
  
  .dropdown-option {
    background-color: #a7a7a7;
    height: 35px;
    color: white;
    padding: 10px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    transition: opacity 0.3s ease;
  }
  
  .dropdown-option:hover {
    background-color: #8a8a8a;
  }
  .option1{
    margin-bottom: 4px;
    margin-top: 5px;
  }`, "",{"version":3,"sources":["webpack://./src/components/dropDownStyle.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,eAAe;EACjB;EACA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,UAAU,EAAE,wCAAwC;IACpD,6BAA6B,EAAE,0BAA0B;EAC3D;;EAEA;IACE,UAAU,EAAE,kDAAkD;EAChE;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,qBAAqB;IACrB,cAAc;IACd,eAAe;IACf,6BAA6B;EAC/B;;EAEA;IACE,yBAAyB;EAC3B;EACA;IACE,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".dropdown {\n  margin-top: 70px;\n  width: 100%;\n  }\n  \n  .dropdown-toggle {\n    background-color: #7b6248;\n    color: white;\n    padding: 10px;\n    font-size: 16px;\n    border: none;\n    border-radius: 10px;\n    cursor: pointer;\n  }\n  .dropdown-toggle:hover{\n    background-color: #a7a7a7;\n  }\n  \n  .dropdown-content {\n    display: flex;\n    flex-direction: column;\n    opacity: 0; /* Initially hide the dropdown content */\n    transition: opacity 0.3s ease; /* Add transition effect */\n  }\n  \n  .dropdown-content.show {\n    opacity: 1; /* Show the dropdown content with fade-in effect */\n  }\n  \n  .dropdown-option {\n    background-color: #a7a7a7;\n    height: 35px;\n    color: white;\n    padding: 10px;\n    text-decoration: none;\n    display: block;\n    cursor: pointer;\n    transition: opacity 0.3s ease;\n  }\n  \n  .dropdown-option:hover {\n    background-color: #8a8a8a;\n  }\n  .option1{\n    margin-bottom: 4px;\n    margin-top: 5px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
